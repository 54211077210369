import React from 'react'
import 'iframe-resizer'
import CreateMarkup from './CreateMarkup'

const Ap = () => {
  const html = `
    <iframe
      id='iframe_af00aee0be1b9f1f27f104ac4aea474c'
      style='overflow: hidden; min-width: 100%; border: none;'
      src='https://elections.ap.org/widgets/content/af00aee0be1b9f1f27f104ac4aea474c'
      width='100%'
      height='100%'
      frameborder='1'
      allowfullscreen='allowfullscreen'
      onload="iFrameResize({}, '#iframe_af00aee0be1b9f1f27f104ac4aea474c');"
      >`

  return (
    <div className="embeddedResults">
      <h1>Races to Watch</h1>
      <div dangerouslySetInnerHTML={CreateMarkup(html)} />
    </div>
  )
}

export default Ap
