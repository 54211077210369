import React from 'react'

const ElectionContext = React.createContext({
  ap: Boolean,
  slug: '',
  date: '',
  year: '',
  nav: [],
  overview: [],
  national: [],
  logo: '',
  priorityPresidentialCandidates: [],
})

export default ElectionContext
