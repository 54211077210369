import React from 'react'
import CreateMarkup from './CreateMarkup'
import 'iframe-resizer'

const ApMnHouseGop = () => {
  const iframe = `<iframe
  id="iframe_8998f425d56da2b72e4a4b0c60bc3f10"
  style="overflow: hidden; min-width: 100%; border: none;"
  src="https://elections.ap.org/widgets/content/8998f425d56da2b72e4a4b0c60bc3f10"
  width="100%"
  height="100%"
  frameborder="1"
  allowfullscreen="allowfullscreen"
  onload="iFrameResize({}, '#iframe_8998f425d56da2b72e4a4b0c60bc3f10');"
  />`

  return (
    <div className="embeddedResults">
      <h1>MN House Results - Gop</h1>
      <div dangerouslySetInnerHTML={CreateMarkup(iframe)} />
    </div>
  )
}

export default ApMnHouseGop
