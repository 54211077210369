import React, { Component } from 'react'

class TX extends Component {
  render() {
    return (
      <symbol id="statesvg-TX" width="100" height="100" viewBox="0 0 100 100">
        <path d="M49.69,4.27l.1,16.52,2.72,1.56h1.57l.31-.41,1.67,1.77,4,.63.63.94,2.82-.94,1.36,2.09,1.78-.84,1.67,1.47L70,26l.42,1.67,1.57-2,1,.73L74,26l2.3,1.36,2.82-1.67.94.31,1.47-.62,1.56.31.84-.94L90.24,28l2.51-.32.84,13.49,1.77,1.57v1.77L98.29,49l-1.57,4.07.63,4.29-1.57,2.09.63,1.15L94.94,61l-5,3.24L90.24,63l1.88-.73v-.21l-2.72.52.42-2-.94.73L87.63,61l1,2.61.73.63-2.09,1.36v1l-1.88,1.88-3.86,1.88v.11L83.13,70l-3.66,2.3,1.68-1.78-1.68.73.63-.42-.42-.42-1.35.42L78,70.12,77.7,70l.42.83-.11.42-.52.52.21-.94-1.36-.42,1.15,1.68-.52.62,1,.11-1.67.94-1-1,.11,1.78-.94.94-.42.21.31-1.46L74,75.66l-.42-.94-1.25.73.31,1.26.94-.95-1.25,2.41-2.62-.32L72,79.53l-1.36,3.34-.84.32.42-1.26-.94,1-1.15-1.47L69,83l-.94.21h2.51l-.84,2.29.63.84-.63.63L72,93l.94.1-.21.74.31-.32.42.53L72,94.79l-.21.94-2.4-1.88-4.08-.21-5.12-2.3-2.09-.21-1-3.45-1.88-2.2-.52-4.7-2.51-1.25-1.88-3.14-1.57-1.57-3-7.21-4.18-4-.84.21-.73-1.78-5.43-.31-1.26-.52-.62.83-2.1.21L29.2,66.67,27,69.08l-1.78-.42-8-5.44-3.45-8.67-3.13-2L2.13,43.47,1.71,42l26,1L28.89,4.27ZM70.8,87l-.21-2.72L70.8,84l.31-.1L73,93.11ZM72,81.09,70.8,83.71,72,80.05l1.46-2.2-1,2.2Zm2.4-5.33.32-.1v.73l-1,1.46Zm1.36-.94-1,1.15.73-1.15L78,73.67Zm3.14-2.09.62-.41-1.14.52Zm11.08-8.46-2.3,2.19,1.77-1.88v-.31Z" />
      </symbol>
    )
  }
}

export default TX
