import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { API_ENDPOINT } from '../../config'
import ElectionContext from '../../context/ElectionContext'
import PresidentialPrimaryNational from './PresidentialPrimaryNational'
import PresidentialPrimaryStates from './PresidentialPrimaryStates'

const PresidentialPrimary = () => {
  const context = useContext(ElectionContext)
  return (
    <div className="PresidentialPrimary">
      <Helmet>
        <title>MPR News | Live Results for Presidential Primary</title>
        <meta
          name="twitter:title"
          content="LIVE Election Results from MPR News | Presidential Primary"
        />
        <meta
          property="og:title"
          content="LIVE Election Results from MPR News | Presidential Primary"
        />
      </Helmet>
      <div className="section_header">
        <h2 className="hdg hdg-2 hdg-em">
          National Presidential Primary Results
        </h2>
        <a
          className="button button-marginRight button-marginTop"
          href="/2020-11-03/"
        >
          View Overview of Results
        </a>
        <a
          className="button button-marginRight button-marginTop"
          href="/2020-11-03/president/county"
        >
          View Minnesota County Results
        </a>
      </div>
      <PresidentialPrimaryNational
        interval={30000}
        party="Democratic"
        url={`${API_ENDPOINT}/${context.year}/presidential_primary_delegates?party=Dem`}
      />
      <PresidentialPrimaryNational
        interval={30000}
        party="Republican"
        url={`${API_ENDPOINT}/${context.year}/presidential_primary_delegates?party=GOP`}
      />
      <div className="results-legend results-minnesota-first">
        <h3 className="results-header">Democratic Primary Results By State</h3>
        <div className="results-key">Percent and Votes</div>
      </div>
      <PresidentialPrimaryStates
        interval={30000}
        url={`${API_ENDPOINT}/${context.year}/presidential_primary_races?party=Dem&state=Minnesota`}
      />
      <PresidentialPrimaryStates
        interval={30000}
        url={`${API_ENDPOINT}/${context.year}/presidential_primary_races?party=Dem`}
      />
      <div className="results-legend">
        <h3 className="results-header">Republican Primary Results By State</h3>
        <div className="results-key">Percent and Votes</div>
      </div>
      <PresidentialPrimaryStates
        interval={30000}
        url={`${API_ENDPOINT}/${context.year}/presidential_primary_races?party=GOP`}
      />
    </div>
  )
}

export default PresidentialPrimary
