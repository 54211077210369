import React, { useState, useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import ElectionDropdown from '../ElectionDropdown'
import ElectionContext from '../../context/ElectionContext'
import { isInternalLink } from '../../util'
import Svg from '../Svg'

const Nav = () => {
  const context = useContext(ElectionContext)
  const routes = context.nav.filter((item) => !isInternalLink(item.href))
  const links = context.nav.filter((item) => isInternalLink(item.href))

  const [navActive, setNavActive] = useState(false)

  const toggleNav = () => {
    setNavActive(!navActive)
  }

  const changeLocation = () => {
    setNavActive(false)
  }

  return (
    <nav>
      <button className="nav_button" onClick={toggleNav}>
        {navActive ? (
          <span>
            <Svg icon="close" origHeight={20} origWidth={20} />
            <span className="nav_button_label">Close Menu</span>
          </span>
        ) : (
          <span>
            <Svg icon="menu" origHeight={20} origWidth={20} />
            <span className="nav_button_label">
              <span className="invisible">Open </span>
              Menu
            </span>
          </span>
        )}
      </button>
      <div className={`nav ${navActive ? 'active' : ''}`}>
        <Link to={`/${context.date}`} className="nav_logo">
          <img
            className="header_logo_img"
            src={context.logo}
            alt={`Election ${window.location.pathname
              .split('-')[0]
              .replace(/\//, '')}`}
          />
        </Link>
        <ElectionDropdown />
        <ul id="nav_list" className="nav_list">
          <li>
            <NavLink
              exact
              to={`/${context.date}/`}
              onClick={changeLocation}
              id="nav_link-overview"
              className="nav_link nav_link-overview"
            >
              Overview
              <Svg icon="star" />
            </NavLink>
          </li>
          {routes.map((item, index) => {
            return (
              <li key={`/${item.href}`}>
                <NavLink
                  exact
                  to={`/${context.date}/${item.href}`}
                  onClick={changeLocation}
                  id={`nav_link-${item.icon}`}
                  className={`nav_link nav_link-${item.icon}`}
                >
                  {item.title}
                  <Svg icon={item.icon} />
                </NavLink>
              </li>
            )
          })}
          {links.map((item, index) => {
            return (
              <li key={item.href}>
                <a
                  href={item.href}
                  onClick={changeLocation}
                  id={`nav_link-${item.icon}`}
                  className={`nav_link nav_link-${item.icon}`}
                >
                  {item.title}
                  <Svg icon={item.icon} />
                </a>
              </li>
            )
          })}
          <li />
        </ul>
      </div>
    </nav>
  )
}

export default Nav
