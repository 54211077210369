import React, { Component } from 'react'

class IconStar extends Component {
  render() {
    return (
      <symbol
        id="icon-mn-counties"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <g strokeWidth=".75" fillRule="evenodd">
          <g transform="translate(10, 0)">
            <polygon points="63.66 93.58 63.79 99.46 54.93 99.67 54.86 93.76" />
            <polygon points="51.92 94.07 51.97 87.93 56.37 87.88 56.39 89.34 59.31 89.29 59.38 93.68" />
            <polygon points="7.93 23.77 16.73 23.99 16.68 25.93 15.22 25.91 15.18 27.39 9.29 27.24 9.34 25.78 7.87 25.73" />
            <polygon points="7.93 23.77 7.85 20.19 18.13 20.47 18.16 24.52 16.72 24.46 16.73 23.99" />
            <polygon points="38.29 53.34 42.77 53.27 42.75 57.77 41.73 57.76 41.83 64.93 38.86 64.92 38.78 57.77 38.28 57.76" />
            <polygon points="0.52 19.96 1 17.84 0.69 16.16 0.71 13.93 0.85 13.79 0.63 13.66 18.19 14.27 18.13 20.47" />
            <polygon points="0.17 5.95 0.61 9.34 1.34 11.19 1.34 11.93 0.49 13.38 0.63 13.66 9.36 13.95 9.42 6.24" />
            <polygon points="3.45 46.17 3.49 43.16 4.05 41.08 3.19 39.83 3.29 37.41 10.76 37.62 10.74 46.45" />
            <polygon points="2.28 63.9 9.33 64.13 9.23 67.07 10.72 67.12 10.61 70.04 10.78 70.04 10.75 71.11 10.25 70.85 9.93 70.81 9.26 70.36 8.73 70.27 8.64 69.87 7.99 69.68 7.21 69.66 7.02 69.46 6.62 69.43 6.47 68.48 6.12 67.8 4.7 66.98 4 66.94 3.42 66.13 3.3 65.67 3.09 65.06" />
            <polygon points="3.29 37.41 3.24 31.62 3.44 31.49 12.41 31.76 12.23 37.65" />
            <polygon points="43.11 93.91 38.68 93.95 38.69 88.06 43.1 88.03" />
            <polygon points="25.26 99.79 32.75 99.86 32.81 93.95 25.35 93.89" />
            <polygon points="31.37 89.53 22.44 89.47 22.4 86.52 25.34 86.55 25.4 82.88 30.39 85.71 31.02 86.81 31.36 86.88" />
            <polygon points="25.29 93.89 25.26 93.9 17.9 93.8 18.04 87.9 22.45 87.99 22.44 89.47 25.36 89.5" />
            <polygon points="31.36 86.88 32.34 87.3 35.29 88.76 35.85 88.39 35.73 87.32 38.7 87.31 38.68 93.95 31.31 93.94" />
            <polygon points="5.97 87.6 10.71 87.74 10.53 93.62 5.76 93.48" />
            <polygon points="25.36 89.5 31.36 89.54 31.31 93.94 25.29 93.89" />
            <polygon points="47.53 93.87 47.49 93.87 43.11 93.91 43.1 88.03 47.5 87.97" />
            <polygon points="47.57 87.97 51.97 87.93 51.98 87.97 52.04 93.81 47.53 93.87" />
            <polygon points="17.87 99.7 25.26 99.79 25.29 93.89 18.03 93.8" />
            <polygon points="5.76 93.48 10.67 93.62 10.48 99.55 5.55 99.4" />
            <polygon points="17.87 99.7 10.48 99.55 10.67 93.62 18.03 93.8" />
            <polygon points="22.36 87.99 16.44 87.88 16.58 81.99 19.41 82.05 19.47 79.41 20 79.5 20.26 80.05 20.91 79.99 23.4 81.86 25.2 82.55 25.4 82.88 25.34 86.55 22.4 86.52" />
            <polygon points="11.54 71.55 10.75 71.11 10.72 67.12 21 67.32 21.01 71.73" />
            <polygon points="21.01 71.73 20.89 76.15 18.08 76.11 18.02 78.46 17.55 78.06 17.78 77.74 16.26 76.3 15.23 75.8 14.96 75.14 14.03 74.81 13.89 74.31 13.19 73.55 12.18 72.5 11.54 71.55" />
            <polygon points="15.01 76.04 13.68 76 13.63 77.47 6.37 77.28 6.62 69.43 8.64 69.87 8.73 70.27 9.33 70.32 12.03 72.24 13.19 73.55 13.99 74.3 14.24 75.05 14.96 75.14" />
            <polygon points="10.54 87.73 5.97 87.6 6.25 80.23 10.6 80.36" />
            <polygon points="10.6 80.36 16.5 80.51 16.44 87.88 10.54 87.73" />
            <polygon points="9.23 67.07 9.27 61.17 15.15 61.31 15.12 67.22" />
            <polygon points="15.15 61.31 9.27 61.17 9.34 55.27 15.18 55.44" />
            <polygon points="40.15 93.93 40.14 99.85 47.54 99.79 47.49 93.87" />
            <polygon points="40.14 93.94 32.81 93.95 32.75 99.86 40.14 99.85 40.15 93.93" />
            <polygon points="47.53 93.87 47.49 93.87 47.54 99.79 54.93 99.67 54.86 94.01" />
            <polygon points="50.39 78.62 50.08 78.54 49.74 78.58 49.45 78.16 48.83 78.04 48.11 78.15 47.82 78.15 47.75 77.84 47.94 77.5 47.74 76.2 48.18 76.19 48.14 72.25 47.71 72.26 47.71 69.32 50.98 69.29 50.79 69.42 50.9 69.72 50.81 70.07 50.92 70.63 50.76 71.22 50.92 71.37 50.86 71.68 51.07 72.38 50.6 72.86 50.34 73.35 50.75 73.76 50.84 73.9 50.72 74.29 50.99 75.3 50.73 75.98 50.84 76.43 50.81 77.09 50.64 77.5 50.41 78.08 50.32 78.53" />
            <polygon points="37 82.17 37.12 82.14 37.23 81.77 37.08 81.45 37.64 80.84 37.83 80.78 37.9 80.9 38.16 80.63 39.08 80.43 39.55 80.03 40.01 79.56 40.55 79.37 40.38 78.85 40.77 78.08 41.47 77.71 42.7 77.56 43.34 77.82 43.45 78.04 44.02 77.93 44.04 79.17 44.17 79.17 44.16 80.64 44.65 80.64 44.64 82.11" />
            <polygon points="47.57 76.11 47.39 75.71 46.44 75.72 45.84 76.26 45.58 75.93 45.47 73.78 45.25 73.78 45.23 72.27 48.14 72.25 48.18 76.19" />
            <polygon points="35.43 67.26 37.05 67.97 38.91 69.43 41.19 69.3 41.76 70.3 41.66 70.58 40.05 71.1 40.3 71.46 39.55 72.1 39.21 72.89 38.87 73.01 38.75 74.8 32.87 74.76 32.95 69.18 33.66 69 34.05 69.31 34.92 68.55" />
            <polygon points="44.02 77.93 46.44 75.72 47.39 75.71 47.88 76.68 47.98 78.21 49.44 78.16 51.24 79.14 51.26 80.58 50.53 80.58 50.55 82.06 49.02 82.07 49.02 82.52 47.56 82.57 47.55 83.3 44.65 83.33 44.62 83.32 44.65 80.64 44.16 80.64" />
            <polygon points="41.83 64.93 41.82 70.24 41.12 69.29 39.93 69.42 38.91 69.43 38.02 68.76 36.51 68.02 35.87 67.8 34.78 66.72 34.31 66.06 34.25 64.99 34.22 64.94" />
            <polygon points="22.55 33.43 22.57 25.65 21.72 25.1 21.71 24.15 22.21 23.14 18.09 23.06 18.19 14.27 22.6 14.32 22.55 17.26 31.41 17.33 31.37 33.52" />
            <polygon points="12.23 37.65 12.41 31.76 18.34 31.88 18.19 37.8" />
            <polygon points="18.19 37.8 18.39 28.94 18.11 28.93 18.2 23.06 22.21 23.14 21.71 24.15 21.63 24.69 21.93 25.41 22.57 25.65 22.62 37.85" />
            <polygon points="21.12 14.29 21.18 11.37 24.01 11.41 24.07 7.89 22.29 8.51 21.6 7.91 21.56 6.56 21.54 6.52 23.39 6.55 23.51 0.02 25.69 0.29 27.23 1.14 27.92 4.9 28.6 8.56 28.47 10.28 31.42 11.68 31.38 17.33 22.55 17.26 22.6 14.32" />
            <polygon points="9.36 13.95 9.42 6.24 21.56 6.56 21.6 7.91 22.47 8.54 24.07 7.89 24.01 11.41 21.18 11.37 21.12 14.29" />
            <polygon points="69.13 93.41 59.38 93.68 59.28 87.83 61.98 87.76 62.35 87.99 62.5 88.24 63.81 88.94 63.84 89.22 64.33 89.46 64.44 89.71 65.69 90.49 67.2 90.88 68.12 91.96 68.44 92.58 68.65 92.72 68.8 93.08" />
            <polygon points="69.13 93.41 69.67 94.63 69.38 96.68 69.49 96.79 69.52 97.49 69.9 97.89 70.11 99.27 63.79 99.46 63.66 93.58" />
            <polygon points="38.83 60.47 38.86 64.92 34.22 64.93 33.97 64.42 33.72 64.24 33.59 63.92 33.59 63.44 33.84 62.9 33.6 62.92 33.34 62.31 33.47 62.1 32.85 61.63 32.8 61.28 31.93 60.67 31.87 60.47" />
            <polygon points="47.2 54.72 47.21 57.72 46.17 57.74 46.21 61.97 41.79 61.95 41.73 57.76 42.75 57.77 42.75 54.83 47.19 54.71" />
            <polygon points="31.42 11.68 32.59 11.61 33.28 11.72 33.47 12.47 34.47 12.63 38.11 12.96 38.44 13.92 38.31 14.65 40.26 14.77 40.43 14.55 41.33 14.59 42.34 14.13 42.27 13.51 44.62 12.63 45.16 12.63 45.48 12.95 46.49 12.85 46.68 25.33 38.75 25.28 38.75 26.17 31.42 26.17" />
            <polygon points="49.77 61.94 46.21 61.97 46.22 57.73 47.21 57.72 47.16 50.29 56.09 50.17 56.18 56 55.72 56.2 55.4 57.05 54.81 56.81 54.1 57.74 53.49 57.58 53.17 58.14 51.27 59.19 50.65 60.28 50.45 61.35" />
            <polygon points="46.21 61.97 49.77 61.94 49.44 62.16 49.29 64.54 50.65 64.7 51.2 65.09 51.74 66.43 52.15 66.79 51.56 68.13 51.54 68.6 50.98 69.29 47.71 69.32 47.65 64.9 46.22 64.9" />
            <polygon points="41.84 67.38 41.79 61.95 46.21 61.97 46.22 64.9 47.65 64.9 47.7 67.37" />
            <polygon points="47.7 67.37 47.71 72.26 45.23 72.27 45.25 73.78 44.56 73.79 44.61 72.63 42.77 70.7 41.82 70.24 41.84 67.38" />
            <polygon points="41.7 70.23 42.77 70.7 43.66 71.43 44.21 71.94 44.61 72.63 44.56 73.79 45.47 73.78 45.47 75.16 45.85 76.18 45.44 77.09 44.95 77.57 43.62 77.97 41.72 77.72 41.72 76.24 38.75 76.27 38.77 73.21 38.87 73.01 39.55 72.1 40.3 71.46 40.05 71.1 40.45 70.86 41.45 70.46" />
            <polygon points="41.72 77.72 40.77 78.08 40.48 78.48 40.55 79.37 38.76 80.66 38.72 79.96 37.26 79.96 37.27 79.21 35.81 79.2 35.8 74.77 38.75 74.8 38.75 76.27 41.72 76.24" />
            <polygon points="35.8 74.77 35.81 79.2 32.86 79.2 32.84 80.68 29.89 80.67 29.91 74.75" />
            <polygon points="32.85 69.6 32.87 74.76 29.91 74.75 29.89 76.22 26.81 76.21 26.86 68.86 31.4 68.88 31.4 69.62" />
            <polygon points="22.5 61.22 32.8 61.28 33.47 62.1 33.84 62.9 33.59 63.92 34.25 64.99 34.31 66.06 35.43 67.26 34.92 68.55 34.05 69.31 33.66 69 32.95 69.18 32.85 69.6 31.4 69.62 31.4 68.88 26.86 68.86 26.88 67.39 22.47 67.35" />
            <polygon points="28.4 51.54 29.79 52.32 31.02 51.81 31.84 52.37 31.94 52.93 31.43 53.93 31.75 54.41 31.65 54.79 38.29 54.81 38.28 57.76 38.78 57.77 38.83 60.47 31.87 60.47 32.8 61.28 28.38 61.28" />
            <polygon points="32.22 52.56 32.12 43.81 38.72 43.85 38.69 47.48 38.3 47.57 38.29 54.81 31.65 54.79 31.75 54.41 31.43 53.93 31.48 53.42" />
            <polygon points="38.29 53.22 38.3 47.57 38.69 47.48 38.74 40 47.08 40 47.2 54.72 47.19 54.71 42.75 54.83 42.77 53.27" />
            <polygon points="56.09 50.17 47.16 50.29 47.05 44.41 55.89 44.3" />
            <polygon points="0.52 19.96 7.85 20.19 7.75 22.78 7.97 22.78 7.87 25.73 9.34 25.78 9.29 27.24 15.18 27.39 15.22 25.91 16.68 25.93 16.72 24.46 18.16 24.52 18.11 28.93 18.39 28.94 18.3 31.88 3.44 31.49 3.44 29.62 3.17 29.45 2.67 27.75 2.68 27.19 1.71 25.07 1.81 24.3 1.39 23.81 1.27 22.05 0.94 21.69" />
            <polygon points="9.46 46.41 9.29 56.75 9.29 56.74 5.61 56.63 5.92 55.57 5.57 53.78 5.55 51.37 5.06 51.05 4.37 49.86 3.99 48.47 3.97 47.04 3.59 46.23 6.82 46.33" />
            <polygon points="9.34 55.27 9.46 46.41 10.74 46.45 10.76 44.97 22.54 45.21 22.54 55.58" />
            <polygon points="22.62 37.85 22.54 45.21 10.75 44.89 10.76 37.62" />
            <polygon points="22.57 43.73 22.55 33.43 28.44 33.53 28.43 43.82" />
            <polygon points="31.42 26.17 38.75 26.17 38.75 25.28 46.68 25.33 46.68 28.24 46.93 28.25 47.08 40 38.74 40 38.75 36.91 38.36 36.57 38.75 35.34 38.21 35.43 37.44 35.1 37.18 35.48 35.85 34.56 35.83 33.58 35.25 32.57 34.81 32.37 32.43 33.47 31.39 32.88" />
            <polygon points="26.94 43.79 28.43 43.82 28.44 33.53 31.37 33.52 31.38 32.97 32.43 33.47 34.81 32.37 35.83 33.58 35.66 34.16 37.18 35.48 37.44 35.1 37.95 35.14 38.21 35.43 38.56 35.27 38.36 36.57 38.75 36.91 38.72 43.85 32.12 43.81 32.22 52.56 32.06 52.75 31.02 51.81 29.79 52.32 28.76 51.91 28.18 51.34 27.55 51.4 26.94 50.75" />
            <polygon points="22.52 51.13 22.57 43.73 26.94 43.79 26.94 50.75 27.46 50.92 27.49 51.18 22.53 51.13" />
            <polygon points="22.52 51.13 27.49 51.18 27.55 51.4 28.18 51.34 28.4 51.54 28.38 61.28 22.5 61.22" />
            <polygon points="22.5 61.46 15.15 61.31 15.18 55.44 22.54 55.58" />
            <polygon points="22.47 67.35 15.12 67.22 15.15 61.31 22.5 61.46" />
            <polygon points="21 67.32 26.88 67.39 26.81 76.21 20.89 76.15" />
            <polygon points="28.31 83.63 26.29 83.63 26.07 83.17 25.54 83.02 25.05 82.5 23.18 81.84 20 79.5 19.22 79.28 18.02 78.46 18.08 76.11 29.94 76.22 29.91 79.2 28.33 79.2" />
            <polygon points="28.31 83.63 28.33 79.2 29.91 79.2 29.89 80.67 32.84 80.68 32.86 79.2 37.27 79.21 37.26 79.96 38.72 79.96 38.78 80.62 37.67 80.89 37.08 81.45 37.22 82.02 37 82.17 37.24 82.95 36.76 83.64" />
            <polygon points="36.76 83.64 36.35 85.2 36.71 85.53 36.38 86.17 35.58 87 35.56 87.8 35.82 88.52 35.33 88.63 34.77 88.61 34.01 88.13 32.24 87.39 30.09 85.52 28.67 84.97 27.42 84.45 27.02 83.85 26.43 83.59" />
            <polygon points="35.62 87.31 35.98 86.36 36.71 85.53 36.45 85.32 36.7 83.71 37.29 82.61 37 82.17 41.66 82.14 41.66 88.04 38.69 88.06 38.7 87.31" />
            <polygon points="41.66 88.04 41.66 82.14 44.62 82.11 44.65 83.33 47.55 83.3 47.57 87.97" />
            <polygon points="53.54 87.92 47.57 87.97 47.56 82.56 49.02 82.52 49.02 82.07 50.55 82.06 50.53 80.58 51.26 80.58 51.24 79.99 51.24 79.14 52.68 80.86 53.22 80.97 53.47 81.57 53.87 81.46 55.7 81.7 56.08 81.78 57.24 83.44 54.95 83.47 54.97 84.95 53.51 84.97" />
            <polygon points="57.24 83.44 58.67 83.95 59.52 84.28 61.28 85.81 61.13 86.04 61.5 86.4 61.98 87.76 59.28 87.83 59.31 89.29 56.39 89.34 56.37 87.88 53.54 87.92 53.51 84.97 54.97 84.95 54.95 83.47" />
            <polygon points="69.86 19.71 70.38 31.98 77.85 26.78 87.53 21.86 83.45 22.21 82.99 22.57 80.95 20.78 75.28 21.06 75.01 20.6 72.76 20.05 71.91 18.68" />
            <polygon points="9.29 56.75 9.37 58.27 9.19 64.12 2.28 63.9 2.02 63.57 3.35 62.02 5.41 60.06 5.61 56.63 9.29 56.74" />
            <polygon points="16.46 81.99 16.5 80.51 6.25 80.23 6.36 77.28 6.37 77.28 13.63 77.47 13.68 76 15.01 76.04 15.03 75.3 15.8 75.85 17.85 77.88 17.53 78.16 19.47 79.41 19.41 82.05" />
            <polygon points="17.9 93.8 10.53 93.62 10.71 87.74 18.04 87.9" />
            <polygon points="61.17 19.76 61.64 39.95 55.89 45.41 55.89 44.3 47.05 44.41 46.49 12.85 48.05 12.78 51.61 14.21 50.75 15.59 53.08 15.79 54.67 19.62 55.57 18.84 55.57 17.53 58.2 17.18 58.78 18.77" />
            <polygon points="61.17 19.76 62.12 19.81 62.17 21.2 63.91 21.29 63.86 22.39 67.43 21.58 69.86 19.71 70.38 31.98 65.78 36.43 61.53 40.01" />
          </g>
        </g>
      </symbol>
    )
  }
}

export default IconStar
