import React, { Component } from 'react'

class IconGavel extends Component {
  render() {
    return (
      <symbol id="icon-gavel" width="100" height="100" viewBox="0 0 100 100">
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
          <path d="M41.4 26.5C38.8 23.9 38.8 20.7 40.7 18.7L57.5 1.9C59.5 0 62.7 0 65.3 1.9L65.9 2.6C67.9 4.5 67.9 7.8 65.9 10.3L49.1 26.5C47.2 28.4 43.3 28.4 41.4 26.5Z" />
          <path d="M73.2 58.3C70.6 55.7 70.6 52.5 72.5 49.9L89.3 33.1C91.3 31.2 94.5 31.2 97.1 33.1L97.7 33.7C99.7 35.7 99.7 38.9 97.7 41.5L80.9 58.3C78.3 60.2 75.1 60.2 73.2 58.3Z" />
          <path d="M62.4 47.6L51.5 36.6C48.9 34 48.9 30.1 51.5 27.5L66.3 12.7C68.9 10.1 72.8 10.1 75.4 12.7L86.4 23.7C88.9 26.2 88.9 30.1 86.4 32.7L71.5 47.6C68.9 50.2 65 50.2 62.4 47.6Z" />
          <path d="M25.9 87.4C29.7 83.5 51.7 51.2 54.9 53.1 57.5 54.4 61.4 49.9 61.4 49.9L49.1 37.6C49.1 37.6 44.6 41.5 45.9 44.1 47.8 47.3 15.5 69.3 11.6 73.2 11.6 73.2-6.5 87.4 2.6 96.4 11.6 105.5 25.9 87.4 25.9 87.4Z" />
        </g>
      </symbol>
    )
  }
}

export default IconGavel
