import React from 'react'
import Loading from '../Loading'
import useData from '../../hooks/useData'
import PropTypes from 'prop-types'
import Error from '../Error'
import Svg from '../Svg'
import Race from '../Race'

const RaceResults = ({ currentYear, url, interval }) => {
  const data = useData(url, interval)

  if (data === undefined) {
    return <Loading />
  } else if (typeof data === 'string') {
    return <Error err={data} />
  }

  data.race.level = 'local'
  return (
    <>
      <div className="President">
        <div className="section_header">
          <h2 className="hdg hdg-2">
            <Svg icon="mn" />
            {data.race.name}
          </h2>
        </div>
      </div>
      <div className="grid_col-narrow">
        <div className="section">
          <Race {...data.race} />
        </div>
      </div>
    </>
  )
}

RaceResults.propTypes = {
  url: PropTypes.string.isRequired,
  interval: PropTypes.number,
}

export default RaceResults
