import React, { useState } from 'react'
import Svg from '../Svg'
import { PARTY_CSS_CLASSES, PARTY_FULL_NAMES } from '../../config'
import { PRIMARY_CANDIDATE_COLORS_2020 } from '../../config/elections/electionConfig2020Primary'
import { slugify, useDefaultImg } from '../../util'
import PropTypes from 'prop-types'

const RaceFull = ({
  candidates,
  name,
  precinctsReportingPct,
  compact,
  level,
  source,
  district,
  addLabel,
  isPrimary,
  previousWinner,
}) => {
  console.log(level)
  const [hover, setHover] = useState({ isHover: false, hoveredCandidate: 0 })

  const handleCandidateMouseOver = (candidateId) => {
    setHover({ isHover: true, hoveredCandidate: candidateId })
  }

  const handleCandidateMouseOut = () => {
    setHover({ isHover: false, hoveredCandidate: 0 })
  }

  const hasImages = [
    'usHouse',
    'president',
    'attorneyGeneral',
    'governor',
    'usSenate',
    'mnAuditor',
    'secretaryOfState',
    'primary',
    'mnHouse',
    'mnSenate',
  ].some((item) => item === level)

  const getSortedCandidates = () => {
    const length = compact ? 2 : undefined

    return candidates
      ?.sort(function (a, b) {
        if (a.votecount > b.votecount) {
          return -1
        }
        if (a.votecount < b.votecount) {
          return 1
        }
        return 0
      })
      ?.slice(0, length)
  }

  const hasWinner = () => {
    return candidates?.some((candidate) => candidate.winner === true)
  }

  const getCandidateImage = (candidate) => {
    if (hasImages) {
      if (isPrimary) {
        return (
          <img
            src={`/data/imgs/candidates/${candidate.polid}.jpg`}
            alt={candidate.name}
            onError={useDefaultImg}
          />
        )
      } else {
        return (
          <img
            src={`/data/imgs/candidates/${slugify(candidate.name)}.jpg`}
            alt={candidate.name}
            className="results_tag_img"
            onError={useDefaultImg}
          />
        )
      }
    }
  }

  const buildPartyName = (candidate) => {
    let colr = '#333'
    const partyName = compact
      ? candidate.party
      : PARTY_FULL_NAMES[candidate.party] || candidate.party
    if (candidate.party === 'Dem') colr = '#4298b5'
    if (candidate.party === 'GOP') colr = '#eb5c40'
    if (
      candidate.winner &&
      previousWinner &&
      previousWinner?.party !== candidate.party
    ) {
      return (
        <>
          {partyName}{' '}
          <span style={{ color: colr }} className="race_party_pickup">
            ({candidate.party} pickup)
          </span>
        </>
      )
    }
    return partyName
  }

  const buildCandidateNodes = () => {
    return getSortedCandidates()?.map((candidate) => {
      const tagClasses = `results_tag ${
        !isPrimary && PARTY_CSS_CLASSES[candidate.party]
      }`
      const pctClasses = `results_pct ${
        candidate.winner ? 'results_pct_win' : ''
      } ${source}`
      const candidateImage = getCandidateImage(candidate)

      let lastNameArray = candidate.name.split(' ')
      let lastName = lastNameArray[lastNameArray.length - 1].toLowerCase()
      let color = PRIMARY_CANDIDATE_COLORS_2020[lastName]

      const candidateBarStyle = {
        backgroundColor: color,
      }

      return (
        <li
          className={`results_item ${
            hover.hoveredCandidate === candidate.candidateId
              ? 'is-selected'
              : ''
          }`}
          onMouseOver={() => handleCandidateMouseOver(candidate.candidateId)}
          onFocus={() => handleCandidateMouseOver(candidate.candidateId)}
          onMouseOut={() => handleCandidateMouseOut()}
          onBlur={() => handleCandidateMouseOut()}
          key={candidate.candidateId}
        >
          <div
            className="results_item_tag"
            style={isPrimary && candidateBarStyle}
          >
            <div className={tagClasses} style={isPrimary && candidateBarStyle}>
              {candidateImage}
              {!isPrimary && (
                <span className="results_tag_party">{candidate.party}</span>
              )}
            </div>
          </div>
          <div className="results_stats_left">
            <h4 className="hdg hdg-3">{candidate.name}</h4>
            {!isPrimary && (
              <div className="results_text">{buildPartyName(candidate)}</div>
            )}
          </div>
          <div className="results_stats_right">
            <div className={pctClasses}>
              {candidate.winner && (
                <Svg icon="checkmark" origHeight={13} origWidth={17} />
              )}{' '}
              {candidate.uncontested
                ? '100'
                : Math.round(candidate.votepct * 100)}
              %
            </div>
            <div className="results_text">
              {candidate.uncontested
                ? ' '
                : candidate.votecount.toLocaleString()}
            </div>
          </div>
        </li>
      )
    })
  }

  const buildCandidateBars = () => {
    return getSortedCandidates()?.map((candidate) => {
      let lastNameArray = candidate.name.split(' ')
      let lastName = lastNameArray[lastNameArray.length - 1].toLowerCase()
      let color = PRIMARY_CANDIDATE_COLORS_2020[lastName]

      const raceBarStyle = {
        width: candidate.uncontested
          ? '100' + '%'
          : candidate.votepct * 100 + '%',
        backgroundColor: color,
      }

      const raceBarClass = `graph_segment noPointer ${
        !isPrimary && PARTY_CSS_CLASSES[candidate.party]
      } ${
        hover.hoveredCandidate === candidate.candidateId ? 'is-selected' : ''
      } ${hover.isHover ? 'is-hover' : ''}`

      return (
        <div
          onMouseOver={() => handleCandidateMouseOver(candidate.candidateId)}
          onFocus={() => handleCandidateMouseOver(candidate.candidateId)}
          onMouseOut={() => handleCandidateMouseOut()}
          onBlur={() => handleCandidateMouseOut()}
          key={candidate.candidateId}
          className={raceBarClass}
          style={raceBarStyle}
        >
          <span className="invisible">
            {`${candidate.name}: ${Math.round(candidate.votepct * 100)}%`}
          </span>
        </div>
      )
    })
  }

  const buildRaceTitle = () => {
    if (compact) {
      return <h3 className="hdg hdg-3">{district}</h3>
    }
    return (
      <h3 className="hdg hdg-3">
        {name}
        {addLabel && addLabel}
      </h3>
    )
  }

  const buildRaceGraph = () => {
    if (!compact) {
      return (
        <div className="race_graph">
          <div className="graph">{buildCandidateBars()}</div>
        </div>
      )
    }
  }

  const resultsClass = () => {
    return compact ? 'results results-compact' : 'results'
  }

  if (Math.round(precinctsReportingPct * 100) === 0) {
    return (
      <div
        className={`race race-full ${hasWinner() && 'has-winner'}`}
        data-testid="race-full"
      >
        <div className="race_header">
          <div className="split">
            <div className="split_left">{buildRaceTitle()}</div>
            <div className="split_right">
              {Math.round(precinctsReportingPct * 100)}% Reporting
            </div>
          </div>
        </div>
        {buildRaceGraph()}
        <div className="race_body">
          {name ? (
            <ol className={resultsClass()}>{buildCandidateNodes()}</ol>
          ) : (
            <p>Polls close at 8 pm Nov 8; initial results coming soon.</p>
          )}
        </div>
      </div>
    )
  }

  return (
    <div
      className={`race race-full ${hasWinner() && 'has-winner'}`}
      data-testid="race-full"
    >
      <div className="race_header">
        <div className="split">
          <div className="split_left">{buildRaceTitle()}</div>
          <div className="split_right">
            {Math.round(precinctsReportingPct * 100)}% Reporting
          </div>
        </div>
      </div>
      {buildRaceGraph()}
      <div className="race_body">
        {Math.round(precinctsReportingPct * 100) === 0 ? (
          <p>Polls close at 8 pm Nov 8; initial results coming soon.</p>
        ) : (
          <ol className={resultsClass()}>{buildCandidateNodes()}</ol>
        )}
      </div>
    </div>
  )
}

RaceFull.propTypes = {
  candidates: PropTypes.array.isRequired,
  name: PropTypes.string,
  isPrimary: PropTypes.bool,
  precinctsReportingPct: PropTypes.number.isRequired,
  compact: PropTypes.bool,
  level: PropTypes.string,
  source: PropTypes.string,
  district: PropTypes.string,
  addLabel: PropTypes.string,
  previousWinner: PropTypes.object,
}

export default RaceFull
