import React, { Component } from 'react'

class MA extends Component {
  render() {
    return (
      <symbol id="statesvg-MA" width="100" height="100" viewBox="0 0 100 100">
        <path d="M72.73,24.06l1.43-.38.07,2H72.81l2,1.65,1,2.32-1.58-1.2v1.2L76.56,31l-.75.15.3.68,1.57.22.53.76V31.48l1.5-1,.75,2.1-2.93,2-4.8,1.8-.45,1,1-.53.37,1-2.1,1.05.75,1.35-2-.23.08,2.85L69.28,42l.45.82-1.35-.45-.45.45,1,.68-.83.3.23.9,1.27-.23-.37,1,1,.3L70.41,47,73,46.34l-.9-2.1,4.2,3,4.13,7.95-1.35.15.67-.15-.9-1.8-.45,2h-.9l2.18,2,2,.67.45,4.43.75.75,2.7,1.72,3.45.53-1.42.6,2.4-.38,6-2.55.83-.9-.83-3.37.9-.23-1.35-.9-.75,1.73-1-5.63L92,53.32l-.45,1.35-2-1.58L90.29,52h1.8l2.77,1.73L97,56.32l1.8,5-1.27-.6L97.11,63h1.43l-.23,4.43-6.45.6-2.47,1.87.15-1.42L86,69.67l-.75-.83-2.33,2.93L78,72.9l1-1.81-.15-3.67.83-1.73-.6-.52-.23.9-1.65-1.13V67h-.67l-.75,1.58-1-.45-1.2,1,.15,1.35-1.65-1.43-1.27,3.68-2.48.83-1-.08,1-.75-.53-1-.45,1-1.05-.37.38.67-.6.53-.3-5.48-1.58-.53,2.1-4.5L64.7,65.69H63.43l-.45.83L60.5,63.89l-.45-4-.82-.37-.45-4.28-37-.75-.22.75-1.13.15v-.9L2.06,53.32l-.82-.9L8.66,28,61,30.36l2.78-1.88,1.65-2.32,1.8-.15,3.9-2.4Zm8,50.79-.22,1.05,1.12-.9L82,76l-1,.6,1.65.53.22,2-4.35.22-2.77,1.5L74,79.27l2.32-.67,2.55-3.08Zm4,4-.23.23-1.27-1.13,1.27-1.5ZM96.44,78l1.5,4.2-2.7.67-4.73-1.12,5.33-1,.67-1.42-1.65,1.42,1.2-2.32-.45-1Zm.45-6.23-.6.15,1.2-3.9.45.82Z" />
      </symbol>
    )
  }
}

export default MA
