import React, { Component } from 'react'

class IconPointer extends Component {
  render() {
    return (
      <symbol id="icon-pointer" width="6" height="14" viewBox="0 0 6 14">
        <polygon points="0 14 6 6.5 0 0" fill="#004D71" fillRule="evenodd" />
      </symbol>
    )
  }
}

export default IconPointer
