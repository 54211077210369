import React from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../Loading'
import Error from '../Error'
import PresidentialPrimaryRace from './PresidentialPrimaryRace'
import { PrimaryMap } from '../Map'
import PropTypes from 'prop-types'
import useData from '../../hooks/useData'
import { geoMercator } from 'd3-geo'
import maps from '../../config/maps'
import { PRIMARY_CANDIDATE_COLORS_2020 } from '../../config/elections/electionConfig2020Primary'

const PresidentialPrimaryCountyMap = ({
  url,
  interval,
  mapName,
  candidates,
}) => {
  const data = useData(url, interval)
  let { slug } = useParams()

  const map = maps.mnPresident
  const projection = geoMercator().scale(2000)

  if (data === undefined) {
    return <Loading />
  } else if (typeof data !== 'object') {
    return <Error err={data} />
  } else if (!data?.states?.length) {
    return <Error err="Results not available" />
  }

  return (
    <div className="section">
      <div className="section section-md presidentialPrimaryDelegates">
        <div className="results_source">
          {`Data Source: ${
            data.meta.election_data_source === 'mn_oss' ? 'MN SOS' : 'AP'
          }`}
        </div>
        <PrimaryMap
          width={450}
          height={350}
          center={[-94, 46.5]}
          projection={projection}
          topoJson={map.topoJson}
          keyAttribute={map.keyAttribute}
          properName={map.properName}
          mapName={mapName}
          doNavigation={true}
          highlightZone={slug}
          data={data.states[0]['results'][0]['county_map']}
        />
        <div className="legend">
          {candidates.map((candidate) => {
            let lastName = candidate.split(' ')
            lastName = lastName[lastName.length - 1].toLowerCase()

            return (
              <div className="legend_node" key={lastName}>
                <svg viewBox="0 0 5 5">
                  <rect
                    width="5"
                    height="5"
                    fill={PRIMARY_CANDIDATE_COLORS_2020[lastName]}
                  />
                </svg>
                <span>{candidate}</span>
              </div>
            )
          })}
          <div className="legend_node">
            <svg viewBox="0 0 5 5">
              <rect
                width="5"
                height="5"
                fill={PRIMARY_CANDIDATE_COLORS_2020['writeIn']}
              />
            </svg>
            <span>Write-In</span>
          </div>
        </div>
        {slug && <PresidentialPrimaryRace slug={slug} party="DEM" />}
      </div>
    </div>
  )
}

PresidentialPrimaryCountyMap.propTypes = {
  url: PropTypes.string.isRequired,
  interval: PropTypes.number,
  mapName: PropTypes.string,
  candidates: PropTypes.array,
}

export default PresidentialPrimaryCountyMap
