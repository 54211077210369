import React from 'react'
import PropTypes from 'prop-types'

const Svg = ({ elementClass, origHeight, origWidth, icon, ...rest }) => {
  elementClass = elementClass ? elementClass : ''
  origHeight = origHeight ? origHeight : 100
  origWidth = origWidth ? origWidth : 100

  return (
    <svg
      className={`icon icon-${icon} ${elementClass}`}
      viewBox={`0 0 ${origWidth} ${origHeight}`}
      {...rest}
    >
      <use xlinkHref={`#icon-${icon}`} />
    </svg>
  )
}

Svg.propTypes = {
  elementClass: PropTypes.string,
  origHeight: PropTypes.number,
  origWidth: PropTypes.number,
  icon: PropTypes.string,
}

export default Svg
