import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import PresidentElectionTableResults from './PresidentElectionTableResults'
import { API_ENDPOINT } from '../../config'
import ElectionContext from '../../context/ElectionContext'

const PresidentElectionTable = () => {
  const context = useContext(ElectionContext)

  if (!context.priorityPresidentialCandidates) return null

  return (
    <div className="ElectionTable">
      <PresidentElectionTableResults
        interval={30000}
        url={`${API_ENDPOINT}/${context.date}/us_president_races`}
        priorityCandidates={context.priorityPresidentialCandidates}
      />
    </div>
  )
}

PresidentElectionTable.propTypes = {
  countyCode: PropTypes.string,
}

export default PresidentElectionTable
