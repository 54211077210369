import React from 'react'
import Loading from '../Loading'
import { CountyMap } from '../Map'
import RaceFull from '../RaceFull'
import useData from '../../hooks/useData'
import Error from '../Error'
import PropTypes from 'prop-types'
import { geoMercator } from 'd3-geo'
import maps from '../../config/maps'
import { getWinningCandidate } from '../../util'

const GovernorResults = ({ url, interval, previousElection }) => {
  const data = useData(url, interval)
  const map = maps.mnGovernor
  const projection = geoMercator().scale(2000)
  const prevRace = previousElection ? previousElection.governor[0] : undefined
  if (data === undefined) {
    return <Loading />
  } else if (typeof data === 'string') {
    return <Error err={data} />
  }

  return (
    <div>
      <div className="section">
        <div className="section section-md">
          <CountyMap
            width={450}
            height={350}
            center={[-94, 46.5]}
            projection={projection}
            topoJson={map.topoJson}
            keyAttribute={map.keyAttribute}
            properName={map.properName}
            data={data[`${map.dataLabel}Map`]}
            previousElection={
              previousElection
                ? previousElection[`${map.dataLabel}Map`]
                : undefined
            }
          />
        </div>
      </div>
      <h3 className="hdg hdg-3">Minnesota Popular Vote</h3>
      <RaceFull
        {...data.governor[0]}
        level="governor"
        key="governor-minn-full"
        previousWinner={getWinningCandidate(prevRace?.candidates)}
      />
    </div>
  )
}

GovernorResults.propTypes = {
  url: PropTypes.string.isRequired,
  interval: PropTypes.number,
  previousElection: PropTypes.object,
}

export default GovernorResults
