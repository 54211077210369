import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import useData from '../../hooks/useData'
import ElectionContext from '../../context/ElectionContext'
import LegislatureResults from './LegislatureResults'
import { API_ENDPOINT } from '../../config'
import { US_HOUSE_DISTRICT_PATTERN } from '../../util'
import usePreviousElection from '../../hooks/usePreviousElection'

const UsHouse = () => {
  const context = useContext(ElectionContext)
  const { slug } = useParams()
  const previousElection = usePreviousElection(
    context,
    'legislature',
    'us_house_races'
  )
  let isSlugGood = US_HOUSE_DISTRICT_PATTERN.test(slug)
  let url = `${API_ENDPOINT}/${context.date}/us_house_races`
  let slugUrl = isSlugGood ? `${url}/${slug}` : url

  const data = useData(slugUrl, 30000)

  return (
    <div className="Legislature">
      <Helmet>
        <title>MPR News | Live Results for U.S. House</title>
        <meta
          name="twitter:title"
          content="LIVE Election Results from MPR News | U.S. House"
        />
        <meta
          property="og:title"
          content="LIVE Election Results from MPR News | U.S. House"
        />
      </Helmet>
      <div className="section_header">
        <h2 className="hdg hdg-2 mobileCentered">U.S. House</h2>
      </div>
      <LegislatureResults
        data={data}
        mapTag="usHouse"
        url={url}
        name="US House"
        previousElection={previousElection}
      />
    </div>
  )
}

export default UsHouse
