import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import Error from './components/Error'
import ElectionContext from './context/ElectionContext'
import ComponentChooser from './components/ComponentChooser'
import { isInternalLink } from './util'

const Routes = () => {
  const context = useContext(ElectionContext)
  const navRoutes = context.nav.filter((item) => !isInternalLink(item.href))
  const overviewRoutes =
    (Array.isArray(context?.overview) &&
      context?.overview?.filter(
        (item) =>
          item.href && !navRoutes.find((navItem) => navItem.href === item.href)
      )) ||
    []
  const routes = [...navRoutes, ...overviewRoutes]

  return (
    <Switch>
      <Route exact path={`/${context.date}/`}>
        <ComponentChooser href="/" title="Home" />
      </Route>
      <Route path={`/${context.date}/races/:id/frame`}>
        <ComponentChooser href={`/races/frame`} title="Race" />
      </Route>
      {routes.map((item, index) => {
        return (
          <Route
            exact
            path={`/${context.date}/${item.href}/:slug?`}
            key={item.href}
          >
            <ComponentChooser href={item.href} title={item.title} />
          </Route>
        )
      })}
      {routes.map((item, index) => {
        return (
          <Route
            exact
            path={`/${context.date}/${item.href}/:slug/frame`}
            key={item.href}
          >
            <ComponentChooser href={`${item.href}/frame`} title={item.title} />
          </Route>
        )
      })}
      <Route>
        <Error err="404 Page Not Found" />
      </Route>
    </Switch>
  )
}

export default Routes
