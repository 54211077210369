import React, { Component } from 'react'

class IconStar extends Component {
  render() {
    return (
      <symbol id="icon-star" width="100" height="100" viewBox="0 0 100 100">
        <polygon
          fillRule="evenodd"
          points="50 74.2 19.1 96.33 31 60.65 0 38.65 38.26 38.73 50 3 61.74 38.73 100 38.65 69 60.65 80.9 96.33"
        />
      </symbol>
    )
  }
}

export default IconStar
