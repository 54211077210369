import React from 'react'

const FooterSubscribe = () => {
  return (
    <div className="footer_subscribe">
      <a
        href="https://www.mprnews.org/newsletter"
        className="link link-plain text-bold"
      >
        Subscribe to email newsletters
      </a>
    </div>
  )
}

export default FooterSubscribe
