import React, { Component } from 'react'

class SC extends Component {
  render() {
    return (
      <symbol id="statesvg-SC" width="100" height="100" viewBox="0 0 100 100">
        <path d="M24.74,16.84,48.32,18l.18,1.9,2.08-1,2.62,3.25.09,3,20.32.54L93.48,45.2l-.54.18.54.73-3.43,1.53-3.16,2.89-3.43,5.06-.91,4.34L82,58.21l-1-.36,1.35-2.8-.63.72-1.26,2.44,2.07,2.53-.63.9L81,61.1V62l-1.18-.72.82,1.09-1.36,1-.36,1.08-1.9.45-.54-1-1.17.54-.72,1.72.36.45.72-.45-.36.45L71,70.13l-1.53-.9.27-1.17h-1l.37,1.53h-.73l1.9.91-.18.81-1.36,1.08-1.35.18.27.82-3.07.9L63,73l1,1.45-2.26,1.72-.63-1.09-.55,1.27-2.8-1.9.18,1H55.36l-.09.55,3.8.36L57,76.82l2.44.72L57.8,79.08l2.08-1.36-.63,1.9L56.09,81l-.36-4.24h-.46v3.43L53.83,78.9,53.29,76l-1.36-1.09L53.2,79.8l2.07,2.26-2.62,2.26.82-2.08-.18-.91L51.39,86l-2.08-1.17H48L46.42,82.6,47,79.8,45.7,76.19,41.91,73V70.5l-1.72-4-.36-2.44-4.52-2.62-.18-1.17L33.78,59.2l.18-1L32.42,57l-.18-2.44L30,52.25l-2.08-.81-1.71-3.62-5.42-4.15-.63-1.9-2.08-2.62L15.8,32.92,12.46,32l-2.17-2.53L7,27.23l.9-2.44L11.46,21l6.6-1.44,1.17-1.18,4.52-1,.72-.9ZM79.48,64.53v-.81l.36-.36v.36Z" />
      </symbol>
    )
  }
}

export default SC
