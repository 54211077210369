import React, { Component } from 'react'

class UT extends Component {
  render() {
    return (
      <symbol id="statesvg-UT" width="100" height="100" viewBox="0 0 100 100">
        <path d="M19.61,2.26l37.76.39V21.79L86.6,21.4l1.94,76.95L50,99l-38.54-.65L14.17,2ZM31,9.37,28.66,10l.65.77-.65.65,1,3.88-1,.91.65.26.9-1.17,1,1.17.9,1.68.13,3.49L34.22,23,35.39,25,36.16,23l1.29,4.53.91.39,2.33-1.68.9-1.94,2.85-1.82-2.59-1.94-2.2-3.88-.9-.12,1.16-1.3,2.07.13.65-1H39.39v-1L37.71,13l.65,4.27-1,.65L36,13.64l-1.81-.26-1.29-1.29.52-2.72-1.17-.9Zm4.52,12.42-.12-.65.12-.13.26.39Zm3.24-2.59-1-.77.65-.26ZM41,24.25l-1-1.82V20.37l1,1.94Z" />
      </symbol>
    )
  }
}

export default UT
