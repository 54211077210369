import React, { Component } from 'react'

class MI extends Component {
  render() {
    return (
      <symbol id="statesvg-MI" width="100" height="100" viewBox="0 0 100 100">
        <path d="M23.58,10.34l1.89,2-1.89,2h1.89l2.11-2-2.11,2,2.11-2H31.8l1.88,2,2.22,4.11,1.89,1.88,2-1.88,2,1.88h2.1l6.22-4.1h6.1l6.11-1.89v6h6.22l2,2.11v2.11h2v2h2.11v2l-6.1-2-2.22,2v-2l-2.11,4.22-1.89-4.22H56.21l-2,2H48v2.22H46l-2.11,4V28.55l-2.1,2.22v2.11h-2v0l-6.11,8H31.8v-4H29.69l2.11-4-4.22-2.11V28.55l-4-2H19.25l-1.88-2L7.16,20.33,5.05,18.45h-2l6.22-4.11h6l2.11-4h1.88l4.33-2.1ZM31.8,4.13l-2.11,2L27.58,8.24l-2.11,4.1-1.89-2h1.89v0l-1.89-2.1,4-4.11ZM66.32,30.77l2.22,2.11h2l2,1.88h2.11l6.22,4v2.11l-2.22,2L80.86,45v8.22L78.64,55.3v2l-4,2.11v4l4,1.89V63.4h2.22v-4h1.88l2-2.11,4.11,2.11v1.89L93,73.5l-2,6.21v0H88.85l-2.11,2.11v1.89l-2,2.11v4.11L80.86,94H41.79l2.1-2.11L46,85.82l2-6.11V71.5l-4.11-6.21,2.11-4v-4l2-4.1V49.08L50.11,47V45l2-2.11V45l4.1-4.1-2,4.1,2,2v0l2.11-4.11v-4.1l4-1.89-2.11-2.11,2.11-1.88V30.77ZM43.89,20.33v0Zm8.22,22.53h-2v-2h2Zm4.1-8.1v0Zm12.33-4v2.11l-2.22-2.11Zm4-8.33h-2v-4h2l-2,1.88Zm0,2.11-2-2.11h2Zm6.11,2v2l-4-2h4Zm6.1,61.27v0Z" />
      </symbol>
    )
  }
}

export default MI
