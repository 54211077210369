import React from 'react'
import RaceFull from '../RaceFull'
import { CountyMap } from '../Map'
import Loading from '../Loading'
import { raceLevel, isEmpty } from '../../util'
import useData from '../../hooks/useData'
import PropTypes from 'prop-types'
import Error from '../Error'
import { geoMercator } from 'd3-geo'
import maps from '../../config/maps'

// TODO: Make these CountyMaps as well
const StatewideResults = ({ url, interval }) => {
  const data = useData(url, interval)
  const projection = geoMercator().scale(2000)

  const attorneyGeneralMap = maps.attorneyGeneral
  const secretaryOfStateMap = maps.secretaryOfState
  const auditorMap = maps.auditor
  const amendmentMap = maps.amendment

  if (data === undefined) {
    return <Loading />
  } else if (typeof data === 'string') {
    return <Error err={data} />
  }

  const buildRaceNode = (jsonSlug) => {
    return data[jsonSlug].map((race) => {
      let level = raceLevel(race)

      return <RaceFull {...race} level={level} key={race.district} />
    })
  }

  return (
    <div>
      {data.attorneyGeneral.length > 0 && !isEmpty(data.attorneyGeneralMap) && (
        <div className="grid grid-fill">
          <h2 className="hdg hdg-2 mobileCentered">Attorney General Results</h2>
          <div className="section section-md">
            <div className="grid-col grid_col-half">
              <CountyMap
                width={450}
                height={350}
                center={[-94, 46.5]}
                projection={projection}
                topoJson={attorneyGeneralMap.topoJson}
                keyAttribute={attorneyGeneralMap.keyAttribute}
                properName={attorneyGeneralMap.properName}
                data={data[`${attorneyGeneralMap.dataLabel}Map`]}
                mapName={attorneyGeneralMap.dataLabel}
              />
            </div>
            <div className="grid-col grid_col-half">
              {buildRaceNode('attorneyGeneral')}
            </div>
          </div>
        </div>
      )}
      {data.secretaryOfState.length > 0 && !isEmpty(data.secretaryOfStateMap) && (
        <div className="grid grid-fill">
          <div className="grid grid-fill">
            <h2 className="hdg hdg-2 mobileCentered">
              Secretary of State Results
            </h2>
            <div className="grid-col grid_col-half">
              <div className="section section-md">
                <CountyMap
                  width={450}
                  height={350}
                  center={[-94, 46.5]}
                  projection={projection}
                  topoJson={secretaryOfStateMap.topoJson}
                  keyAttribute={secretaryOfStateMap.keyAttribute}
                  properName={secretaryOfStateMap.properName}
                  data={data[`${secretaryOfStateMap.dataLabel}Map`]}
                  mapName={secretaryOfStateMap.dataLabel}
                />
              </div>
            </div>
            <div className="grid-col grid_col-half">
              {buildRaceNode('secretaryOfState')}
            </div>
          </div>
        </div>
      )}
      {data.auditor.length > 0 && !isEmpty(data.auditorMap) && (
        <div className="grid grid-fill">
          <h2 className="hdg hdg-2 mobileCentered">Auditor Results</h2>
          <div className="grid-col grid_col-half">
            <div className="section section-md">
              <CountyMap
                width={450}
                height={350}
                center={[-94, 46.5]}
                projection={projection}
                topoJson={auditorMap.topoJson}
                keyAttribute={auditorMap.keyAttribute}
                properName={auditorMap.properName}
                data={data[`${auditorMap.dataLabel}Map`]}
                mapName={auditorMap.dataLabel}
              />
            </div>
          </div>
          <div className="grid-col grid_col-half">
            {buildRaceNode('auditor')}
          </div>
        </div>
      )}
      {data.amendment.length > 0 && !isEmpty(data.amendmentMap) && (
        <div className="grid grid-fill">
          <h2 className="hdg hdg-2">Amendment Results</h2>
          <div className="grid-col grid_col-half">
            <div className="section section-md">
              <CountyMap
                width={450}
                height={350}
                center={[-94, 46.5]}
                projection={projection}
                topoJson={amendmentMap.topoJson}
                keyAttribute={amendmentMap.keyAttribute}
                properName={amendmentMap.properName}
                data={data[`${amendmentMap.dataLabel}Map`]}
                mapName={amendmentMap.dataLabel}
              />
            </div>
          </div>
          <div className="grid-col grid_col-half">
            {buildRaceNode('amendment')}
          </div>
        </div>
      )}
    </div>
  )
}

StatewideResults.propTypes = {
  url: PropTypes.string.isRequired,
  interval: PropTypes.number,
}

export default StatewideResults
