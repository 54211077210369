import React from 'react'
import PropTypes from 'prop-types'

const FooterProgramming = (props) => {
  const openInNewWindow = () => {
    window.open(
      'https://www.mprnews.org/listen',
      'Listen Page',
      'resizable,height=842,width=776'
    )
  }
  return (
    <div className="footer_prog">
      <div className="footer_row">
        <h3 className="hdg hdg-5">{props.title}</h3>
      </div>
      <div className="footer_progListen">
        <button
          href="https://www.mprnews.org/listen"
          onClick={(e) => {
            e.preventDefault()
            openInNewWindow()
          }}
        >
          {props.listenText}
        </button>
      </div>
      <div className="footer_progLinks">
        <ul className="vList">
          {props.links.map((link) => {
            return (
              <li key={link.href}>
                <a href={link.href} className="link link-plain">
                  {link.label}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

FooterProgramming.defaultProps = {
  title: 'Program information',
}

FooterProgramming.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
    })
  ),
  listenHref: PropTypes.string,
  listenText: PropTypes.string,
}

export default FooterProgramming
