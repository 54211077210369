import React, { Component } from 'react'

class IconMnUsHouseDist extends Component {
  render() {
    return (
      <symbol
        id="icon-mn-us-house-dist"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <g strokeWidth="2" fillRule="evenodd">
          <polygon points="72.1 87.8 73.8 88.9 76.4 90.7 77.4 91 78.9 93 79.8 94.7 79.6 97.5 80 97.9 80.2 99.3 44.3 99.9 15.5 99.5 15.8 93.5 32.4 93.9 32.5 89.5 31.5 89.5 31.7 88.3 32.2 88.3 32.4 86.6 35.4 86.6 35.4 82.9 36.3 83.7 46.8 83.7 47 82.2 53.7 82.2 53.8 83.3 54.7 83.7 54.7 85.1 56.1 85.1 56.2 88 66.4 87.9 66.5 89.4 69.4 89.4 69.4 87.9" />
          <polygon points="55.7 76.8 55.4 77.3 54.6 77.8 54 78.1 53.5 78.1 53.4 77.9 53.1 77.7 52.7 77.7 52.5 77.8 52.1 77.8 51.5 77.8 51.4 77.9 51 78 51 78.2 50.9 78.3 50.3 78.3 50.3 78 49.9 78 49.9 77.8 48.8 77.8 48.8 73.3 49.8 71.9 49.9 72 50.4 72 50.4 71.5 50.1 71.2 51.5 70.5 51.6 70.7 51.7 70.3 52.8 70.8 53.5 71.4 53.7 70.9 54.8 70.9 54.8 72.3 54.5 72.5 54.7 72.7 54.6 73.1 53.2 73.4 53.2 74.8 53.1 74.8 53.2 75.5 52.9 75.7 53 76 53.2 76 53.2 75.8 53.8 75.9 53.8 76.3 54.2 76.3 54.2 76.8" />
          <polygon points="61 69.3 60.9 69.6 61 69.9 61 70.7 60.8 71.2 61.1 72.3 54.8 72.3 54.8 70.9 53.7 70.9 53.5 71.4 52.7 70.8 52.2 70.7 51.7 70.3 51.6 70.7 50.1 71.2 50.4 71.5 50.4 72 49.8 71.9 48.8 73.3 48.8 77.8 50.9 78.3 50.6 79.4 50.1 79.6 49.4 80.3 48.8 80.6 48.8 80 47.3 80 47.3 79.3 45.8 79.3 45.9 74.9 42.9 74.8 42.9 70.4 42.9 69.7 41.4 69.7 41.4 68.9 38.4 68.9 38.4 67.5 39.4 67.4 39.3 67 40 66.7 40 61.4 42.8 61.3 41.9 60.5 48.9 60.5 48.9 65 51.9 65 51.9 67.4 57.8 67.4 57.8 69.4" />
          <polygon points="72.1 87.8 69.4 87.9 69.4 89.4 66.5 89.4 66.4 87.9 56.2 88 56.1 85.1 54.7 85.1 54.7 83.7 54.5 83.7 54.5 83.4 54.3 83.3 53.8 83.3 53.7 82.2 47 82.2 47.3 81.3 48.4 80.7 48.5 80.8 49.6 80.1 49.9 79.7 50.6 79.4 50.4 78.9 50.7 78.6 50.5 78.6 51.5 77.8 52.7 77.7 53.5 78.1 54.4 77.9 55 77.7 55.5 77.2 55.6 76.7 55.9 76.3 56.5 75.8 57.4 75.8 57.8 76.3 58 77.1 58.2 77 58.2 76.7 60.8 76.7 60.9 77.2 60.4 78.6 62.5 80.3 62.7 80.9 65 81.7 66.2 81.8 66.4 82.1 66.7 82.9 68 83.9 68.7 84 70.4 84.9 71.2 85.5 71.3 85.9 71.2 86.1 71.6 87.2" />
          <polygon points="55.9 76.3 55.9 76.5 55.6 76.7 55.7 76.8 54.2 76.8 54.2 76.3 54.1 76.4 53.8 76.3 53.8 75.9 53.8 75.8 53.2 75.8 53.2 76 53 76 52.9 75.8 52.9 75.7 53 75.6 53 75.4 52.9 75.4 53.1 75.3 53.1 75.5 53.2 75.5 53.2 75.1 53.1 74.8 53.2 74.8 53.2 73.4 53.8 73.4 53.8 73 54.7 73 54.6 73.1 54.7 73.2 54.7 72.7 54.5 72.5 54.5 72.4 54.7 72.4 54.6 72.3 54.8 72.3 55.3 72.3 55.3 73.6 55.4 73.6 55.4 73.7 55.5 73.7 55.5 75.2 55.6 75.3 55.6 75.7 55.6 76 55.7 76.1 55.8 76.2 55.9 76.2" />
          <polygon points="55.8 76.2 55.6 76 55.5 75.2 55.5 73.7 55.4 73.7 55.4 73.6 55.3 73.6 55.3 72.3 61.1 72.3 61.1 72.5 60.4 73.2 60.4 73.5 60.9 74 60.8 74.9 61 75.4 60.9 75.9 60.8 76.7 58.2 76.7 58.2 77 57.9 77 57.9 76.8 57.8 76.3 57.6 76.2 57.4 75.8 56.5 75.8 56.2 76.3 56 76.3" />
          <polygon points="40 61.4 40 62.9 40 64.6 40 66.7 39.6 66.6 39.3 67 39.4 67.4 38.4 67.5 38.4 68.9 39.4 68.9 39.6 68.8 41.4 68.9 41.4 69.7 42.9 69.7 42.9 70.5 42.9 72 42.9 74.8 45.9 74.9 45.8 79.3 47.3 79.3 47.3 80 48.8 80 48.8 80.7 48.5 80.8 47.9 80.8 47.7 81.2 47.2 81.5 47 82.3 47.3 82.9 47.1 83.4 46.8 83.7 36.3 83.7 35.6 83 35.4 82.9 35.4 86.6 32.4 86.6 32.4 88 32.2 88 31.7 88.3 31.7 89 31.5 89 31.5 89.5 32.5 89.5 32.4 93.9 15.8 93.5 16.6 69 16.2 68 14.7 67.1 14 67 12.6 64.5 12 63.6 12.2 62.9 13.3 62.1 14.6 61.3 15.4 60.1 15.7 58.4 15.6 56.5 15.9 55.6 15.5 51.4 14.3 49.5 14 48.5 13.4 46.2 13.5 43 14 41.1 13.7 41.3 13.3 39.9 13.5 39.6 13.4 29.7 12.6 27.8 12.2 26.2 12 25.5 11.8 24.4 11.3 23.6 11.2 22.7 11.2 22.1 10.9 21.7 10.5 20.1 10.9 18.2 10.8 15.6 10.5 13.4 11.5 11.4 10.5 9.1 10.2 6 33.4 6.6 33.5 0.1 35.1 0.6 35.4 0.4 37.3 1.2 37.5 3 37.9 5 37.9 6.6 38.3 6.6 38.6 8.6 38.5 9.9 39.3 11.1 41.5 11.7 41.5 24.8 37.8 24.7 37.7 25.7 37 25.7 37 26.9 36.8 26.9 35.6 26.9 35.5 27.7 35.6 28.1 35.5 29.1 35.5 31.6 35.6 31.9 35.8 31.8 36.2 32 37 32.1 37 33.6 35.7 33.6 35.6 33.6 34.1 33.5 32.6 33.5 32.6 51.2 37.5 51.3 37.6 51.5 38.3 51.4 38.4 51.6 38.4 61.3" />
          <polygon points="41.4 11.7 43.2 11.8 47.9 12.9 49.2 14.8 52.3 14.3 53.6 13 58 12.8 61.4 14.2 61.3 16 63.4 16.2 64.7 19.7 65.8 19 65.9 17.4 68.3 17.4 69.2 19.2 72.3 20.3 74.1 21.4 76.2 22 82.1 18.8 82.2 19.9 83.3 21.2 90 20.6 93.7 22.4 97.5 22.1 84.5 28.8 75.8 37.4 66.1 46 66.2 55.7 65.3 57.1 64.2 57.8 63.5 57.7 63.2 58.2 61.7 58.9 60.7 60.3 59.8 62 59.3 64.6 60.7 64.7 62.1 66.6 62.2 67.3 61.5 68.4 61 69.3 57.8 69.4 57.7 67.4 51.9 67.4 51.9 65 48.9 65 48.8 60.5 42.8 60.5 41.9 60.5 42 60.7 42.8 61.3 38.4 61.3 38.4 51.6 32.5 51.2 32.5 33.5 37 33.6 37 32.1 35.6 31.8 35.5 26.9 37 26.9 37 25.7 37.7 25.7 37.7 24.7 41.4 24.7 41.4 14" />
        </g>
      </symbol>
    )
  }
}

export default IconMnUsHouseDist
