import React, { Component } from 'react'

class VA extends Component {
  render() {
    return (
      <symbol id="statesvg-VA" width="100" height="100" viewBox="0 0 100 100">
        <path d="M68.57,33.28,70,30.78l1.94.28,1.3,1.2-.56,1.39,2.59,1.11,2.49,2.12V39.1l-1.1.65-.19.92-.74-.09L74.85,44l-.65-.47,1,.93-.83.37L78.08,44l.28.83-.65.27,1.39,1.57,2.4.93,1.57-.09.83,1.84,3.24,1.57v1.3L86,51.76l.46,3.14L84.64,54l-.46.74L79.1,48.43l-1.39-1.11-.83.19,2.22,1.1,4.8,6.29,2.78.92-1.11.65,1.75.27-.18,2.68-1.67-1.84-.37,1.75.84.55-1,.37L81,56.47l-.18.27L84,60.25l1.75.47.93,1.2-.28.55.74.09-.46,1.3-1.21.46L82.7,60.9l-1,.46-1.48-1.94-.93,1.3-.83-1.3-.37.65-2.4-.92-.18.64,2.21.46.65-.37.65.84,2.68,1.1.74-.46.27,2,2.13,1.29-.65,1.38,1.76-.46.92.46-.18-1.38,3.78.46,1.76,5.36-1.48-2.77v2.77l-.92-.65.18.65-23.46.28H42.7L1.5,68.29l6.28-1.75.83-1.39,2.31-.46,2.4-3.33,4-2,4.34-3.51.28,2.4,4,2.5L28.38,59l1.67,1.29,4.06-.92L34.39,58l1.29.46,2.5-1.11L39,58l1.84-1.3.84-1.57L41,54.25l5.63-8.31,1.76-4.81,1.29.56L50,43.07l2.49.83,3.33-6.19,1.48.83,5-5.26.92-4.07Zm27.9,17.37L94.62,56.1l-.65.55-.55-.83-.46.65.46.18-1,.74-.64,4-.37-.64-.65,1.1L90,59.33l.92-4.25,1.3-1,.18-1.84,1.39-.46L93,50.83l1.11-1.48,3-.27ZM91.39,70v.56h-.1l-.37-.37Zm1-9.42h-.55l.65-1.11v.83Zm1-2.31L93.33,58,94,57.3l-.18.09Zm4.16-7.39-.37.28h-.47l1.76-2.5Z" />
      </symbol>
    )
  }
}

export default VA
