import React, { Component } from 'react'

class ME extends Component {
  render() {
    return (
      <symbol id="statesvg-ME" width="100" height="100" viewBox="0 0 100 100">
        <path d="M51.18,6.67l.94.94,5.76-2.94,1.41.59,1.88-1.64,4.59,3.05,3,3.06L69.52,36l-.59,4.35,3.41,2.12L75,43v1.18l-1.17,1.06L75,47.71l-.47,2.58,2.12,2.24.94-.94,1.76.47,2,4.35-.35.7-.94-.23v1.29l-1.29-.47.7,2.24,1.41-1.65.47,1.29L81,58.17l1.41,1.53-3.17,3.88-1.41-.12-.24.59-.59-2-1.29.35.82,1.65-.82.82-.59-1.41L75,64.64l-1.88-.82-.47,2.58-1.17-.82-.83,1.53-.11-2.24L69.52,66.4V64.87l-.59.36-1.06,4-.12-2-.47,1.89-.58-2-.94.59.94,1.52L65.4,71l-.94-3.41-.7-.47-.36.82-.35-1.53-.23,1.3-1.3-1.3.24,1.77-1.41-.47-.83,1.52-.23-2h-.82l.35,2.12-.35-.47-1.3.24.59,3.76L54.47,71l-1.3.23.24-1.17,1.76-.94-.7-.71-1.3.82,1.06-2.94-.82-.7v2l-2.94,1.29L51.29,71l-2,4.11.12,1.77-2.23,1.29v1.18L46,79.69l.59-1.77-1.53,1.42.47-.71H44.24V76.87L42.12,81.8l-.59-1.41V81.8l.24-4.11-1.06,1.65-.12,2.93v-.82l-.94-.23L40,79.81l-.36-.71L40,77.57l-2.12,3.06.35,1.29.59-.47-.94,2-.12-6.58-1.64,1.76.11.47.71-.59.7,5.53L37,85l-.82-3.76v1.29L34.24,84l.83-2.24-1.53,1.77,1.29-2.24-.94.47.47-.58-3.29,2-.94,2.23-.82.23,1.29.71v1.18l-2.12.7L27.9,89.8l.7.35-3.41,2.59-2.35,5.64-.94-.47-.59.59-1.06-2,.24-1.76-2.24-3.41-.7-42.56,1.53-1,1.41,2.59,1.41-4.23,1.53.82,1.17-.35-1.29-2.23,2.12-3.3,2.94-1.29V38.07l2.35-2.83L30,34.07,30.13,32l.94-1.41L30.6,28.9l1.65-3.06L34,24.31l.82-6.23,12-16.58,2.7.59.35,3.53Zm2.23,70.9-1.18-.47L52,75.81l1.76.7Zm2.94-4.47-.59.47.59,1.18H55.06l.58-2.59Zm6.7-1.76H61.17l.47,1.29-.71.47-1.64-1.76,1.06-2.47,1.41-.47,1.64,2Z" />
      </symbol>
    )
  }
}

export default ME
