import logo from '../../assets/svg/logo-election2016.svg'

const nav = [
  {
    title: 'Post-Election Analysis',
    href: 'post',
    icon: 'infographic',
  },
  {
    title: 'Presidential Results',
    href: 'president',
    icon: 'whitehouse',
  },
  {
    title: 'National Results',
    href: 'national',
    icon: 'us',
  },
  {
    title: 'US House Districts',
    href: 'us/house',
    icon: 'mn-us-house-dist',
  },
  {
    title: 'Statewide Races',
    href: 'statewide',
    icon: 'mn',
  },
  {
    title: 'Minnesota Senate',
    href: 'mn/senate',
    icon: 'mn-senate',
  },
  {
    title: 'Minnesota House',
    href: 'mn/house',
    icon: 'mn-house',
  },
  {
    title: 'Local Results by County',
    href: 'county',
    icon: 'mn-counties',
  },
  {
    title: 'Live Blog',
    href: 'https://live.mprnews.org/Event/Election_2018',
    icon: 'live',
  },
]

const overview = [
  {
    title: 'President',
    href: 'president',
    buttonText: 'Map & Details',
    type: 'PresidentialNational',
  },
  {
    type: 'PresidentialMinn',
  },
  {
    title: 'US Senate and House',
    href: 'national',
    buttonText: 'More National Results',
    type: 'BalanceOfPower',
    area: 'US',
    bop: 'bopUs',
  },
  {
    title: 'US House in Minnesota',
    label: 'usHouse',
    href: 'us/house',
    buttonText: 'Map & Details',
    type: 'Race',
  },
  {
    title: 'Minnesota Legislature',
    type: 'BalanceOfPower',
    area: 'MN',
    bop: 'bopMn',
  },
  {
    title: 'Statewide Races',
    href: 'statewide',
    buttonText: 'See All Races',
    text: 'Minnesota Supreme Court and constitutional amendment',
  },
  {
    title: 'Results by County',
    href: 'county',
    buttonText: 'View Counties',
    text: 'Mayoral races, local ballot measures and more',
  },
]

const national = [
  {
    title: 'President',
    href: 'president',
    buttonText: 'Map & Details',
    type: 'PresidentialNational',
  },
  {
    type: 'PresidentialMinn',
  },
  {
    title: 'US Senate and House',
    href: 'national',
    buttonText: 'More National Results',
    type: 'BalanceOfPower',
    area: 'US',
    bop: 'bopUs',
  },
  {
    title: 'US House in Minnesota',
    label: 'usHouse',
    href: 'us/house',
    buttonText: 'Map & Details',
    type: 'Race',
  },
]

const electionConfig2016 = {
  ap: false,
  slug: 'electionConfig2016',
  date: '2016-11-08',
  nav: nav,
  year: '2016',
  overview: overview,
  national: national,
  logo: logo,
}

export default electionConfig2016
