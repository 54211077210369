import React, { Component } from 'react'

class ID extends Component {
  render() {
    return (
      <symbol id="statesvg-ID" width="100" height="100" viewBox="0 0 100 100">
        <path d="M81,64.51l.55,31.3L53.67,95.7,25.92,94.59l1.11-23,1.66-4.22-.89-1.11-1.88-.55V63.4l3.44-4.77.22-1.77,4-7.11-1-2-2.11-2.1-1.67-6L30.8,7,39,7.58l-.66,12.2,2.77,4-.22,1.89.56.88-.56.56,3.33,2.33,4.33,7.1,1-.44.77,1.22,2.22-.22-1.22,6.1-.55.34.89,3.55-1.45,1.22.56.89-.56,1.66,2.22,1.22,2.89-2.66,1.33,1.22.78,3.66L59.77,58v1.88l1,1,1.66.11,1.34,4L65,66.18l1-1.56,3.1.45,1.22-1.33,7.11.22L77,62.74l1.11-1.44Z" />
      </symbol>
    )
  }
}

export default ID
