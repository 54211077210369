import React from 'react'
import PropTypes from 'prop-types'
import Svg from '../Svg'

const FooterContact = (props) => {
  return (
    <div className="footer_contact">
      <div className="footer_row">
        <h3 className="hdg hdg-5">Connect with us</h3>
      </div>
      <ul className="footer_contactList">
        {props.links.map((link) => {
          return (
            <li key={link.href}>
              {link.href.startsWith('mailto:') ||
              link.href.startsWith('tel:') ? (
                <a href={link.href} className="footer_link footer_link-icon">
                  {link.label}
                </a>
              ) : (
                <a href={link.href} className="footer_link footer_link-icon">
                  {link.icon && (
                    <Svg
                      icon={link.icon}
                      origHeight={link.height}
                      origWidth={link.width}
                    />
                  )}{' '}
                  {link.label}
                </a>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

FooterContact.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      icon: PropTypes.string,
      label: PropTypes.string,
    })
  ),
}

export default FooterContact
