import React, { Component } from 'react'

class OH extends Component {
  render() {
    return (
      <symbol id="statesvg-OH" width="100" height="100" viewBox="0 0 100 100">
        <path d="M88.92,39.25,86.23,40.7l1.23,2.36L87,45.63l.44,1.46-2.24,5.6L85,56.27h-.9l.23,1.23L83.1,59.86l.56,1.23-.56,2.35-6.28,5.49-2.57,1.23-1-1.46h-.9l-1.68,1.46-.44,1.68h-1.8l-1.34,2.35v1.68l-1.23.34.89,1.12.34,2.24-1.23-.23-1.23,1.91-.9-2.69-1-.67-1,.45-2.8,5,.68,4L57.45,88l-.79,3.36L51.74,92l-1.8-2.69L47.59,88l-1.45-3.7-2.36.79-2.24,2.35-2.8.22-1-1.34-2.92-1.23-1.9.67-.45,1H31.24l-2.8-3.14-1.9-.45-2,.23-2.35-1.24-1.68-4.59L17.8,75.42,14.66,76,13,74.3l-1.9,1.12,2-60.92,23.52-.23,6.6,2.58,1.68,1.9,1.8.11.67-1.23,2.69,1.91-6.28,1,2.36.9,2.24-1,3.24,1L50.28,20l2.35,1.91,1.68.33,7.39-2.68,5.16.22,6.72-5.49L88.14,8Z" />
      </symbol>
    )
  }
}

export default OH
