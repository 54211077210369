import React from 'react'
import CreateMarkup from './CreateMarkup'
import 'iframe-resizer'

const ApMnHouseDfl = () => {
  const iframe = `<iframe
  id="iframe_1e49bf9f1ada68836da968e4f3f80366"
  style="overflow: hidden; min-width: 100%; border: none;"
  src="https://elections.ap.org/widgets/content/1e49bf9f1ada68836da968e4f3f80366"
  width="100%"
  height="100%"
  frameborder="1"
  allowfullscreen="allowfullscreen"
  onload="iFrameResize({}, '#iframe_1e49bf9f1ada68836da968e4f3f80366');
  "/>`

  return (
    <div className="embeddedResults">
      <h1>MN House Results - DFL</h1>
      <div dangerouslySetInnerHTML={CreateMarkup(iframe)} />
    </div>
  )
}

export default ApMnHouseDfl
