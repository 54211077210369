import React, { useContext } from 'react'
import RaceResults from './RaceResults'
import { API_ENDPOINT } from '../../config'
import ElectionContext from '../../context/ElectionContext'
import { useParams } from 'react-router-dom'

const GenericRace = () => {
  const params = useParams()
  const context = useContext(ElectionContext)

  return (
    <RaceResults
      currentYear={context.date}
      interval={30000}
      url={`${API_ENDPOINT}/${context.date}/races/${params.id}`}
    />
  )
}

export default GenericRace
