import React, { Component } from 'react'

class AR extends Component {
  render() {
    return (
      <symbol id="statesvg-AR" width="100" height="100" viewBox="0 0 100 100">
        <path d="M2.24,8l42.34.56H88.39l1.69,2.83v2l-4.4,4.18-1.58,3L97,20.08l.79,2.48-1.36-.33-.79.9,1.81,1.13-4.74,2.37.79,1.92-.57,1.24L93.24,31l-1.8.91-.12,1.46-1.58,2.15.79,4.85-.68.68H88.73l-1,2L85.9,44.24l.68,2.15-3.5,2.14-.23,2.15H81.27l-.9,2.26v5.3l-1.69,1-1.24-.11V61.4H76.08l-.68,2-2.14,1L75,65.92l-.22,1.47-4.07,1.35L72,70l-.91,1.36.68,1.47L68.29,76l1.47,1.24-.23,1.13-1.47.45,2.15,2.26v2.37l-1,1.13,1.36,1.24.34,2.82-1.36.79V92H11.39V78.79l-2.71-1-4.07.57.12-.57h-1L2.58,76.08l2-41.55Z" />
      </symbol>
    )
  }
}

export default AR
