import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import NationalResults from './NationalResults'
import { API_ENDPOINT } from '../../config'
import ElectionContext from '../../context/ElectionContext'

const National = () => {
  const context = useContext(ElectionContext)
  return (
    <div className="National" data-testid="national">
      <Helmet>
        <title>MPR News | Live Results for National Elections</title>
        <meta
          name="twitter:title"
          content="LIVE Election Results from MPR News | National Elections"
        />
        <meta
          property="og:title"
          content="LIVE Election Results from MPR News | National Elections"
        />
      </Helmet>
      <div className="section_header">
        <h2 className="hdg hdg-2">National Results</h2>
      </div>
      <NationalResults
        interval={30000}
        url={`${API_ENDPOINT}/${context.date}/national_races`}
      />
    </div>
  )
}

export default National
