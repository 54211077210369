import React from 'react'
import PropTypes from 'prop-types'
import Home from '../../components/Home'
import MnSenate from '../../components/Legislature/MnSenate'
import MnHouse from '../../components/Legislature/MnHouse'
import UsHouse from '../../components/Legislature/UsHouse'
import Governor from '../Governor'
import Judicial from '../Judicial'
import Statewide from '../Statewide'
import President from '../President'
import CountyIndex from '../CountyIndex'
import National from '../National'
import UsSenate from '../UsSenate'
import PresidentialPrimary from '../PresidentialPrimary'
import PresidentialPrimaryByCounty from '../PresidentialPrimaryByCounty'
import Ap from '../Ap'
import ApMnHouseDfl from '../Ap/ApMnHouseDfl'
import ApMnHouseGop from '../Ap/ApMnHouseGop'
import ApMnSenate from '../Ap/ApMnSenate'
import ApUsSenate from '../Ap/ApUsSenate'
import ApUsHouse from '../Ap/ApUsHouse'
import GenericRace from '../GenericRace'

const ComponentChooser = ({ href, title }) => {
  switch (href) {
    case '/':
      return <Home />
    case 'mn/senate':
      return <MnSenate />
    case 'mn/senate/frame':
      return <MnSenate />
    case 'mn/house':
      return <MnHouse />
    case 'us/house':
      return <UsHouse />
    case 'governor':
      return <Governor />
    case 'statewide':
      return <Statewide />
    case 'judicial':
      return <Judicial />
    case 'president':
      return <President />
    case 'county':
      return <CountyIndex />
    case 'national':
      return <National />
    case 'us/senate':
      return <UsSenate />
    case 'president/primary':
      return <PresidentialPrimary />
    case 'president/county':
      return <PresidentialPrimaryByCounty />
    case '/president/frame':
      return <President />
    case '/races/frame':
      return <GenericRace />
    case 'ap':
      return <Ap />
    case 'ap/mnhouse/dfl':
      return <ApMnHouseDfl />
    case 'ap/mnhouse/gop':
      return <ApMnHouseGop />
    case 'ap/mnsenate':
      return <ApMnSenate />
    case 'ap/ussenate':
      return <ApUsSenate />
    case 'ap/ushouse':
      return <ApUsHouse />

    default:
      return <DoesNotExist title={title} />
  }
}

ComponentChooser.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
}

const DoesNotExist = ({ title }) => {
  return (
    <div>
      <h1>{title ? title : 'Does not exist'}</h1>
      <h2>No results to show</h2>
    </div>
  )
}

DoesNotExist.propTypes = {
  title: PropTypes.string,
}

export default ComponentChooser
