import React, { Component } from 'react'

class TN extends Component {
  render() {
    return (
      <symbol id="statesvg-TN" width="100" height="100" viewBox="0 0 100 100">
        <path d="M76.62,39.23l22.53-.82-.49,3.77-2,.41-1.88,2.86L93.25,45l-4,2.87-.66-1.39-2,1.88L85.3,48l-.73,2.38-2.13.41-3.53,2.78-4.58,1-1.39,1.14L72.2,58l-2.38.41-.41,3.35L42,62,.85,60.69l2.95-1.8-.49-3.2L5.77,53.4l-.33-2.62,2.62-1.31-.33-1.15,1,.41L8.31,46.6,9.45,45l-.9-1.47,1.56-.08-.74-1.15,1.07-.57-.17-1.56L11,41l.66-.82,15.64.49L27.23,38l2.54.74Z" />
      </symbol>
    )
  }
}

export default TN
