import React, { Component } from 'react'

class IA extends Component {
  render() {
    return (
      <symbol id="statesvg-IA" width="100" height="100" viewBox="0 0 100 100">
        <path d="M84.25,42.91v.8l2.47,1.58.53,2,3.08,2.29.26,1.14-.44,3.52-1.49,1.32-.36,2.56-1,1.14-3.44,1.59-4,.61L79,64.84l1.85,1.67.44,2.38-1.85,2.29-.88,3L75.36,76l.44,3-1.5-.09-3.17-3.52-29.41.88-22.63-.71v-1L18,73.29l.53-2.73-.7-2.9.44-1.85-.88-.62.79-1.76-1.32-.35.79-2.2-1.06-.36v-1l-1.32-.27.44-4.58-.88-1.49v-1.5l-2.29-3.35L12,43.26l-1-.43-.53-2.47L9.4,38.86l2.38-6.34L11.34,31l-1.06-.44.62-2.29-.62-2.11,33.82.44L76,26.27l.88,2.47,1.4.7-1.14,2.64,1.41,6.34.88,1.06,4,.88Z" />
      </symbol>
    )
  }
}

export default IA
