import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { API_ENDPOINT } from '../../config'
import { PRIMARY_DEM_CANDIDATES_2020 } from '../../config/elections/electionConfig2020Primary'
import ElectionContext from '../../context/ElectionContext'
import PresidentialPrimaryCountyMap from './PresidentialPrimaryCountyMap'

const PresidentialPrimaryByCounty = () => {
  const context = useContext(ElectionContext)
  return (
    <div className="PresidentialPrimary PresidentialPrimary-county">
      <Helmet>
        <title>MPR News | Live Results for Presidential Primary</title>
        <meta
          name="twitter:title"
          content="LIVE Election Results from MPR News | Presidential Primary"
        />
        <meta
          property="og:title"
          content="LIVE Election Results from MPR News | Presidential Primary"
        />
      </Helmet>
      <div className="section_header">
        <h2 className="hdg hdg-2 hdg-em">
          Minnesota County Presidential Primary Results
        </h2>
        <h3 className="hdg hdg-3 hdg-understated">
          Click on a county to see race details
        </h3>
      </div>
      <PresidentialPrimaryCountyMap
        interval={30000}
        candidates={PRIMARY_DEM_CANDIDATES_2020}
        mapName="dem"
        url={`${API_ENDPOINT}/${context.year}/presidential_primary_races?party=Dem&state=Minnesota&include_county_map=true`}
      />
    </div>
  )
}

export default PresidentialPrimaryByCounty
