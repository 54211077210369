import React, { Component } from 'react'

class MN extends Component {
  render() {
    return (
      <symbol id="statesvg-MN" width="100" height="100" viewBox="0 0 100 100">
        <polygon points="62.46 55.59 62.46 45.87 63.19 45.87 64.65 43.44 71.34 38.09 78.88 29.57 92.74 22.77 90.18 22.4 88.72 22.89 86.29 20.94 79.48 21.91 77.9 19.36 73.65 22.4 70 22.4 68.78 21.79 68.3 20.33 65.5 19.48 64.53 17.66 62.22 18.02 61.98 19.36 61.25 19.73 59.79 16.2 57.48 15.96 58.09 14.62 54.68 13.4 51.4 13.04 49.21 13.65 48.6 14.62 45.68 14.74 44.35 12.92 36.2 11.21 35.58 10.66 34.72 2.46 33.74 1.49 32.28 1.49 30.83 1 30.83 6.35 28.5 6.35 7.26 5.62 8.48 11.7 7.87 13.65 7.87 20.21 10.54 29.21 10.3 39.42 10.91 40.64 10.54 45.38 12.73 53.65 12.25 58.88 8.84 62.89 10.54 65.56 12.25 66.42 13.22 67.87 12 98.27 43.62 99 75.59 98.88 74.86 92.19 73.65 90.25 70.37 89.27 65.02 83.31 62.58 82.71 61.49 81 59.18 80.4 56.38 77.72 57.11 75.05 56.51 72.74 57.11 71.64 57.11 69.33 58.09 66.78 57.11 64.47 55.53 64.35 55.17 62.28 57.72 58.76 62.46 55.59" />
      </symbol>
    )
  }
}

export default MN
