import React, { useContext } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes'
import MprHeader from './components/MprHeader'
import Header from './components/Header'
import MprFooter from './components/MprFooter'
import Sprite from './components/Svg/Sprite'
import Svg from './components/Svg'
import ElectionContext from './context/ElectionContext.js'
import ScrollToTop from './components/ScrollToTop/'
import { useEffect } from 'react'

function App(props) {
  const context = useContext(ElectionContext)
  const framed = window.location.href.endsWith('frame')
  const { slug } = context
  useEffect(() => {
    //do a check for the meta content
    //this will stop the Underwriting tag to fire three times
    if (slug !== undefined) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'getAdSlot',
        contentTopic: 'homepage',
      })
    }
  }, [slug])

  function liveResults() {
    if (
      window.location.pathname.startsWith(`/${context.date}/county`) ||
      window.location.pathname.startsWith(`/${context.date}/interactive`)
    ) {
      return undefined
    }

    return (
      <div className="live mobileCentered">
        {/* We don't yet have a way to determine live vs. final data. Switch these commented sections before/after an election until we figure it out. */}
        {/* <span className="live_text">Final Results</span> */}
        <span className="live_text">Updating Live</span>
        <span className="live_icon">
          <Svg icon="live" />
        </span>
      </div>
    )
  }

  const lastSegment = window.location.pathname.split('/').pop()

  return (
    <Router>
      <ScrollToTop />
      <div className="app">
        {!framed && <MprHeader />}
        <div className="siteContainer">
          {!framed && <Header />}
          {framed ? (
            <main className="result_iframe">
              <Routes />
            </main>
          ) : (
            <main className="main">
              <div className="grid">
                <div className="grid_col grid_col-main">
                  <div className="grid_col-main_content grid_col-narrow">
                    <div className="main_header">
                      <div className="">
                        <div id="mpr-ad-1" className="ad_top" />
                        <h1 className="hdg hdg-1 mobileCentered">
                          LIVE Election Results
                        </h1>
                        {liveResults()}
                      </div>
                    </div>
                    <Routes />
                  </div>
                </div>
              </div>
            </main>
          )}
        </div>
        {lastSegment !== 'frame' && (
          <div className="ad">
            <div id="mpr-ad-2" className="ad_slot" />
          </div>
        )}
        {!framed && <MprFooter />}
        <Sprite />
      </div>
    </Router>
  )
}

export default App
