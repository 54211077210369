import React, { Component } from 'react'

class IconBlankCalendar extends Component {
  render() {
    return (
      <symbol
        id="icon-blank-calendar"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <path
          d="M16.42,77.78H82.24V29.92H16.42ZM34.37,20.94V7.48A1.48,1.48,0,0,0,32.88,6h-3a1.48,1.48,0,0,0-1.49,1.5V20.94a1.48,1.48,0,0,0,1.49,1.5h3A1.48,1.48,0,0,0,34.37,20.94Zm35.9,0V7.48A1.48,1.48,0,0,0,68.78,6h-3a1.48,1.48,0,0,0-1.49,1.5V20.94a1.48,1.48,0,0,0,1.49,1.5h3A1.48,1.48,0,0,0,70.27,20.94Zm18-3V77.78a6,6,0,0,1-6,6H16.42a6,6,0,0,1-6-6V18a6,6,0,0,1,6-6h6V7.48A7.51,7.51,0,0,1,29.88,0h3a7.5,7.5,0,0,1,7.47,7.48V12h18V7.48A7.5,7.5,0,0,1,65.78,0h3a7.51,7.51,0,0,1,7.48,7.48V12h6A6,6,0,0,1,88.22,18Z"
          transform="translate(-10.44)"
        />
      </symbol>
    )
  }
}

export default IconBlankCalendar
