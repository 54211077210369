import React, { useContext } from 'react'
import StatewideResults from './StatewideResults'
import { API_ENDPOINT } from '../../config'
import ElectionContext from '../../context/ElectionContext'

const Statewide = () => {
  const context = useContext(ElectionContext)
  return (
    <div className="Legislature">
      <StatewideResults
        interval={30000}
        url={`${API_ENDPOINT}/${context.date}/statewide_races`}
      />
    </div>
  )
}

export default Statewide
