const US_SENATE_COUNT = 100
const US_HOUSE_COUNT = 435
const MN_SENATE_COUNT = 67
const MN_HOUSE_COUNT = 134
const US_GOVERNOR_COUNT = 50

const calculateColumnSize = (dataLength) => {
  switch (dataLength) {
    case US_SENATE_COUNT:
      return {
        colSize: 2,
        midPointOffset: 0,
        isOdd: false, // Set this flag on an entry if the last column of squares ends up cut off and the line ends up off center
      }
    case US_HOUSE_COUNT:
      return {
        colSize: 8,
        midPointOffset: 2,
        isOdd: true,
      }
    case MN_SENATE_COUNT:
      return {
        colSize: 3,
        midPointOffset: 1,
        isOdd: true,
      }
    case MN_HOUSE_COUNT:
      return {
        colSize: 5,
        midPointOffset: 2,
        isOdd: false,
      }
    case US_GOVERNOR_COUNT:
      return {
        colSize: 1,
        midPointOffset: 0,
        isOdd: false,
      }
    default:
      return {
        colSize: 5,
        midPointOffset: 0,
        isOdd: false,
      }
  }
}

export default calculateColumnSize
