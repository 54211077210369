import React from 'react'
import PropTypes from 'prop-types'
import Svg from '../Svg'

const FooterPodcasts = (props) => {
  return (
    <div className="footer_podcasts">
      <div className="footer_row">
        <h3 className="hdg hdg-5">
          <a href="https://www.mprnews.org/podcasts">{props.title}</a>
        </h3>
      </div>
      <div className="footer_row">
        <div className="footer_podcastText">
          <p>{props.text}</p>
        </div>
      </div>
      <div className="footer_footer">
        <a
          href="https://www.mprnews.org/podcasts"
          className="link link-plain text-bold"
        >
          <div className="footer_link footer_link-icon">
            {props.linkLabel}
            <Svg icon="chevronRight" origHeight={22} origWidth={22} />
          </div>
        </a>
      </div>
    </div>
  )
}

FooterPodcasts.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string,
  linkLabel: PropTypes.string,
}

export default FooterPodcasts
