import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import * as serviceWorker from './serviceWorker'
import ElectionContext from './context/ElectionContext'
import elections from './config/elections'
import { DATE_WHITELIST } from './config'
import './sass/index.scss'

// Default: 2018-11-06
// Future Default: 2020-??-??
// Otherwise: parse the second variable of wherever the person first navigated

const dateString = window.location.pathname.split('/')[1]
let dateRegex = /^(19|20)\d\d([- /.])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/
let isDate = dateRegex.test(dateString)
let isGoodDate = DATE_WHITELIST.includes(dateString)
let election = elections[0] // by default, pick the most recent election site configuration

if (isDate && isGoodDate) {
  election = elections.find((s) => s.date === dateString)
} else if (isDate || dateString.length === 0) {
  window.location.pathname = `/${election.date}`
}

const appWithContext = (
  <ElectionContext.Provider value={election}>
    <App />
  </ElectionContext.Provider>
)

ReactDOM.render(appWithContext, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
