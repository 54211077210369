import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import BoPChart from '../BoPChart'
import { isInternalLink } from '../../util'
import useData from '../../hooks/useData'
import PropTypes from 'prop-types'
import ElectionContext from '../../context/ElectionContext'

const NationalResults = ({ url, interval }) => {
  const data = useData(url, interval)
  const context = useContext(ElectionContext)

  const buildBopNodes = (label, prefix, idx) => {
    let bopList = []
    for (let key in data) {
      if (key.startsWith(prefix)) {
        let bop = data[key]
        bop.label = label
        bopList.push(bop)
      }
    }

    return bopList.map((bop) => {
      return <BoPChart {...bop} key={idx} />
    })
  }

  return (
    <div data-testid="national-data">
      {context.national.map((item, idx) => {
        let currentHref = isInternalLink(item.href)
          ? `${context.date}/${item.href}`
          : item.href

        const node = buildBopNodes(item.title, item.bop, idx)

        return (
          <li key={`${item.href}${idx}`}>
            <section className="section">
              <div className="section_header">
                {item.title && (
                  <h2 className="hdg hdg-2 hdg-em hdg-inline">{item.title}</h2>
                )}

                {item.buttonText && (
                  <Link to={`${currentHref}`} className="button">
                    {item.buttonText}
                  </Link>
                )}
              </div>
              {item.headerText && (
                <div className="section_subheader">{item.headerText}</div>
              )}
              <div className="blocks">{node}</div>
            </section>
          </li>
        )
      })}
    </div>
  )
}

NationalResults.propTypes = {
  url: PropTypes.string.isRequired,
  interval: PropTypes.number,
}

export default NationalResults
