import logo from '../../assets/svg/logo-election2022.svg'
import electionConfig2020Presidential from './electionConfig2020Presidential'

const nav = [
  {
    title: 'Governor',
    href: 'governor',
    icon: 'mn-seal',
  },
  {
    title: 'National Results',
    href: 'national',
    icon: 'us',
  },
  {
    title: 'U.S. House',
    href: 'us/house',
    icon: 'mn-us-house-dist',
  },
  {
    title: 'Statewide',
    href: 'statewide',
    icon: 'mn',
  },
  {
    title: 'Minnesota Senate',
    href: 'mn/senate',
    icon: 'mn-senate',
  },
  {
    title: 'Minnesota House',
    href: 'mn/house',
    icon: 'mn-house',
  },
  {
    title: 'Local Races by County',
    href: 'county',
    icon: 'mn-counties',
  },
  {
    title: 'Live Coverage',
    href: 'https://www.mprnews.org/story/2022/11/01/election-2022-minnesota',
    icon: 'live',
  },
]

const overview = [
  {
    title: 'StateWide Races',
    href: 'governor',
    buttonText: 'More Results',
    label: 'governor',
    type: 'Race',
  },
  {
    label: 'attorneyGeneral',
    type: 'Race',
  },
  {
    label: 'secretaryOfState',
    type: 'Race',
  },
  {
    label: 'auditor',
    type: 'Race',
  },
  {
    title: 'U.S. Senate and House',
    href: 'national',
    buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopUs',
    label: 'U.S. Senate',
  },
  {
    title: 'U.S. House in Minnesota',
    label: 'usHouse',
    href: 'us/house',
    buttonText: 'Map & Details',
    type: 'Race',
  },
  {
    title: 'Minnesota Legislature',
    type: 'BalanceOfPower',
    area: 'MN',
    bop: 'bopMn',
    headerText: 'Results for the state house and senate.',
  },
  {
    title: 'Statewide Races',
    href: 'statewide',
    buttonText: 'See All Races',
    bodyText:
      'Results for the attorney general, secretary of state and auditor.',
  },
  {
    title: 'Local Races',
    href: 'county',
    buttonText: 'View Counties',
    bodyText:
      'Results in mayoral races, school boards, ballot measures and more.',
  },
]

const national = [
  {
    title: 'U.S. Senate',
    // href: 'us/senate',
    // buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopUsSenate',
    label: '',
    headerText:
      'Fourteen Democrats and twenty Republicans are up for election along with two special elections.',
  },
  {
    title: 'U.S. House',
    href: 'us/house',
    buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopUsHouse',
    label: '',
    headerText: 'All 435 seats in the House are up for election.',
  },
  {
    title: 'U.S. Governors',
    // href: 'interactive/2022-governor',
    // buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopGovernor',
    label: '',
    headerText: 'Thirty-six governors are up for election.',
  },
]

const electionConfig2022 = {
  ap: false,
  slug: 'electionConfig2022',
  date: '2022-11-08',
  nav: nav,
  year: '2022',
  overview: overview,
  national: national,
  logo: logo,
  previousElections: {
    governor: '2018-11-06',
    legislature: '2020-11-03',
  },
}

export default electionConfig2022
