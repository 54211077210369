import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import PresidentResults from './PresidentResults'
import { API_ENDPOINT } from '../../config'
import Svg from '../Svg'
import ElectionContext from '../../context/ElectionContext'

const President = () => {
  const context = useContext(ElectionContext)
  return (
    <div className="President">
      <Helmet>
        <title>MPR News | Live Results for Presidential Election</title>
        <meta
          name="twitter:title"
          content="LIVE Election Results from MPR News | Presidential Election"
        />
        <meta
          property="og:title"
          content="LIVE Election Results from MPR News | Presidential Election"
        />
      </Helmet>
      <div className="section_header">
        <h2 className="hdg hdg-2">
          <Svg icon="mn" />
          Presidential Results in Minnesota
        </h2>
      </div>
      <PresidentResults
        currentYear={context.date}
        interval={30000}
        url={`${API_ENDPOINT}/${context.date}/us_president_races`}
      />
    </div>
  )
}

export default President
