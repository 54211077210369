import React, { Component } from 'react'

class IconMn extends Component {
  render() {
    return (
      <symbol id="icon-mn" width="100" height="100" viewBox="0 0 100 100">
        <path
          fillRule="evenodd"
          d="M11.5 11.4L10.9 13.1 10.7 20 13.7 29.4 13.4 39.8 13.9 40.5 13.4 45.2 15.8 53.3 15.8 60 12.3 63.3 13.7 66.4 15.3 66.8 16.6 68.3 15.6 99.6C15.6 99.6 34.9 100 44.4 100 56.4 100 80.2 99.4 80.2 99.4L79.5 94.3C79.5 94.3 78.2 92.2 77.6 91.7 77 91.1 76 91 76 91 76 91 72.8 88.8 72 87.8 71.3 86.9 71.4 85.9 70.7 85.3 69.9 84.7 67.5 84.1 67.5 84.1L66.4 82.7 62.8 80.4 60.2 78.7C60.2 78.7 60.7 77.7 60.8 77.3 61 76.5 60.8 74.3 60.8 74.3L60.2 73 61 72.2 60.8 69 62 66.8 61 65.1 59.3 64.9 59.3 61.8C59.3 61.8 60.2 61.4 60.4 61 60.8 60.2 61.4 58.9 61.4 58.9 61.4 58.9 63.6 57.7 64.4 57.1 65.2 56.4 66.2 55.1 66.2 55.1L66.2 45.3 67.1 45.2 68.5 42.8 75.3 37.3 83.6 28.7 97.4 21.8 95.1 21.5 93.5 22.1 91.2 20 83.4 21.1 82 18.3 77.8 21.3 74.4 21.6 72.8 20.6 72.4 19.5 69.8 18.6 68.7 16.7 66.2 17 66.4 18.6 64.9 19.6 63.2 15.7 61 15.9 61 14 58 12.7 55.3 12.5 53.3 13.1 52.8 14 48.2 14.8 48.2 12.7C48.2 12.7 45.8 12.8 44.4 12.5 43.9 12.3 43 11.4 43 11.4L41.3 11.6 39.3 10.7 38.5 9.4 38.4 8.2 38 6.2 37 0.7C37 0.7 36.2 0 35.4 0 34.6 0 33.7 0.1 33.7 0.1L33.5 5.9 10 5.6 11.5 11.4Z"
        />
      </symbol>
    )
  }
}

export default IconMn
