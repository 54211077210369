import React from 'react'
import Loading from '../Loading'
import { CountyMap } from '../Map'
import PresidentNational from '../PresidentNational'
import PresidentElectionTable from '../PresidentElectionTable'
import RaceFull from '../RaceFull'
import useData from '../../hooks/useData'
import PropTypes from 'prop-types'
import Error from '../Error'
import { geoMercator } from 'd3-geo'
import maps from '../../config/maps'

const PresidentResults = ({ currentYear, url, interval }) => {
  const data = useData(url, interval)
  const map = maps.president
  const projection = geoMercator().scale(2000)

  if (data === undefined) {
    return <Loading />
  } else if (typeof data === 'string') {
    return <Error err={data} />
  }

  return (
    <>
      <PresidentElectionTable />
      <div className="grid_col-narrow">
        <div className="section">
          <div className="grid grid-fill">
            <div className="grid-col grid_col-half">
              <div className="section section-md">
                <CountyMap
                  width={450}
                  height={350}
                  center={[-94, 46.5]}
                  projection={projection}
                  topoJson={map.topoJson}
                  keyAttribute={map.keyAttribute}
                  properName={map.properName}
                  data={data[`${map.dataLabel}CountyMap`]}
                />
              </div>
              <PresidentNational
                candidates={data.president.topCandidates}
                key="president-natl"
              />
            </div>
            <div className="grid-col grid_col-half">
              <h3 className="hdg hdg-3">Minnesota Popular Vote</h3>
              <RaceFull
                {...data.president.stateResults[0]}
                level="president"
                key="president-minn-full"
              />
            </div>
          </div>
        </div>
        {currentYear === '2016-11-08' && (
          <div className="section">
            <div className="section_header">
              <h2 className="hdg hdg-2">National Electoral Map</h2>
            </div>
            <iframe
              title="UPDATE ME. (Also I make synchronous XHR's. SadPanda"
              src="https://interactives.ap.org/2016/general-election/?SITE=MNPELN&DEFAULTGEO=TRUE&HIDEBOP=TRUE"
              width="100%"
              height="800px"
              style={{ border: '0' }}
            />
          </div>
        )}
      </div>
    </>
  )
}

PresidentResults.propTypes = {
  url: PropTypes.string.isRequired,
  interval: PropTypes.number,
}

export default PresidentResults
