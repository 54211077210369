import React, { useContext } from 'react'
import Loading from '../Loading'
import Error from '../Error'
import { BasicMap } from '../Map'
import BoP from '../BoP'
import RaceFull from '../RaceFull'
import shortid from 'shortid'
import PropTypes from 'prop-types'
import { raceLevel, getWinningCandidate } from '../../util'
import { geoMercator } from 'd3-geo'
import maps from '../../config/maps'
import ElectionContext from '../../context/ElectionContext'

const LegislatureResults = ({ url, data, name, mapTag, previousElection }) => {
  const context = useContext(ElectionContext)
  const map = parseInt(context.year) >= 2022 ? maps[mapTag][1] : maps[mapTag][0]
  const projection = geoMercator().scale(2000)

  const bopNodes = () => {
    let bopList = []
    for (let key in data) {
      if (key.startsWith('bop')) {
        let res = data[key]
        res.label = `Control of the ${name}`
        bopList.push(res)
      }
    }

    return bopList.map((bop) => {
      return <BoP {...bop} key={shortid.generate()} />
    })
  }

  const raceNodes = () => {
    let raceList = []
    for (let race of data[map.dataLabel]) {
      raceList.push(race)
    }

    return raceList
      .sort(
        (a, b) => a.district.match(/[0-9]+/)[0] - b.district.match(/[0-9]+/)[0]
      )
      .map((race) => {
        let level = raceLevel(race)
        const prevRace =
          previousElection && previousElection[map.dataLabel]
            ? previousElection[map.dataLabel]
                .filter((item) => item.district === race.district)
                .pop()
            : undefined

        return (
          <RaceFull
            {...race}
            level={level}
            key={race.district}
            previousWinner={getWinningCandidate(prevRace?.candidates)}
          />
        )
      })
  }

  if (data === undefined) {
    return <Loading />
  } else if (typeof data === 'string') {
    return <Error err={data.toString()} />
  }

  const uncontested = data?.mnHouse?.filter(
    (node) => node.candidates[0].uncontested === true
  )

  return (
    <div>
      <div className="section">
        <div className="section section-md">
          <BasicMap
            width={450}
            height={350}
            center={[-94, 46.5]}
            projection={projection}
            topoJson={map.topoJson}
            keyAttribute={map.keyAttribute}
            properName={map.properName}
            data={data[`${map.dataLabel}Map`]}
            previousElection={
              previousElection
                ? previousElection[`${map.dataLabel}Map`]
                : undefined
            }
            uncontestedData={uncontested}
            mapTag={mapTag}
          />
        </div>
        <div className="section section-md">{bopNodes()}</div>
        <div className="blocks blocks-1">{raceNodes()}</div>
      </div>
    </div>
  )
}

LegislatureResults.propTypes = {
  url: PropTypes.string.isRequired,
  data: PropTypes.object,
  name: PropTypes.string.isRequired,
  mapTag: PropTypes.string.isRequired,
  previousElection: PropTypes.object,
}

export default LegislatureResults
