import React, { Component } from 'react'

class OK extends Component {
  render() {
    return (
      <symbol id="statesvg-OK" width="100" height="100" viewBox="0 0 100 100">
        <path d="M95.5,34.14,98,49.72l.38,23.88L93.8,72.18l-1.33-1.32-2.83-1.42L88,70.86H86.43l-1.22-1.23-2,1.42-1.88-.28-3.78,1.88-.66.76-3-2-.09-.66-1.8.66L70,70l-1.7,1.23-.75,2.07-.85-2.54L64.16,71.9,61.8,69.44l-2.36,1.8h-.95l-.37-1.8-1.51-.28-.19-1.7H53.59l-1.7,1-1.13-1.23-1.52.38L44,66.14l-.1-1.23-2-2.07-.56,1.32L38.39,64l-2.55-2.83H34.71l.38-26.62L1.67,33.29l.38-6.89,46.82,1.42,46.44-.66Z" />
      </symbol>
    )
  }
}

export default OK
