const DECIDED_OPACITY = 1.0
const UNDECIDED_OPACITY = 0.5

const generateDataPoints = (props) => {
  let leftCount = props.left ? props.left : 0
  let leftHoldoverCount = props.left_holdovers ? props.left_holdovers : 0
  let leftLeanCount = props.left_leaning ? props.left_leaning : 0
  let rightCount = props.right ? props.right : 0
  let rightHoldoverCount = props.right_holdovers ? props.right_holdovers : 0
  let rightLeanCount = props.right_leaning ? props.right_leaning : 0
  let otherCount = props.other ? props.other : 0
  let otherHoldoverCount = props.other_holdovers ? props.other_holdovers : 0
  let otherLeanCount = props.other_leaning ? props.other_leaning : 0

  leftCount = leftCount - leftHoldoverCount
  otherCount = otherCount - otherHoldoverCount
  rightCount = rightCount - rightHoldoverCount

  let undecidedCount =
    props.total -
    leftCount -
    leftHoldoverCount -
    leftLeanCount -
    rightCount -
    rightHoldoverCount -
    rightLeanCount -
    otherCount -
    otherHoldoverCount -
    otherLeanCount

  let leftHoldover = new Array(leftHoldoverCount).fill({
    party: 'dem',
    opacity: DECIDED_OPACITY,
    holdover: true,
  })
  let left = new Array(leftCount).fill({
    party: 'dem',
    opacity: DECIDED_OPACITY,
    holdover: false,
  })
  let leftLeaning = new Array(leftLeanCount).fill({
    party: 'dem',
    opacity: UNDECIDED_OPACITY,
    holdover: false,
  })
  let rightHoldover = new Array(rightHoldoverCount).fill({
    party: 'gop',
    opacity: DECIDED_OPACITY,
    holdover: true,
  })
  let right = new Array(rightCount).fill({
    party: 'gop',
    opacity: DECIDED_OPACITY,
    holdover: false,
  })
  let rightLeaning = new Array(rightLeanCount).fill({
    party: 'gop',
    opacity: UNDECIDED_OPACITY,
    holdover: false,
  })
  let otherHoldover = new Array(otherHoldoverCount).fill({
    party: 'ind',
    opacity: DECIDED_OPACITY,
    holdover: true,
  })
  let other = new Array(otherCount).fill({
    party: 'ind',
    opacity: DECIDED_OPACITY,
    holdover: false,
  })
  let otherLeaning = new Array(otherLeanCount).fill({
    party: 'ind',
    opacity: UNDECIDED_OPACITY,
    holdover: false,
  })
  let undecided = new Array(undecidedCount > -1 ? undecidedCount : 0).fill({
    party: 'wi',
    opacity: UNDECIDED_OPACITY,
    holdover: false,
  })

  let data = [].concat(
    leftHoldover,
    left,
    leftLeaning,
    otherHoldover,
    other,
    otherLeaning,
    undecided,
    rightLeaning,
    right,
    rightHoldover
  )

  return data
}

export default generateDataPoints
