import React, { Component } from 'react'

class MS extends Component {
  render() {
    return (
      <symbol id="statesvg-MS" width="100" height="100" viewBox="0 0 100 100">
        <path d="M71.64,7.69h2.55l1.33,1.55L69.75,65.62l1.33,28.53L70,95l-2-1.56-2.45.78-2.77-1.78-.89.56,1.78.22-6,1.78-1.44-1.22-.55.66.66.67-1.78,1.11-.33,1H52.33l-1.67-4.89-2.11-2.11L47.77,88l1.67-5.66-30.52-.77,1.33-.78-1.22-4,2-.55L21.69,74,21,72.39l1.55-1.33,1.55-4.77L28.46,63l-.33-2.22,1.22-.22,2.11-3.22-3.33-2.77.33-1.89L27.35,51.2l1.56-1.12-1.34-1.33L29,45.87l-1.45-1V42.54l1.34-1-.45-2.11-1.11-.78,1.11-2.77L26.8,34l.89-.22L28,32.66,26.8,32l2.88-2.44-.22-1.33.33-.89L29,26.11l3.44-1.22-1-1.78,1.11-1,1-1.45h.55l.22-1.55h1.34l1.44-.89.44-5.55,1.22-.11.56-1.78L42.11,9l-.55-1.67ZM60.54,96.48l-.45.78-1-.56,1.44-.44Zm1.55.67H62l.78-.34Zm6.77-.34-3.44-.33v-.22h1Zm1.78,0-.67.34-.22-.45Z" />
      </symbol>
    )
  }
}

export default MS
