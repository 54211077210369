import electionConfig2022 from './electionConfig2022'
import electionConfig2020Presidential from './electionConfig2020Presidential'
import electionConfig2020AugustPrimary from './electionConfig2020AugustPrimary'
import electionConfig2020Primary from './electionConfig2020Primary'
import electionConfig2018 from './electionConfig2018'
import electionConfig2016 from './electionConfig2016'

export default [
  electionConfig2022,
  electionConfig2020Presidential,
  electionConfig2020AugustPrimary,
  electionConfig2020Primary,
  electionConfig2018,
  electionConfig2016,
]
