import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import ElectionContext from '../../context/ElectionContext'
import JudicialResults from './JudicialResults'
import { API_ENDPOINT } from '../../config'

const Judicial = () => {
  const context = useContext(ElectionContext)
  return (
    <div className="County Judicial">
      <Helmet>
        <title>MPR News | Live Results for Judicial Election</title>
        <meta
          name="twitter:title"
          content="LIVE Election Results from MPR News | Judicial Election"
        />
        <meta
          property="og:title"
          content="LIVE Election Results from MPR News | Judicial Election"
        />
      </Helmet>
      <div className="section_header">
        <h2 className="hdg hdg-2">Judicial Results</h2>
      </div>
      <JudicialResults
        interval={30000}
        url={`${API_ENDPOINT}/${context.date}/judicial_races`}
      />
    </div>
  )
}

export default Judicial
