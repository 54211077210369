import React from 'react'
import logo from '../../assets/images/mprnews.svg'

function MprHeader() {
  return (
    <>
      <nav id="mprnews-nav" role="navigation" aria-label="Site menu">
        <div id="nav-top">
          {/* <a href="#" id="nav-top-opener" /> */}
          <div id="nav-list">
            <ul id="nav-primary">
              <li id="nav-mpr-give">
                <div>
                  <a
                    title="Minnesota Public Radio"
                    href="http://minnesota.publicradio.org/"
                    className="mpr"
                  >
                    Minnesota Public Radio
                  </a>{' '}
                  <a
                    href="https://contribute.publicradio.org/contribute.php?refId=default&amp;WT.mc_id=news_web_nav_button&amp;WT.mc_ev=click&amp;utm_campaign=membership_contribution&amp;utm_medium=web_nav_button&amp;utm_source=news&amp;utm_content=&amp;utm_term"
                    className="contribution-link"
                  >
                    Give Now
                  </a>{' '}
                </div>
              </li>
              <li id="nav-services">
                <ul className="nav-sub">
                  <li className="mpr">
                    {' '}
                    <a
                      title="Minnesota Public Radio"
                      href="http://minnesota.publicradio.org/"
                    >
                      Minnesota Public Radio
                    </a>{' '}
                  </li>
                  <li className="has-sub first">
                    {' '}
                    <span className="header">MPR Services</span>
                    <ul>
                      <li className="news">
                        {' '}
                        <a
                          title="Minnesota Public Radio News"
                          href="http://www.mprnews.org"
                          data-pjax="true"
                        >
                          MPR News
                        </a>{' '}
                      </li>
                      <li className="current">
                        {' '}
                        <a
                          title="The Current from Minnesota Public Radio"
                          href="http://www.thecurrent.org/"
                        >
                          The Current
                        </a>{' '}
                      </li>
                      <li className="classical">
                        {' '}
                        <a
                          title="Classical Minnesota Public Radio"
                          href="http://minnesota.publicradio.org/radio/services/cms/"
                        >
                          Classical MPR
                        </a>{' '}
                      </li>
                      <li id="more-streams">
                        {' '}
                        <a href="http://minnesota.publicradio.org/radio/">
                          More Streams
                        </a>{' '}
                      </li>
                      <li className="nav-secondary">
                        {' '}
                        <a href="http://www.thecurrent.org/heartland">
                          Radio Heartland
                        </a>{' '}
                      </li>
                      <li className="nav-secondary">
                        {' '}
                        <a href="http://www.thecurrent.org/local">
                          Local Current{' '}
                        </a>{' '}
                      </li>
                      <li className="nav-secondary">
                        {' '}
                        <a href="http://www.thecurrent.org/rock-the-cradle">
                          Rock the Cradle
                        </a>{' '}
                      </li>
                      <li className="nav-secondary">
                        {' '}
                        <a href="http://minnesota.publicradio.org/radio/services/cms/choral/">
                          Choral Classical
                        </a>{' '}
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li
                id="nav-news-sections"
                style={{ display: 'none' }}
                className="nav-dropdown"
              >
                {' '}
                <button
                  className="nav-dropdown-header"
                  id="news-sections-header"
                >
                  Sections
                </button>
                <div className="nav-sub-wrapper">
                  <ul className="nav-sub">
                    <li className="has-sub first">
                      <span className="header">MPR News coverage</span>
                      <ul>
                        <li>
                          <a
                            href="http://www.mprnews.org/arts"
                            data-pjax="true"
                          >
                            Arts
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.mprnews.org/thread"
                            data-pjax="true"
                          >
                            Books
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.mprnews.org/business"
                            data-pjax="true"
                          >
                            Business
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.mprnews.org/education"
                            data-pjax="true"
                          >
                            Education
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.mprnews.org/environment"
                            data-pjax="true"
                          >
                            Environment
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.mprnews.org/health"
                            data-pjax="true"
                          >
                            Health
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.mprnews.org/lifestyle"
                            data-pjax="true"
                          >
                            Lifestyle
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.mprnews.org/politics"
                            data-pjax="true"
                          >
                            Politics
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.mprnews.org/state"
                            data-pjax="true"
                          >
                            State
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.mprnews.org/twin-cities"
                            data-pjax="true"
                          >
                            Twin Cities
                          </a>
                        </li>
                        <li>
                          <a href="http://blogs.mprnews.org/newscut/">
                            NewsCut
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.mprnews.org/weather"
                            data-pjax="true"
                          >
                            Weather
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="has-sub">
                      <span className="header">MPR News programs</span>
                      <ul>
                        <li>
                          <a
                            data-pjax="true"
                            href="http://www.mprnews.org/topic/morning-edition"
                          >
                            Morning Edition
                          </a>
                        </li>
                        <li>
                          <a
                            data-pjax="true"
                            href="http://www.mprnews.org/topic/all-things-considered"
                          >
                            All Things Considered
                          </a>
                        </li>
                        <li>
                          <a
                            data-pjax="true"
                            href="http://www.mprnews.org/topic/kerri-miller"
                          >
                            MPR News with Kerri Miller
                          </a>
                        </li>
                        <li>
                          <a
                            data-pjax="true"
                            href="http://www.mprnews.org/topic/tom-weber"
                          >
                            MPR News with Tom Weber
                          </a>
                        </li>
                        <li>
                          <a href="http://minnesota.publicradio.org/radio/programs/">
                            More programs
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="has-sub">
                      <span className="header">MPR News resources</span>
                      <ul>
                        <li>
                          <a
                            href="http://www.mprnews.org/schedule"
                            data-pjax="true"
                          >
                            Program schedule
                          </a>
                        </li>
                        <li>
                          <a href="http://minnesota.publicradio.org/radio/stations/">
                            Station directory
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.mprnews.org/podcasts"
                            data-pjax="true"
                          >
                            Podcasts
                          </a>
                        </li>
                        <li>
                          <a href="http://archive.mprnews.org/">Archive</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                id="nav-members"
                className="nav-dropdown"
                style={{ display: 'none' }}
              >
                {' '}
                <a
                  href="http://minnesota.publicradio.org/support/"
                  className="nav-dropdown-header"
                  id="members-sections-header"
                >
                  Members
                </a>
                <div className="nav-sub-wrapper">
                  <ul className="sub">
                    <li className="has-sub" id="nav-members-give-sub">
                      <a
                        href="http://minnesota.publicradio.org/support/more-ways-to-give/"
                        className="header"
                      >
                        More ways to give
                      </a>
                      <ul>
                        <li>
                          <a href="http://minnesota.publicradio.org/support/gift-membership/">
                            Give a gift membership
                          </a>
                        </li>
                        <li>
                          <a href="http://minnesota.publicradio.org/support/membership/employer_match.shtml">
                            Employer matching gifts
                          </a>
                        </li>
                        <li>
                          <a href="http://minnesota.publicradio.org/support/individual/leadership_circle.shtml">
                            Leadership Circle
                          </a>
                        </li>
                        <li>
                          <a href="http://minnesota.publicradio.org/support/planned-giving/">
                            Planned giving
                          </a>
                        </li>
                        <li>
                          <a href="http://minnesota.publicradio.org/support/vehicle/">
                            Donate a vehicle
                          </a>
                        </li>
                        <li>
                          <a href="http://minnesota.publicradio.org/support/individual/donate_stock.shtml">
                            Donate stock
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="http://minnesota.publicradio.org/support/member_benefits/">
                        Member benefits
                      </a>
                    </li>
                    <li>
                      <a href="http://minnesota.publicradio.org/support/membership/sustaining_members.shtml">
                        Sustaining membership
                      </a>
                    </li>
                    <li>
                      <a href="http://minnesota.publicradio.org/about/contact/">
                        Contact us
                      </a>
                    </li>
                    <li>
                      <a
                        className="giveNowHeaderLink"
                        href="https://contribute.publicradio.org/contribute.php?refId=default&amp;WT.mc_id=news_web_header_dropdown&amp;WT.mc_ev=click&amp;utm_campaign=membership_contribution&amp;utm_medium=web_header_dropdown&amp;utm_source=news&amp;utm_content=&amp;utm_term"
                      >
                        Become a member
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                id="nav-more"
                className="nav-dropdown"
                style={{ display: 'none' }}
              >
                {' '}
                <a
                  href="http://minnesota.publicradio.org/about/"
                  className="nav-dropdown-header"
                  id="more-sections-header"
                >
                  More
                </a>
                <div className="nav-sub-wrapper">
                  <ul className="nav-sub services">
                    <li
                      className="header"
                      id="more-sub-primary-services-header"
                    >
                      {' '}
                      <span>MPR Services</span>{' '}
                    </li>
                    <li id="more-sub-primary-services">
                      <ul>
                        <li className="news">
                          {' '}
                          <a
                            title="Minnesota Public Radio News"
                            href="http://www.mprnews.org/"
                            data-pjax="true"
                          >
                            MPR News
                          </a>{' '}
                        </li>
                        <li className="current">
                          {' '}
                          <a
                            title="The Current from Minnesota Public Radio"
                            href="http://www.thecurrent.org/"
                          >
                            The Current
                          </a>{' '}
                        </li>
                        <li className="classical">
                          {' '}
                          <a
                            title="Classical Minnesota Public Radio"
                            href="http://minnesota.publicradio.org/radio/services/cms/"
                          >
                            Classical MPR
                          </a>{' '}
                        </li>
                      </ul>
                    </li>
                    <li id="more-sub-secondary-services">
                      <ul>
                        <li className="nav-secondary">
                          {' '}
                          <a href="http://www.thecurrent.org/heartland">
                            Radio Heartland
                          </a>{' '}
                        </li>
                        <li className="nav-secondary">
                          {' '}
                          <a href="http://www.thecurrent.org/local">
                            Local Current{' '}
                          </a>{' '}
                        </li>
                        <li className="nav-secondary">
                          {' '}
                          <a href="http://www.thecurrent.org/rock-the-cradle">
                            Rock the Cradle
                          </a>{' '}
                        </li>
                        <li className="nav-secondary">
                          {' '}
                          <a href="http://minnesota.publicradio.org/radio/services/cms/choral/">
                            Choral Classical
                          </a>{' '}
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="nav-sub more">
                    <li className="mpr" id="more-sub-mpr-header">
                      {' '}
                      <a
                        title="Minnesota Public Radio"
                        href="http://minnesota.publicradio.org/"
                      >
                        Minnesota Public Radio
                      </a>{' '}
                    </li>
                    <li id="nav-about">
                      <a href="http://minnesota.publicradio.org/about/">
                        About MPR
                      </a>
                      <ul className="sub" id="nav-about-sub">
                        <li>
                          <a href="http://minnesota.publicradio.org/about/contact/">
                            Contact info
                          </a>
                        </li>
                        <li>
                          <a href="http://minnesota.publicradio.org/radio/stations/">
                            Stations
                          </a>
                        </li>
                        <li>
                          <a href="http://americanpublicmedia.iapplicants.com/">
                            Careers
                          </a>
                        </li>
                        <li>
                          <a href="http://minnesota.publicradio.org/about/people/">
                            Staff directory
                          </a>
                        </li>
                        <li>
                          <a href="http://minnesota.publicradio.org/about/mpr/">
                            Company information
                          </a>
                        </li>
                        <li>
                          <a href="http://minnesota.publicradio.org/about/press/">
                            Press room
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li id="sub-secondary-members">
                      <a href="http://minnesota.publicradio.org/support/">
                        Members
                      </a>
                    </li>
                    <li>
                      <a href="http://minnesota.publicradio.org/events/">
                        Events
                      </a>
                    </li>
                    <li>
                      <a href="http://minnesota.publicradio.org/support/shop/">
                        Shop
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div id="news-logo">
          <a
            href="http://www.mprnews.org/"
            data-pjax="true"
            aria-label="MPR News Home"
          >
            {' '}
            <img src={logo} alt="" />{' '}
          </a>
        </div>
        <div id="nav-top-contribution">
          {' '}
          <a
            href="https://contribute.publicradio.org/contribute.php?refId=default&amp;WT.mc_id=news_web_nav_button&amp;WT.mc_ev=click&amp;utm_campaign=membership_contribution&amp;utm_medium=web_nav_button&amp;utm_source=news&amp;utm_content=&amp;utm_term"
            className="contribution-link"
          >
            Give Now
          </a>{' '}
        </div>
      </nav>
      <div id="nav-faux-wrapper" />
    </>
  )
}

export default MprHeader
