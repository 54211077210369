import React from 'react'
import US from './US'
import AL from './AL'
import AR from './AR'
import AZ from './AZ'
import CA from './CA'
import CO from './CO'
import FL from './FL'
import IA from './IA'
import IL from './IL'
import NV from './NV'
import MA from './MA'
import ME from './ME'
import MN from './MN'
import NC from './NC'
import NH from './NH'
import OH from './OH'
import OK from './OK'
import SC from './SC'
import TN from './TN'
import TX from './TX'
import UT from './UT'
import VA from './VA'
import VT from './VT'
import ID from './ID'
import MI from './MI'
import MO from './MO'
import MS from './MS'
import ND from './ND'
import WA from './WA'
class Svg extends React.Component {
  render() {
    return (
      <svg style={{ display: 'none' }}>
        <AL />
        <AR />
        <AZ />
        <CA />
        <CO />
        <FL />
        <IA />
        <ID />
        <IL />
        <NV />
        <MA />
        <ME />
        <MN />
        <MI />
        <MO />
        <MS />
        <NC />
        <ND />
        <NH />
        <OH />
        <OK />
        <SC />
        <TN />
        <TX />
        <UT />
        <VA />
        <VT />
        <WA />
        <US />
      </svg>
    )
  }
}

export default Svg
