import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import ElectionContext from '../../context/ElectionContext'
import Nav from '../Nav'

const Header = () => {
  const context = useContext(ElectionContext)

  return (
    <header className="header">
      <Link to="/" className="header_logo">
        <img
          className="header_logo_img"
          src={context.logo}
          alt={`Election ${window.location.pathname
            .split('-')[0]
            .replace(/\//, '')}`}
        />
      </Link>
      <Nav />
    </header>
  )
}

export default Header
