import React from 'react'
import config from './footerConfig'
import FooterContact from './FooterContact'
import FooterLower from './FooterLower'
import FooterPodcasts from './FooterPodcasts'
import FooterSubscribe from './FooterSubscribe'
import FooterProgramming from './FooterProgramming'
import { useState, useEffect } from 'react'
function useMounted() {
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])
  return mounted
}

const MprFooter = () => {
  const mounted = useMounted()
  return (
    <>
      {mounted && (
        <footer className="footer">
          <img
            className="footer_stripes-top"
            src="/stripes-top.png"
            height="100"
            alt=""
          />
          <h2 className="invisible">About MPR News</h2>
          <div className="footer_upper">
            <FooterPodcasts
              title={config.podcastTitle}
              href={config.podcastLinkHref}
              text={config.podcastText}
              linkLabel={config.podcastLinkLabel}
            />
            <FooterContact links={config.contact} />
            <FooterSubscribe />
            <FooterProgramming
              listenText={config.listenText}
              listenHref={config.listenHref}
              links={config.programmingLinks}
              title={config.programmingTitle}
            />
          </div>
          <FooterLower nav={config.nav} />
          <img
            className="footer_stripes-bottom"
            src="/stripes-bottom.png"
            height="80"
            alt=""
          />
        </footer>
      )}
    </>
  )
}

export default MprFooter
