import React from 'react'
import 'iframe-resizer'
import CreateMarkup from './CreateMarkup'

const ApUsHouse = () => {
  const iframe = `<iframe
    id="iframe_ee303a2398614949bd95c07c53cde1aa" 
    style="overflow: hidden; min-width: 100%; border: none;"
    src="https://elections.ap.org/widgets/content/ee303a2398614949bd95c07c53cde1aa"
    width="100%" 
    height="100%" 
    frameborder="1" 
    allowfullscreen="allowfullscreen" 
    onload="iFrameResize({}, '#iframe_ee303a2398614949bd95c07c53cde1aa');">`

  return (
    <div className="embeddedResults">
      <h1>U.S. House Results</h1>
      <div dangerouslySetInnerHTML={CreateMarkup(iframe)} />
    </div>
  )
}

export default ApUsHouse
