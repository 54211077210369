import logo from '../../assets/svg/logo-election2018.svg'

const nav = [
  {
    title: 'Governor',
    href: 'governor',
    icon: 'mn-seal',
  },
  {
    title: 'National Results',
    href: 'national',
    icon: 'us',
  },
  {
    title: 'U.S. Senate',
    href: 'us/senate',
    icon: 'mn',
  },
  {
    title: 'U.S. House',
    href: 'us/house',
    icon: 'mn-us-house-dist',
  },
  {
    title: 'Statewide',
    href: 'statewide',
    icon: 'mn',
  },
  {
    title: 'Minnesota Senate',
    href: 'mn/senate',
    icon: 'mn-senate',
  },
  {
    title: 'Minnesota House',
    href: 'mn/house',
    icon: 'mn-house',
  },
  {
    title: 'Judicial',
    href: 'judicial',
    icon: 'gavel',
  },
  {
    title: 'Local Races by County',
    href: 'county',
    icon: 'mn-counties',
  },
  {
    title: 'Live Coverage',
    href: 'https://live.mprnews.org/Event/Election_2018',
    icon: 'live',
  },
]

const overview = [
  {
    title: 'StateWide Races',
    href: 'governor',
    buttonText: 'More Results',
    label: 'governor',
    type: 'Race',
  },
  {
    label: 'attorneyGeneral',
    type: 'Race',
  },

  {
    title: 'U.S. Senate and House',
    href: 'national',
    buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopUs',
    label: 'U.S. Senate',
    headerText:
      'In the Senate, 24 Democrats, nine Republicans and two Independents are up for election. All 435 seats in the House are up for election.',
  },
  {
    title: 'U.S. Senate',
    href: 'us/senate',
    buttonText: 'Map & Details',
    label: 'usSenate',
    type: 'Race',
  },
  {
    title: 'U.S. House in Minnesota',
    label: 'usHouse',
    href: 'us/house',
    buttonText: 'Map & Details',
    type: 'Race',
  },
  {
    title: 'Minnesota Legislature',
    type: 'BalanceOfPower',
    area: 'MN',
    bop: 'bopMn',
    headerText:
      'The Senate special election for District 13 will determine which party controls the Senate. All seats in the House are up for reelection.',
  },
  {
    title: 'Statewide Races',
    href: 'statewide',
    buttonText: 'See All Races',
    bodyText:
      'Results for the attorney general, secretary of state and auditor.',
  },
  {
    title: 'Judicial Races',
    href: 'judicial',
    buttonText: 'See All Races',
    bodyText:
      "Results in the state's District Courts, Court of Appeals and Supreme Court. Judges run in nonpartisan elections to serve six-year terms. Voters also elect the chief judge of the Supreme Court.",
  },
  {
    title: 'Local Races',
    href: 'county',
    buttonText: 'View Counties',
    bodyText:
      'Results in mayoral races, school boards, ballot measures and more.',
  },
]

const national = [
  {
    title: 'U.S. Senate',
    href: 'interactive/2018-senate',
    buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopUsSenate',
    label: '',
    headerText:
      'Twenty-four Democrats, nine Republicans and two Independents are up for election.',
  },
  {
    title: 'U.S. House',
    href: 'interactive/2018-house',
    buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopUsHouse',
    label: '',
    headerText: 'All 435 seats in the House are up for election.',
  },
  {
    title: 'U.S. Governors',
    href: 'interactive/2018-governor',
    buttonText: 'More Results',
    type: 'BalanceOfPower',
    area: 'U.S.',
    bop: 'bopGovernor',
    label: '',
    headerText: 'Thirty-six governors are up for election.',
  },
]

const electionConfig2018 = {
  ap: false,
  slug: 'electionConfig2018',
  date: '2018-11-06',
  nav: nav,
  year: '2018',
  overview: overview,
  national: national,
  logo: logo,
}

export default electionConfig2018
