import React, { Component } from 'react'

class IconMnSenate extends Component {
  render() {
    return (
      <symbol
        id="icon-mn-senate"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <g
          fillRule="evenodd"
          strokeWidth="0.2"
          stroke="#FFFFFF"
          transform="translate(6, 0)"
        >
          <polygon points="45.8 56 45.8 59.1 43.6 59.1 43.6 63.2 42.1 63.2 42.1 67.6 40.9 67.6 40.9 69.2 40.4 69.4 38.9 69.2 38.9 70.4 37.6 69.2 36.4 69.4 36.4 70.2 35.1 70.2 36.1 68.4 35.4 67.9 36.4 67.6 36.6 64.8 34.6 64.8 33.4 62.7 33.6 62.5 32.6 62 36.4 61.7 36.6 60.4 39.1 60.4 38.9 54.8 42.8 54.8 43.3 56.3 45.3 56" />
          <polygon points="47.6 85.9 47.6 88.7 48.8 88.7 48.8 91.5 50.3 91.5 50.3 93.1 47.3 93.1 47.3 94.3 43.6 94.3 43.3 91.5 41.9 91.5 42.1 87.4 43.3 87.1 43.3 85.9 47.1 85.9" />
          <polygon points="45.1 79.4 45.1 80.5 44.3 80.7 44.3 80.2 43.3 80.2 43.3 78.9" />
          <polygon points="44.1 78.9 45.8 77.9 46.8 78.1 46.8 79.2 44.1 79.2" />
          <polygon points="45.1 79.4 48.3 79.2 48.3 80.2 46.1 80.2 46.1 80.7 45.1 79.7" />
          <polygon points="45.6 77.9 45.1 78.7 44.1 78.9 43.8 78.4 44.3 77.4 45.3 77.9" />
          <polygon points="44.6 77.4 44.1 76.9 44.1 76.1 44.6 75.8 44.6 76.9 45.1 76.9 44.8 77.4" />
          <polygon points="44.8 76.1 45.1 76.9 44.6 76.9" />
          <polygon points="44.6 77.4 45.1 77.4 45.3 76.1 45.8 77.6 45.1 77.9" />
          <polygon points="44.8 74.6 45.1 75.8 44.3 76.1 44.3 74.8" />
          <polygon points="44.8 74.8 45.3 74.8 45.6 76.3 44.8 75.6 44.8 75.1" />
          <polygon points="45.3 73.8 45.8 74.3 45.6 75.3 45.3 74.8 44.6 74.6 44.6 73.5 45.1 73.8" />
          <polygon points="46.3 77.1 46.1 77.4 45.6 76.9 45.6 75.8 46.6 76.3 46.3 76.9" />
          <polygon points="47.8 79.2 46.8 79.2 46.8 78.1 45.6 77.9 46.6 76.9 47.3 76.9 47.6 78.1 47.6 77.6 47.8 77.9" />
          <polygon points="47.3 76.9 46.3 77.1 46.6 76.3 46.1 76.3 46.6 75.8 47.3 76.6" />
          <polygon points="46.8 75.3 47.1 76.1 46.1 76.1 45.6 74.8 46.8 75.1" />
          <polygon points="47.6 74.6 46.8 75.3 45.8 74.6 45.8 74 45.3 74 45.3 73.5 46.6 73.5 46.6 74 47.6 74.3" />
          <polygon points="51.1 80.2 51.1 81.7 50.3 81.7 50.3 83 48.8 83 48.8 85.9 47.6 85.9 47.6 84.3 44.8 84.3 44.8 81.5 44.3 81.5 44.3 80.7 45.1 80.2 45.8 80.2 45.8 80.7 46.3 80.2 49.8 80.2 50.3 79.7 50.8 80.2" />
          <polygon points="49.6 76.3 50.3 76.3 50.6 77.1 50.8 76.9 50.3 79.2 49.8 80.2 48.3 80.2 48.3 79.2 47.6 78.9 47.8 77.6 47.6 78.1 47.3 77.4 48.3 77.4 48.1 77.9 49.6 77.9 49.6 76.9" />
          <polygon points="47.8 77.4 47.3 76.9 47.1 75.6 47.8 75.6 47.8 77.1" />
          <polygon points="48.6 76.1 49.6 76.3 49.6 77.9 48.1 77.9 47.8 75.8" />
          <polygon points="48.6 74 48.6 76.1 46.8 75.3 47.6 74.6 48.1 74.6 48.1 74 48.6 74.3" />
          <polygon points="48.8 90.2 50.3 90.2 50.3 88.7 54.3 88.7 54.1 91.5 51.6 91.5 51.6 92.8 50.3 93.1 50.3 91.5 48.8 91.5" />
          <polygon points="62.5 89.7 62.5 91 61 91 61 91.5 59.8 91.5 59.8 93.1 58.3 93.1 58.3 90.5 55.6 90.2 55.6 88.7 50.3 88.7 50.3 90.2 48.8 90.2 48.8 88.7 47.6 88.7 47.6 85.9 48.8 85.9 48.8 83 50.3 83 50.3 81.7 51.1 81.7 51.1 80.2 52.3 81.5 52.3 82 53.8 82.8 55.3 82.8 56.8 84.8 59.3 85.6 60.3 86.6 60 87.1 60.5 88.2 61.8 89.7 62.3 89.7" />
          <polygon points="44.8 72 46.1 72 45.8 73.5 45.1 73.8 44.3 73 44.6 72" />
          <polygon points="40.9 69.2 40.9 67.9 42.1 67.6 42.1 66.3 44.6 66.3 44.8 67.6 46.3 67.6 46.3 68.4 47.8 68.6 47.8 72 44.8 72 44.8 71.2 44.3 71.2 44.1 70.4 42.1 70.4 42.1 69.2" />
          <polygon points="51.6 69.2 50.6 68.9 50.1 69.2 50.1 70.4 47.8 70.7 47.8 68.9 46.3 68.6 46.3 67.6 45.1 67.6 44.8 66.3 42.1 66.3 42.1 63.5 49.8 63.2 49.3 63.8 49.3 65.8 51.1 66.6 51.1 67.1 52.1 68.1" />
          <polygon points="46.1 72 49.1 72 49.1 73.3 48.1 73.5 48.6 73.5 48.6 74 47.6 74.6 46.6 74.3 46.8 73.5 45.8 73.5 46.1 72.8" />
          <polygon points="47.8 70.7 50.1 70.7 50.1 69.2 51.6 69.2 50.6 71 50.8 73.8 50.1 74.6 50.6 75.1 50.6 76.9 48.6 76.3 48.6 73.5 48.1 73.5 49.1 73.5 49.1 72 47.8 72" />
          <polygon points="10.7 48.1 5 48.1 5.2 45.8 4.7 45.2 5.2 43.2 4.7 42.7 5 42.4 4.5 42.4 4.7 38.6 4.2 38.6 4.2 33.2 13.2 33.2 13.2 39.3 11.7 39.3 12 45 14.7 45.2 14.7 44.5 15.9 44.2 16.2 46.8 12.2 46.5 12 48.1" />
          <polygon points="10.7 48.1 12 48.1 12.2 46.5 19.2 46.8 19.2 47.8 23.4 48.1 23.7 61.4 20.4 61.2 20.9 60.7 20.9 59.9 19.4 59.6 19.7 59.9 19.4 57.1 11 57.1" />
          <polygon points="33.6 62.5 23.7 62.7 23.4 46.8 32.4 46.5 32.9 48.6 32.9 54 32.1 54.8 32.4 56 38.9 56.3 39.1 60.4 36.4 60.4 36.4 61.4 32.9 61.7 32.6 62 33.4 62.5" />
          <polygon points="31.6 12.1 31.9 31.6 26.2 31.6 26.2 32.9 25.9 32.6 26.2 34.7 28.9 34.7 29.1 45.2 27.7 45.2 27.7 46.8 23.4 46.5 23.4 48.1 19.2 48.1 19.2 46.8 16.2 46.8 15.9 44.2 14.7 44.5 14.7 45 14.2 45.2 12 45.2 11.7 39.3 13.2 39.3 13.2 33.2 18.9 33.2 18.4 14.9 21.4 14.9 21.4 12.1 24.2 12.1 24.2 8.2 22.9 9 22.4 9 21.7 8.2 21.7 7.2 23.4 6.9 23.4 0 24.4 0.5 25.9 0.3 27.4 1 27.2 1.3 28.2 5.1 28.2 6.9 28.9 9 28.6 10.5 29.9 11.8 31.6 12.3" />
          <polygon points="13.2 33.2 4.2 33.2 4.5 32.9 4 32.6 4 30.1 3 28.5 3 27.5 2.2 26.7 2.5 26 2 25.4 2 24.2 1 21.9 1.2 20.6 0.7 20.6 1.2 20.6 1.2 18.5 0.7 18 1.2 17 0.7 15.9 1.2 15.4 0.7 15.4 1 14.7 0.5 14.7 1 14.4 0.7 14.1 1 14.4 0.7 14.1 1.2 13.6 1 13.4 1.5 12.3 0.7 11.1 1 10.8 0.5 10 0 6.9 21.7 6.9 21.7 8 22.4 9 23.7 8.7 24.2 8.2 24.2 12.1 21.4 12.1 21.4 14.9 18.4 14.9 18.9 33.2 14.4 33.2" />
          <polygon points="45.8 41.4 47.3 41.4 47.3 55.8 43.3 56.3 43.1 54.8 39.1 54.8 38.9 56.3 32.4 56.3 32.1 55 32.9 54.2 32.6 45.5 39.1 45.2 39.1 41.4 45.6 41.4" />
          <polygon points="31.9 27.2 39.1 27.2 39.1 32.4 44.8 32.4 44.8 33.9 44.3 33.9 44.3 39.8 45.8 39.8 45.8 41.4 39.1 41.4 39.1 45.2 32.6 45.2 32.6 46.3 32.1 46.8 27.7 46.8 27.7 45.2 28.9 45.2 29.1 43.7 28.9 35 26.4 34.7 25.9 32.6 26.2 32.9 26.2 31.6 31.9 31.6 31.9 28.8" />
          <polygon points="55.8 47.3 56.1 57.3 55.3 58.1 55.3 58.6 54.6 58.4 54.1 59.4 53.3 59.1 52.6 59.9 52.1 59.9 50.8 61.2 49.8 63.2 43.6 63.2 43.6 59.1 45.8 59.1 45.8 56 47.3 56 47.3 45.8 50.1 45.8 50.1 42.9 53.1 42.9 53.1 44.2 54.3 44.5 54.3 45.8 55.8 46" />
          <polygon points="39.1 27.2 39.1 26.2 41.9 26.2 47.1 26.5 47.1 27.8 58.8 28 58.8 39.8 57.3 39.8 57.3 42.9 55.8 42.9 55.8 44.5 53.3 44.5 53.1 42.9 50.1 42.9 50.1 45.8 47.3 45.8 47.3 41.6 46.1 41.4 45.8 39.8 44.3 39.8 44.3 33.9 44.8 33.9 44.8 32.4 39.1 32.4 39.1 28" />
          <polygon points="54.3 100 54.3 94.6 58.3 94.3 58.3 93.1 59.8 93.1 59.8 91.5 62.8 90.7 62.5 89.7 62.5 90.2 64 91.5 65.5 91.8 67.5 94.3 67.5 98.2 68 98.5 67.8 99.2 68.3 99.7 67.8 100 55.6 100" />
          <polygon points="56.1 47.6 55.8 47.3 56.3 47.3 56.8 45.8 57.3 45.8 57.3 45 58.3 45 58.3 44.5 60.3 44 58.3 45.5 59 46.8 58.3 46 57.3 46.8 57 46.5 57.3 47.3 56.8 47.8" />
          <polygon points="31.6 12.1 33.1 12.1 33.9 12.6 33.9 13.1 38.4 13.4 38.9 15.4 41.9 15.2 42.8 14.7 42.6 14.4 43.1 13.9 45.1 13.1 47.6 13.6 48.6 13.4 48.6 13.9 49.1 14.1 50.8 14.9 51.8 14.9 52.1 15.4 51.3 15.9 51.8 17 53.3 16.7 54.1 17.2 53.8 18 55.1 20.6 56.3 20.1 55.8 19.5 55.8 18.8 56.3 18.3 58.5 18.3 59.3 19 59 19.3 59.3 20.1 59.8 20.1 59.8 20.6 60.5 20.3 61.5 21.1 62.3 20.8 62.3 21.6 62.8 22.1 62.5 22.4 64.3 22.9 64 23.7 67.8 23.1 69.5 21.3 70 21.3 70.8 20.6 72.2 20.3 73.2 21.9 73.2 22.9 75 22.4 75.5 22.4 75.5 22.9 81 22.6 81.7 22.9 82 24.2 83 24.7 84 24.2 87.7 24.4 86.7 24.9 85.9 24.7 85.9 25.2 85.5 25.2 84.7 26.2 83 26.7 81.7 27.8 76.5 29.3 73.7 30.8 70.8 33.4 67.5 37.3 63 41.6 62.5 41.6 61.3 43.2 59.5 44.2 58.3 44.2 57.8 45.2 57.3 45 56.6 47.3 56.1 47.3 55.6 45.8 54.3 45.8 54.3 44.5 55.8 44.5 55.8 42.9 57 42.9 57.3 39.8 58.8 39.8 58.8 28 47.1 27.8 46.8 26.5 42.1 26.2 39.1 26.2 39.1 27 38.4 27.2 32.1 27.2 31.6 12.9" />
          <polygon points="22.9 100 8.7 100 8.7 81.7 13 81.5 13 85.9 18.4 85.9 18.4 87.4 22.7 87.4 22.7 88.4 24.2 88.7 24.2 90 26.9 90.2 26.9 94.1 24.2 94.3 24.2 96.9 23.2 97.4 22.9 97.2" />
          <polygon points="8.7 71 10.2 71.2 11 72 12.7 72.5 14.7 74.3 16.2 76.3 16.7 76.3 18.4 78.1 18.9 78.1 19.9 79.7 22.2 81.2 22.4 81 23.2 81.7 23.7 81.7 23.7 82.3 23.9 81.7 26.4 81.5 26.9 83.8 27.7 84.6 31.6 86.6 31.4 86.9 32.1 87.7 32.4 90 24.2 90.2 24.2 88.7 22.7 88.7 22.7 87.7 18.4 87.4 18.4 85.9 13 85.9 13 81.5 8.7 81.5 8.7 72" />
          <polygon points="8.7 71 8.5 69.7 7 68.6 6 68.6 5.5 67.1 4.2 65.6 4.2 65 6.2 63.5 7.5 61.7 7.7 57.3 7.2 56.3 7.2 53.2 6.2 52.4 6.5 52.2 5.7 51.4 5.5 49.1 5 48.1 10.7 48.1 11 57.1 19.4 57.1 19.4 59.6 20.9 59.9 20.9 61.4 23.7 61.4 23.7 62.7 30.9 62.7 30.9 67.1 27.9 67.1 27.9 68.6 12.7 68.6 12.7 72.5 9 71" />
          <polygon points="12.7 72.5 12.7 68.9 27.9 68.6 27.9 77.4 30.6 77.4 30.9 80.2 29.4 80.2 29.6 84.6 27.7 84.6 26.9 83.8 26.7 81.5 23.9 81.7 23.9 82.3 22.7 81 21.9 81.2 21.4 80.5 20.9 80.5 19.9 79.4 19.7 79.7 19.7 79.2 19.2 79.2 19.7 78.9 18.9 78.1 18.4 78.1 16.7 76.3 16.2 76.3 15.9 75.6 13.2 72.8 12.7 72.8" />
          <polygon points="35.4 67.9 35.9 68.9 34.9 70.4 34.1 70.2 32.6 71 32.4 70.2 27.9 70.2 27.9 67.1 30.9 67.1 31.1 62.7 33.6 62.5 33.4 62.7 34.6 64.3 34.4 64.8 35.1 64.8 35.4 65.6 34.9 66.1 33.6 65.8 33.6 68.4 34.9 68.6" />
          <polygon points="39.1 100 23.2 100 22.9 97.2 24.2 97.2 24.2 94.3 26.9 94.3 26.9 90.5 32.4 90.2 32.4 87.7 35.9 89.2 36.1 90 36.6 90.2 37.9 90.2 37.9 87.9 39.1 87.9 39.1 87.4 42.1 87.4 41.9 91.5 43.3 91.5 43.3 94.3 39.1 94.3 39.1 99" />
          <polygon points="54.3 94.6 54.3 100 39.1 100 39.1 94.3 47.3 94.3 47.3 93.1 51.6 93.1 51.6 94.3 53.8 94.6" />
          <polygon points="58.3 93.1 58.3 94.3 51.6 94.6 51.6 91.5 54.1 91.5 54.3 88.7 55.3 88.7 55.6 90.2 58.3 90.5" />
          <polygon points="32.4 87.7 30.6 85.9 29.1 85.3 28.9 85.6 28.9 85.1 27.9 84.6 37.4 84.6 36.9 85.6 37.4 86.1 37.1 87.9 37.9 87.9 37.9 90.2 36.1 90.2 36.1 89.7 35.4 88.9 33.6 88.4 32.6 87.7" />
          <polygon points="37.4 84.6 29.6 84.6 29.4 80.2 30.9 80.2 30.9 77.4 27.9 77.4 27.9 70.2 32.1 70.2 32.4 71 33.4 70.7 33.9 73 35.1 73 35.1 74.6 33.6 74.3 33.6 75.8 36.4 75.8 36.4 80.2 37.9 80.2 37.9 81 39.1 81 39.1 81.5 38.6 81.5 37.6 82.5 37.9 83.8 37.4 84.3" />
          <polygon points="42.1 87.4 39.1 87.4 39.1 87.9 37.4 87.9 37.1 85.1 37.9 83.8 37.6 82.5 38.6 81.5 44.8 81.5 44.8 84.3 47.6 84.3 47.6 85.9 43.3 85.9 43.1 87.4" />
          <polygon points="39.1 81.5 39.1 81 37.9 81 37.9 80.2 36.6 80.2 36.4 75.8 39.4 75.8 39.4 77.4 41.4 77.4 41.1 77.6 42.1 77.9 42.1 78.7 40.9 79.4 40.9 80.5 40.4 80.5 39.4 81.5" />
          <polygon points="39.4 75.8 39.4 74.3 40.1 73.3 42.1 73 42.1 77.9 41.1 77.6 41.4 77.4 39.4 77.4" />
          <polygon points="44.3 81.5 39.4 81.2 39.9 81.2 41.9 78.7 43.3 78.7 43.3 80.2 44.3 80.2 44.3 80.7" />
          <polygon points="43.3 78.9 42.1 78.7 42.4 76.6 43.1 76.6 42.8 77.4 43.3 77.9" />
          <polygon points="42.1 74.6 43.1 74.6 42.8 75.8 43.3 75.8 43.3 76.6 42.1 76.6 42.6 76.1 42.1 75.8" />
          <polygon points="44.3 77.4 43.6 79.2 43.3 77.9 42.8 77.4 44.1 76.6 44.3 77.1" />
          <polygon points="44.1 76.6 43.1 77.1 43.3 75.8 42.8 75.8 42.8 75.3 43.1 75.6 43.1 75.1 44.1 76.1" />
          <polygon points="43.1 74.6 44.1 74.6 44.3 75.8 43.8 75.8" />
          <polygon points="43.3 74.6 43.3 74 44.8 73.8 44.8 74.3 44.3 74.8 44.1 74.6" />
          <polygon points="35.1 64.8 36.4 64.8 36.6 67.6 35.4 67.6 35.1 68.6 33.6 68.6 33.6 65.8 34.9 66.1 35.4 65.6 35.1 65.3" />
          <polygon points="35.1 70.2 36.4 70.2 36.4 69.4 37.6 69.2 38.9 70.4 39.9 70.7 39.9 71.2 39.4 71.5 39.4 73 40.1 73.3 39.6 73.8 39.9 74 39.4 74 39.4 75.8 33.9 75.8 33.6 74.3 34.9 74.6 35.1 73 33.6 72.8 33.6 70.7 34.1 70.2" />
          <polygon points="40.9 69.2 42.1 69.2 42.1 71.5 41.6 72 40.6 72 40.4 72.2 40.9 72.8 39.4 73 39.4 71.5 39.9 71.5 39.9 70.4 39.1 70.4 38.9 69.2 40.9 69.4" />
          <polygon points="42.1 74.6 42.1 73.3 40.9 73 40.4 72.2 41.6 72 42.1 71.5 42.8 72 43.3 73 43.6 73.8 43.1 74.6" />
          <polygon points="43.3 73.8 43.6 73.5 43.1 73 43.1 72 43.6 72.5 44.3 72.2 44.6 73.8" />
          <polygon points="44.8 72 43.8 72.5 42.4 71.5 42.1 70.4 44.1 70.4 44.1 71 44.8 71.2" />
        </g>
      </symbol>
    )
  }
}

export default IconMnSenate
