import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import Dropdown from 'react-dropdown'
import { DATE_WHITELIST } from '../../config'

const ElectionDropdown = () => {
  const location = useLocation()
  const value = location.pathname.split('/')[1]

  const options = []

  DATE_WHITELIST.forEach((date) => {
    let prettyDate = new Date(date)
    let label = 'Election'

    if (format(prettyDate, 'MM') === '08') {
      label = 'Primary'
    } else if (format(prettyDate, 'MM') === '03') {
      label = 'Super Tuesday'
    }

    options.push({ value: date, label: format(prettyDate, `yyyy '${label}'`) })
  })

  function handleElectionNav(evt) {
    window.location = `/${evt.value}`
  }

  useEffect(() => {
    let arrowWrapper = Array.from(
      document.getElementsByClassName('Dropdown-arrow-wrapper')
    )[0]
    let dropdownContainer = Array.from(
      document.getElementsByClassName('Dropdown-placeholder')
    )[0]
    arrowWrapper.setAttribute('style', 'display: block')
    dropdownContainer.appendChild(arrowWrapper)
  }, [])

  return (
    <Dropdown
      className="electionDropdown"
      options={options}
      value={value}
      onChange={handleElectionNav}
      arrowOpen={
        <svg
          className="electionDropdown_arrow"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
          />
        </svg>
      }
      arrowClosed={
        <svg
          className="electionDropdown_arrow"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
          />
        </svg>
      }
    />
  )
}

export default ElectionDropdown
