import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import UsSenateResults from './UsSenateResults'
import { API_ENDPOINT } from '../../config'
import ElectionContext from '../../context/ElectionContext'

const UsSenate = () => {
  const context = useContext(ElectionContext)
  return (
    <div className="Legislature">
      <Helmet>
        <title>MPR News | Live Results for U.S. Senate</title>
        <meta
          name="twitter:title"
          content="LIVE Election Results from MPR News | U.S. Senate"
        />
        <meta
          property="og:title"
          content="LIVE Election Results from MPR News | U.S. Senate"
        />
      </Helmet>
      <UsSenateResults
        interval={30000}
        url={`${API_ENDPOINT}/${context.date}/us_senate_races`}
      />
    </div>
  )
}

export default UsSenate
