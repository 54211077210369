import React from 'react'
import Loading from '../Loading'
import Error from '../Error'
import PropTypes from 'prop-types'
import useData from '../../hooks/useData'
import Sprite from '../Svg/states/Sprite'
import Svg from '../Svg/states'
import { default as SvgIcon } from '../Svg'
import { useDefaultImg, DEFAULT_CANDIDATE_IMAGE } from '../../util'
import { THOSE_WHOMST_HAVE_PICS } from '../../config'

const PresidentialPrimaryStates = ({ url, interval }) => {
  const data = useData(url, interval)
  if (data === undefined) {
    return <Loading />
  } else if (typeof data !== 'object') {
    return <Error err={data} />
  }

  return (
    <div className="section section-borderBottom">
      <div className="presidentialPrimaryResults">
        <div className="results_source">
          Data Source:{' '}
          {data.meta.election_data_source === 'mn_oss' ? 'MN SOS' : 'AP'}
        </div>
        {data.states?.map((state, stateShape) => {
          return (
            <StateRow
              {...state}
              stateShape={stateShape}
              stateAbbrev={state.state_postal}
              key={state.state_postal}
            />
          )
        })}
      </div>
    </div>
  )
}

const StateRow = ({ state, results, stateAbbrev }) => {
  // TODO: figure out how to display the dozen+ candidates reasonably
  const getPercent = (percentDecimal) => {
    return Number.parseFloat(percentDecimal)
  }
  const currentPercent = results[0].precincts_reporting_percent
  const moveDecimalOverTwice = 'e+2'

  let picturedCandidates = results[0]?.candidates.filter((candidate) => {
    return THOSE_WHOMST_HAVE_PICS.includes(parseInt(candidate.candidate_id))
  })

  let unpicturedCandidates = results[0]?.candidates.filter((candidate) => {
    return !THOSE_WHOMST_HAVE_PICS.includes(parseInt(candidate.candidate_id))
  })

  return (
    <div className="row row-candidate row-wrap" data-party={results[0].party}>
      <div className="column column-state">
        <div className="state state-wrapper">
          <div className="row row-em">
            <div className="state_flex state_margin">
              <div className="stateSvg">
                <Svg statesvg={`${stateAbbrev}`} />
              </div>
              <div className="cell cell-state">{state}</div>
            </div>
          </div>
          <div className="row row-em">
            <div className="cell_reporting">
              Reporting {getPercent(currentPercent + moveDecimalOverTwice)}%
            </div>
          </div>
        </div>
      </div>
      <div className="column column-candidate">
        <div className="row row-wrap">
          {picturedCandidates?.map((candidate) => {
            return <CandidateCell {...candidate} key={candidate.candidate_id} />
          })}
          {unpicturedCandidates?.map((candidate) => {
            return (
              <CandidateCell
                {...candidate}
                has_photo={false}
                key={candidate.candidate_id}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

const CandidateCell = ({
  candidate_name,
  candidate_id,
  uncontested,
  vote_percent,
  vote_count,
  winner = false,
  has_photo = true,
}) => {
  const photo = has_photo ? (
    <img
      src={`/data/imgs/candidates/${candidate_id}.jpg`}
      height="64"
      width="64"
      alt={candidate_name}
      onError={useDefaultImg}
    />
  ) : (
    <img
      src={DEFAULT_CANDIDATE_IMAGE}
      height="64"
      width="64"
      alt={candidate_name}
    />
  )

  return (
    <div className="cell cell-candidate">
      <div className="cell_icon">{photo}</div>
      <div className="cell_column">
        <div className="cell_row">
          <div className="cell_name">{candidate_name}</div>
        </div>
        <div className="cell_row">
          {uncontested ? (
            <div className="cell_vote cell_vote-em">Uncontested</div>
          ) : (
            <div className="cell_vote">
              <span className="cell_votePercent">
                {vote_percent.toLocaleString('en-US', { style: 'percent' })}
              </span>
              <div className={`cell_voteCount${winner ? ' winner' : ''}`}>
                {winner && (
                  <SvgIcon icon="checkmark" origHeight={13} origWidth={17} />
                )}
                <div className="cell_voteNumber">
                  {vote_count.toLocaleString('en-US')}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Sprite />
    </div>
  )
}

PresidentialPrimaryStates.propTypes = {
  url: PropTypes.string.isRequired,
  interval: PropTypes.number,
}

export default PresidentialPrimaryStates
