import React, { useState } from 'react'
import { PARTY_CSS_CLASSES } from '../../config'
import { slugify } from '../../util'
import PropTypes from 'prop-types'
import Svg from '../Svg'

const PresidentMinn = ({ candidates, mnReportingPct }) => {
  const [displayCandidate, setDisplayCandidate] = useState()

  const getSortedCandidates = () => {
    return candidates.sort((a, b) => {
      if (a.mnPopularVote > b.mnPopularVote) {
        return -1
      }
      if (a.mnPopularVote < b.mnPopularVote) {
        return 1
      }
      return 0
    })
  }

  const buildCandidateNodesMini = () => {
    return getSortedCandidates().map((candidate) => {
      const pctClasses = `candidate_pct ${
        candidate.winner === true ? 'candidate_pct_win' : ''
      }`

      return (
        <li
          className={`candidate candidate-compact ${
            displayCandidate === candidate.name ? 'active' : ''
          }`}
          key={`${slugify(candidate.name)}-minn-candidate`}
        >
          <div className="candidate_stats">
            <div className="hdg hdg-3">{candidate.name}</div>
            <div className={pctClasses}>
              {Math.round(candidate.mnPopularVotePct * 100)}%
            </div>
            <div className="candidate_votes">
              {candidate.mnPopularVote.toLocaleString()}
            </div>
          </div>
        </li>
      )
    })
  }

  const buildCandidateBars = () => {
    return getSortedCandidates().map((candidate) => {
      const raceBarStyle = {
        width: candidate.mnPopularVotePct * 100 + '%',
      }

      const raceBarClass = `graph_segment ${PARTY_CSS_CLASSES[candidate.party]}`

      return (
        <div
          onMouseOver={() => setDisplayCandidate(candidate.name)}
          onFocus={() => setDisplayCandidate(candidate.name)}
          onMouseOut={() => setDisplayCandidate(undefined)}
          onBlur={() => setDisplayCandidate(undefined)}
          key={`${slugify(candidate.name)}-minn-bar`}
          className={`${raceBarClass} ${
            displayCandidate === candidate.name ? 'active' : ''
          }`}
          style={raceBarStyle}
        >
          <span className="invisible">
            {`${candidate.name}: ${Math.round(
              candidate.mnPopularVotePct * 100
            )}%`}
          </span>
        </div>
      )
    })
  }
  return (
    <div className="race">
      <div className="race_header">
        <div className="split">
          <div className="split_left">
            <h3 className="hdg hdg-3">
              <Svg icon="mn" />
              Minnesota Popular Vote
            </h3>
          </div>
          <div className="split_right">
            {Math.round(mnReportingPct * 100)}% Reporting
          </div>
        </div>
      </div>
      <div className="race_graph">
        {Math.round(mnReportingPct * 100) > 0 && (
          <div className="graph">{buildCandidateBars()}</div>
        )}
      </div>
      <div className="race_body">
        {Math.round(mnReportingPct * 100) > 0 ? (
          <ol className="race_candidates">{buildCandidateNodesMini()}</ol>
        ) : (
          <p>No results yet - check back soon!</p>
        )}
      </div>
    </div>
  )
}

PresidentMinn.propTypes = {
  candidates: PropTypes.array,
  mnReportingPct: PropTypes.number,
}

export default PresidentMinn
