import React, { useContext } from 'react'
import ElectionContext from '../../context/ElectionContext'
import { API_ENDPOINT } from '../../config'
import HomeResults from './HomeResults'

const Home = () => {
  const context = useContext(ElectionContext)

  return (
    <div className="Home">
      <HomeResults
        interval={30000}
        url={`${API_ENDPOINT}/${context.date}/home`}
        overview={context.overview}
      />
    </div>
  )
}

export default Home
