import React from 'react'

function Loading() {
  return (
    <div className="loading">
      <span className="loading_text">Loading election results</span>
      <div className="spinner" />
    </div>
  )
}

export default Loading
