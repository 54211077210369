import React, { Component } from 'react'

class AL extends Component {
  render() {
    return (
      <symbol id="statesvg-AL" width="100" height="100" viewBox="0 0 100 100">
        <path d="M68.92,2.14l6.75,42.38,1.78,5.73,2,4.07-.13,2.16L81.14,58l-2.42,2.16-1.9,6.75,1.9,6-1.14,7.38,1.65,2,.13,1.91L33.92,84l-.63,2.55,3.94,4.07L36.6,93l.63,2.16-2.67,2.42-.89-.25v.51h.64l-1.79,1-6.74.64,4.84-1-1.15-2.54H27.94l-.38-1.66.38-2.16-1.65-2.93L24,97.59l-3.06-1.65L19,96.06l-.51-30.8,8.15-61.6L25.14,1.5ZM25,98.86H20.94l3.44-.38Z" />
      </symbol>
    )
  }
}

export default AL
