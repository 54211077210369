import React, { Component } from 'react'

class IconChevronRight extends Component {
  render() {
    return (
      <svg>
        <symbol
          id="icon-chevronRight"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </symbol>
      </svg>
    )
  }
}

export default IconChevronRight
