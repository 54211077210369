import React, { Component } from 'react'

class NC extends Component {
  render() {
    return (
      <symbol id="statesvg-NC" width="100" height="100" viewBox="0 0 100 100">
        <path
          d="M47.4,37.3l43-0.6v1l0.6,0.5v0.3l0.5,0.5v-0.8l1.4,4.5l-1.9-2.9l0.6,1.8l-2.8-1.9l1.4,2l-1.1,0.5L87.9,41l0.7,1.6L86,41.2
	l1.7,1.6l-2.9,1.2l-1.1-0.5l-0.3-1.7l0.3-1.3l-0.6-0.5L83,42.3l0.8,1.6l-0.3,0.7l3.1-0.5l1.7,0.7L88,44.2l2.4-0.2v2.4h-0.7l1,1.2
	l-1.2,0.2l1.2,0.5l0.3-3.1l0.5-0.5l0.7,0.5L92,44.6h0.8l-0.2-0.5l1.2,1.9v2.4l-1.1,0.9l-0.5-0.9l-1.4,3l-1.3,0.9l-2.1-0.7l-0.2,0.7
	l-0.6-1.5L86,51.7l-1-1.3l1.2-0.5l-1.9,0.2l0.6,1.6L80,50.1l1,1.3l5.2,1.9l-2,1.4l1.2-0.2l0.2,0.8l-2.4,1.7l-3.3-2l1.4,2.2l2.2,0.9
	l1.2-1.1l0.9,0.7l-0.3-0.7l0.9,0.2l0.4-1.3l-0.1,1.7l1.4-0.5l-0.4-0.6l0.9,0.8l-2.4,3l-1.2-1l-0.2,1.5l-0.6-1.2l-1,0.5l0.9,0.3h-2.1
	l-2,0.7l-0.5-1v1l-1.9,1.6l-0.8-0.4l0.7-0.7l-1.2-1.3l0.6,1.4l-0.7,0.5l0.7,1.2L75,63.9l-2,1.9l-2,5l0.2-2.1l-0.6-1.2l0.4,2.5
	l-1,1.5l-3.6-0.8l-2,1.2L52.6,59.7l-12-0.5l-0.1-1.6L39,55.4L37.7,56v-1l-13.9-1.2L16,56.3L3,55.9l0.5-3.2l2.3-0.4l0.7-1.9L8,49.2
	l4.1-0.4l3.6-2.3l1.9-0.4l1.2-2l0.9,0.5l1.8-1.8l0.8,1.3l3.8-2.5l1.6,0.7l1.9-2.6l1.9-0.5l0.5-3.3L47.4,37.3z M71.1,71l-0.5,1
	L70,71.8l0.2-0.4L71.1,71z M72.2,67.7l-0.4,1l-0.2,0.1l0.3-0.9L72.2,67.7z M77.2,63.3l-1.9,0.9l-1.2,1.1l0.6-0.7l1.2-1L77.2,63.3z
	 M84.1,60.8h-1.4l-2.9,0.7l2-0.7l1.7-0.2L84.1,60.8z M85.8,61.5h-0.2l-1-0.7l0.9,0.2L85.8,61.5z M86,61.9l1.2-2.4l2-2.2l-1.9,2.3
	L86,61.9z M89.9,56.9l0.6-1h0.5l-1,0.7L89.9,56.9z M91.1,36.7h0.5l0.2,0.6h-0.7l-0.3-0.6H91.1z M92.2,55l-1.1,0.7l0.4-0.5l2.2-1.2
	L92.2,55z M93,39.6l0.9,2.4l2.2,4.4l-2.2-3l0.3-0.1l-1.4-3.5L92,36.7L93,39.6z M95.1,45.9l-0.4-0.2l-0.8-1.2l0.8,0.1L95.1,45.9z
	 M96.1,53.6l-2,0.5l2.3-1.5l0.2-3.4l-0.5-2.7l0.8,2.4l-0.4,3.2L96.1,53.6z"
        />
      </symbol>
    )
  }
}

export default NC
