import React, { Component } from 'react'

class MO extends Component {
  render() {
    return (
      <symbol id="statesvg-MO" width="100" height="100" viewBox="0 0 100 100">
        <path d="M63,19l-1.14,3.44.85,5.24,1.15,2.58,7.44,7.53,1.05,4.67,1.14,1.91,1-.1L76,43l4,2.38-3.15,8.2.19,2.1,3.15,3.34,2.57,1.05,5.34,4.76V67l1.43,2.58L88.1,71.83l2.2,3.62,1.24.76L91.15,75l.77-.28,1,1.81h.76l-.1,3.72-1,3-2.19-.86-1.33,3-.39-1.72h-.85l.57,2.86-1.43,1L88,88.61l-1.91.19,1.14,2-1.52,2.48-8.87.1.86-2.19L81,88.32,81,86.89,79.71,84.8,18.77,83.56l2.29-45-3-1.14-.66-3-2.68-2.76,1.91-3.06H18.1V27.1l-1-1.24-1.91.48-4-3-.67-3.63L8.28,18.33,7.9,14.89l-.76-.76.09-.86,22.51,1.62H59.21Z" />
      </symbol>
    )
  }
}

export default MO
