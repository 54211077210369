import React, { Component } from 'react'

class IconExternal extends Component {
  render() {
    return (
      <symbol id="icon-external" width="16" height="16" viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          d="M14,10 L16,10 L16,1 L4,1 L4,3 L14,3 L14,10 Z M0,5 L12,5 L12,14 L0,14 L0,5 Z"
        />
      </symbol>
    )
  }
}

export default IconExternal
