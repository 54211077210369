import React from 'react'
import Loading from '../Loading'
import RaceFull from '../RaceFull'
import useData from '../../hooks/useData'
import PropTypes from 'prop-types'
import Error from '../Error'

// TODO: Consider using a non-political color scheme for each candidate so that the Judicial page doesn't look so boring.
const JudicialResults = ({ url, interval }) => {
  const data = useData(url, interval)
  if (data === undefined) {
    return <Loading />
  } else if (typeof data === 'string') {
    return <Error err={data} />
  }

  const { courtOfAppeals, districtCourt, supremeCount } = data

  return (
    <div className="grid_col-narrow">
      <div className="section">
        <div className="blocks blocks-1">
          {supremeCount.map((race, i) => {
            return (
              <RaceFull
                {...race}
                level="supremecourt"
                key={`supremecourt_${i}`}
              />
            )
          })}
        </div>
        <div className="blocks blocks-1">
          {districtCourt.map((race, i) => {
            return (
              <RaceFull {...race} level="districtcourt" key={`district_${i}`} />
            )
          })}
        </div>
        <div className="blocks blocks-1">
          {courtOfAppeals.map((race, i) => {
            return (
              <RaceFull
                {...race}
                level="courtofappeals"
                key={`courtofappeals_${i}`}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

JudicialResults.propTypes = {
  url: PropTypes.string.isRequired,
  interval: PropTypes.number,
}

export default JudicialResults
