// @ TODO
import logo from '../../assets/svg/logo-election2020.svg'

const nav = [
  {
    title: 'Presidential Primary',
    href: 'president/primary',
    icon: 'whitehouse',
  },
  {
    title: 'Results by County',
    href: 'president/county',
    icon: 'mn-counties',
  },
  {
    title: 'Election Calendar',
    href: 'https://www.mprnews.org/election2020/calendar',
    icon: 'blank-calendar',
  },
  {
    title: 'Submit Your Voter Story',
    href: 'https://mprnews.typeform.com/to/oKkeTd',
    icon: 'ballot-box',
  },
  {
    title: 'Live Coverage',
    href: 'https://live.mprnews.org/Event/Election_2020_Super_Tuesday',
    icon: 'live',
  },
]

const overview = [
  {
    type: 'PresidentialPrimary',
  },
]

const national = []

const electionConfig2020Primary = {
  ap: false,
  slug: 'electionConfig2020Primary',
  date: '2020-03-03',
  nav: nav,
  year: '2020',
  overview: overview,
  national: national,
  logo: logo,
}

// Used colors that are 99% accessible from here: https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
export const PRIMARY_CANDIDATE_COLORS_2020 = {
  sanders: '#fabebe', // Pink
  biden: '#aaffc3', // Mint
  warren: '#e6beff', // Lavender
  buttigieg: '#f58231', // Orange
  klobuchar: '#fffac8', // Beige
  steyer: '#9A6324', // Brown
  gabbard: '#ffe119', // Yellow
  bloomberg: '#3cb44b', // Green
  bennet: '#42d4f4', // Cyan
  booker: '#4363d8', // Blue
  castro: '#f032e6', // Magenta
  patrick: '#800000', // Maroon
  williamson: '#e6194B', // Red
  delaney: '#469990', // Teal
  yang: '#000075', // Navy
  trump: '#800000', // Maroon (again)
  uncommitted: '#333333', // Dark Gray
  writeIn: '#eeeeee', // Almost White
}

export const PRIMARY_DEM_CANDIDATES_2020 = [
  'Bernie Sanders',
  'Joe Biden',
  'Amy Klobuchar',
  'Elizabeth Warren',
  'Michael R. Bloomberg',
  'Tulsi Gabbard',
  'Pete Buttigieg',
  'Tom Steyer',
  'Michael Bennet',
  'Cory Booker',
  'Julián Castro',
  'Deval Patrick',
  'Marianne Williamson',
  'John K. Delaney',
  'Andrew Yang',
  'Uncommitted',
]

export const PRIMARY_GOP_CANDIDATES_2020 = ['Donald Trump']

export default electionConfig2020Primary
