const maps = {
  usHouse: [
    {
      dataLabel: 'usHouse',
      properName: 'District',
      keyAttribute: 'DISTRICT',
      topoJson: `${process.env.PUBLIC_URL}/data/maps/mn-congressional-district.topo.json`,
    },
    {
      dataLabel: 'usHouse',
      properName: 'District',
      keyAttribute: 'DISTRICT',
      topoJson: `${process.env.PUBLIC_URL}/data/maps/2022-mn-congressional-district.topo.json`,
    },
  ],
  mnGovernor: {
    dataLabel: 'governorCounty',
    properName: 'COUNTYNAME',
    keyAttribute: 'COUNTYKEY',
    topoJson: `${process.env.PUBLIC_URL}/data/maps/mn-counties.topo.json`,
  },
  mnPresident: {
    dataLabel: 'county',
    properName: 'COUNTYNAME',
    keyAttribute: 'COUNTYKEY',
    topoJson: `${process.env.PUBLIC_URL}/data/maps/mn-counties.topo.json`,
  },
  mnHouse: [
    {
      dataLabel: 'mnHouse',
      properName: 'District',
      keyAttribute: 'DISTRICT',
      topoJson: `${process.env.PUBLIC_URL}/data/maps/mn-legislature.topo.json`,
    },
    {
      dataLabel: 'mnHouse',
      properName: 'District',
      keyAttribute: 'DISTRICT',
      topoJson: `${process.env.PUBLIC_URL}/data/maps/2022-mn-legislature.topo.json`,
    },
  ],
  mnSenate: [
    {
      dataLabel: 'mnSenate',
      properName: 'Senate District',
      keyAttribute: 'DISTRICT',
      topoJson: `${process.env.PUBLIC_URL}/data/maps/mn-senate.topo.json`,
    },
    {
      dataLabel: 'mnSenate',
      properName: 'Senate District',
      keyAttribute: 'DISTRICT',
      topoJson: `${process.env.PUBLIC_URL}/data/maps/2022-mn-senate.topo.json`,
    },
  ],
  president: {
    dataLabel: 'president',
    properName: 'COUNTYNAME',
    keyAttribute: 'COUNTYKEY',
    topoJson: `${process.env.PUBLIC_URL}/data/maps/mn-counties.topo.json`,
  },
  attorneyGeneral: {
    dataLabel: 'attorneyGeneral',
    properName: 'COUNTYNAME',
    keyAttribute: 'COUNTYKEY',
    topoJson: `${process.env.PUBLIC_URL}/data/maps/mn-counties.topo.json`,
  },
  secretaryOfState: {
    dataLabel: 'secretaryOfState',
    properName: 'COUNTYNAME',
    keyAttribute: 'COUNTYKEY',
    topoJson: `${process.env.PUBLIC_URL}/data/maps/mn-counties.topo.json`,
  },
  auditor: {
    dataLabel: 'auditor',
    properName: 'COUNTYNAME',
    keyAttribute: 'COUNTYKEY',
    topoJson: `${process.env.PUBLIC_URL}/data/maps/mn-counties.topo.json`,
  },
  amendment: {
    dataLabel: 'amendment',
    properName: 'COUNTYNAME',
    keyAttribute: 'COUNTYKEY',
    topoJson: `${process.env.PUBLIC_URL}/data/maps/mn-counties.topo.json`,
  },
  usSenate: {
    dataLabel: 'usSenate',
    properName: 'COUNTYNAME',
    keyAttribute: 'COUNTYKEY',
    topoJson: `${process.env.PUBLIC_URL}/data/maps/mn-counties.topo.json`,
  },
  countySelect: {
    dataLabel: 'counties',
    properName: 'COUNTYNAME',
    keyAttribute: 'COUNTYKEY',
    topoJson: `${process.env.PUBLIC_URL}/data/maps/mn-counties.topo.json`,
  },
  //usStates: {
  //dataLabel: 'usStates',
  //properName: 'Nationwide',
  //keyAttribute: 'STATE',
  //topoJson: '/maps/us-states.topo.json'
  //width: 800,
  //height: 450,
  //scale: 900,
  //center: [450, 250]
  //}
}

export default maps
