import React from 'react'
import 'iframe-resizer'
import CreateMarkup from './CreateMarkup'

const ApUsSenate = () => {
  const iframe = `<iframe
    id="iframe_7b4e6a5654c681c3576bbf8a53fa6743" 
    style="overflow: hidden; min-width: 100%; border: none;" 
    src="https://elections.ap.org/widgets/content/7b4e6a5654c681c3576bbf8a53fa6743" 
    width="100%" 
    height="100%" 
    frameborder="1" 
    allowfullscreen="allowfullscreen" 
    onload="iFrameResize({}, '#iframe_7b4e6a5654c681c3576bbf8a53fa6743');"
  />`

  return (
    <div className="embeddedResults">
      <h1>U.S. Senate Results</h1>
      <div dangerouslySetInnerHTML={CreateMarkup(iframe)} />
    </div>
  )
}

export default ApUsSenate
