import { useEffect, useState } from 'react'
import { API_ENDPOINT } from '../config'
import unfetch from 'unfetch'

const usePreviousElection = (context, type, race) => {
  const [results, setResults] = useState(undefined)

  const loadData = async (url) => {
    try {
      const res = await unfetch(url)
      const data = await res.json()
      setResults(data)
    } catch (err) {
      console.log('no previous election set')
    }
  }

  useEffect(() => {
    const year = context?.previousElections && context.previousElections[type]
    if (year) {
      loadData(`${API_ENDPOINT}/${year}/${race}`)
    }
  }, [context, type, race])

  return results
}

export default usePreviousElection
