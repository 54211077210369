import React, { Component } from 'react'

class IL extends Component {
  render() {
    return (
      <symbol id="statesvg-IL" width="100" height="100" viewBox="0 0 100 100">
        <path d="M71.06,5.2V9.46l3.69,8.28.11,38.87-1.23.45.45,1.9L73,60.42l1.9,2.68.34,3-2,3.36.23,1L72,71.17l-1.34,2.91-1-.45-.78,1.23,1,1L68.59,77.1l.9.23-1.23.45v2.57h-.68l.68,1.12h-.68l.68,1L66.8,84.61,67.7,87l-5,1.68.44,4.93-1,.33-6.16-2.57L53.58,93.9l.68,1.46H53.7l-1.35-1.68-.56.22.45,1L50.67,94l-1.34-3.48.67-2-.67-2.24V83.94l-5.27-4.71-2.46-1-3-3.36L38.46,73,40.93,68l.56-3.58L37.9,62.66l-2,1.23-.9-.56-1.34-6-7.28-7.39L25,45.18,24.8,41l.45-1.57,1.34-.56-.11-3,3-1.57,2.8-4.82L32,27.15l-1.46-1.9.9-2.91,4.14-.23,3.14-1.57,3.25-4.81.44-4.37L39.47,9,39.25,7.1,36.9,4.64Z" />
      </symbol>
    )
  }
}

export default IL
