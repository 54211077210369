import React, { Component } from 'react'

class CA extends Component {
  render() {
    return (
      <symbol id="statesvg-CA" width="100" height="100" viewBox="0 0 100 100">
        <path d="M41.09,12.33l.35,20L88.55,71.73l3,5.19,2.25,1.42-3.31,4,.24,4.37L88.9,88.14,90,91.68l1.77.83L91.62,95,68.71,99l-.94-1.42.94.24-.23-.71-1.06.47-.71-4.25L64.58,90.5,59,86.72l-2.24.12L55.73,84l-2.6.24L50.3,83.3l-1.54-1.77-2.83-1.42-7.68-.47-1.18-.71V72.55L34.83,72V69.84l-3.31-2.48-2-3.43-3.07-3.07-.7-3.3.94-.24.59-2.12-1.18-1.3H24.2l-1.53-1.77L20.9,48V45.28L22,44.92l.12,2.13,2.12,1.53H25l-1.3-2.83-1.06-.83L22,43.15l.23-.47,1.18-.12.59.12h3.9l.36-1.06L30,42.68l-1.42-1.06-1.3.94.59-1.06L26,42.56,25,41.5l-1.18,1.06L22,41.38l-1.06.12.59,2.6-.59.59-3.43-2.6-.59.94.24-2.48,1.18,1.07L16.05,38l-4.84-5.08V31.11l-.82-2.84.47-3.3L9.44,21.9,6.37,19.3l-.12-2,.83-2.83L8.5,13l-.71.83L9.21,6.79l-.83-2.6L7.79,3.13,7.91,1H41ZM40,84.24v.24l-1.18-.24.47-.35Zm2.36.24.24.71-1.18.7-.83-1.41ZM44.27,84l2.25-.11.23.35L44.51,85l-1.3-1.06Zm2.84,8,.71.12v.36L46.63,92ZM57.5,90.5l.23.94h-.94L55.25,90Zm0,6.14-1-.12L55.37,94h.36Z" />
      </symbol>
    )
  }
}

export default CA
