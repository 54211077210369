// @ TODO
import logo from '../../assets/svg/logo-election2020.svg'

const nav = [
  {
    title: 'U.S. Senate',
    href: 'ap/ussenate',
    icon: 'mn-counties',
  },
  {
    title: 'U.S. House',
    href: 'ap/ushouse',
    icon: 'mn-counties',
  },
  {
    title: 'MN House - DFL',
    href: 'ap/mnhouse/dfl',
    icon: 'blank-calendar',
  },
  {
    title: 'MN House - GOP',
    href: 'ap/mnhouse/gop',
    icon: 'blank-calendar',
  },
  {
    title: 'MN Senate',
    href: 'ap/mnsenate',
    icon: 'ballot-box',
  },
  {
    title: 'Full Coverage',
    href: 'https://www.mprnews.org/politics/election-2020',
    icon: 'live',
  },
]

const overview = {
  type: 'Ap',
}

const national = []

const electionConfig2020AugustPrimary = {
  ap: true,
  slug: 'electionConfig2020AugustPrimary',
  date: '2020-08-11',
  nav: nav,
  year: '2020',
  overview: overview,
  national: national,
  logo: logo,
}

export default electionConfig2020AugustPrimary
