import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from 'react-simple-maps'
import {
  PRIMARY_CANDIDATE_COLORS_2020,
  PRIMARY_DEM_CANDIDATES_2020,
  PRIMARY_GOP_CANDIDATES_2020,
} from '../../config/elections/electionConfig2020Primary'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import {
  MN_HOUSE_DISTRICT_PATTERN,
  MN_SENATE_DISTRICT_PATTERN,
  US_HOUSE_DISTRICT_PATTERN,
  MN_COUNTY_CODE_PATTERN,
} from '../../util'

const PrimaryMap = ({
  width,
  height,
  projection,
  center,
  topoJson,
  keyAttribute,
  properName,
  mapName,
  data,
  highlightZone,
  elementClass,
}) => {
  const [tooltip, setTooltip] = useState({})
  const location = useLocation()
  const history = useHistory()

  function handleMouseEnter(evt) {
    const label = evt.target.attributes['data-geolabel'].value
    const key = evt.target.attributes['data-geokey']
      ? evt.target.attributes['data-geokey'].value
      : 'label'

    const zoneData = data[key]
    setTooltip({
      label: label,
      reporting_percent: zoneData.reporting_percent,
      winning_vote_percent: zoneData.winning_vote_percent,
      winning_vote_count: zoneData.winning_vote_count,
      winning_candidate: zoneData.winning_candidate,
      won: zoneData.won,
    })

    let paths = document.querySelectorAll('.rsm-geography')
    let pathArray = Array.from(paths)
    pathArray.sort((a, b) => {
      if (
        a.getAttribute('data-geokey') === evt.target.getAttribute('data-geokey')
      ) {
        return -1
      } else {
        return 1
      }
    })
    if (!highlightZone) evt.target.setAttribute('stroke-width', 1.5)
  }

  function handleMouseLeave(evt) {
    if (!highlightZone) evt.target.setAttribute('stroke-width', 0.5)
  }

  function handleFocus(evt) {
    if (!highlightZone) evt.target.setAttribute('stroke-width', 1.5)
  }

  function handleBlur(evt) {
    if (!highlightZone) evt.target.setAttribute('stroke-width', 0.5)
  }

  function handleMouseDown(evt) {
    const key = evt.target.attributes['data-geokey']
      ? evt.target.attributes['data-geokey'].value
      : 'label'

    let url = location.pathname.split('/')
    let slug = url[url.length - 1]

    let isSlugMnHouseDistrict = MN_HOUSE_DISTRICT_PATTERN.test(slug)
    let isSlugMnSenateDistrict = MN_SENATE_DISTRICT_PATTERN.test(slug)
    let isSlugUsHouseDistrict = US_HOUSE_DISTRICT_PATTERN.test(slug)
    let isSlugMnCountyCode =
      MN_COUNTY_CODE_PATTERN.test(slug) && parseInt(slug) < 27174

    if (
      isSlugMnHouseDistrict ||
      isSlugMnSenateDistrict ||
      isSlugUsHouseDistrict ||
      isSlugMnCountyCode
    ) {
      url.pop()
    } else if (slug === 'senate') {
      url.pop()
      url.pop()
      url.push('mn')
    }

    url.pop()
    url = url.join('/')

    history.push(`${url}/county/${key}`)
  }

  function chooseFillColor(data) {
    if (data === undefined || !data.won) {
      return '#00000033'
    }
    let lastNameArray = data.winning_candidate.split(' ')
    let lastName = lastNameArray[lastNameArray.length - 1].toLowerCase()
    let color = PRIMARY_CANDIDATE_COLORS_2020[lastName]

    if (
      PRIMARY_DEM_CANDIDATES_2020.includes(data.winning_candidate) ||
      PRIMARY_GOP_CANDIDATES_2020.includes(data.winning_candidate)
    ) {
      return color
    } else {
      return PRIMARY_CANDIDATE_COLORS_2020['writeIn']
    }
  }

  return (
    <div className={`map ${elementClass}`}>
      <ReactTooltip className="tooltip" id={`tooltip-${mapName}`}>
        <h3 className="tooltip_title">{tooltip.label} County</h3>
        <table>
          <tbody>
            {tooltip.won && (
              <tr>
                <td className="tooltip_label">Winning Candidate</td>
                <td className="tooltip_data">{tooltip.winning_candidate}</td>
              </tr>
            )}
            {!tooltip.won && tooltip.winning_candidate !== '' && (
              <tr>
                <td className="tooltip_label">Leading Candidate</td>
                <td className="tooltip_data">{tooltip.winning_candidate}</td>
              </tr>
            )}
            <tr>
              <td className="tooltip_label">Vote Percent</td>
              <td className="tooltip_data">
                {Math.round(tooltip.winning_vote_percent * 100)}%
              </td>
            </tr>
            <tr>
              <td className="tooltip_label">Vote Count</td>
              <td className="tooltip_data">{tooltip.winning_vote_count}</td>
            </tr>
            <tr>
              <td className="tooltip_label">Reporting Percent</td>
              <td className="tooltip_data">
                {Math.round(tooltip.reporting_percent * 100)}%
              </td>
            </tr>
          </tbody>
        </table>
      </ReactTooltip>
      <ComposableMap projection={projection} width={width} height={height}>
        <ZoomableGroup zoom={1} center={center}>
          <Geographies
            geography={topoJson}
            data-tip
            data-for={`tooltip-${mapName}`}
          >
            {({ geographies }) => {
              geographies.sort((a, b) => {
                if (a.properties[keyAttribute] === highlightZone) {
                  return -1
                } else {
                  return 1
                }
              })

              return geographies.map((geo) => {
                let key = geo.properties[keyAttribute]
                let label = geo.properties[properName]
                  ? geo.properties[properName]
                  : `${properName} ${key}`
                const thisData = data && data[key]
                let strokeWidth = 0.5

                if (highlightZone) {
                  if (highlightZone === key) {
                    strokeWidth = 2
                  }
                }

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={chooseFillColor(thisData)}
                    fillOpacity={1}
                    stroke="#000000"
                    strokeWidth={strokeWidth}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onMouseDown={handleMouseDown}
                    data-geokey={key}
                    data-geolabel={label}
                  />
                )
              })
            }}
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  )
}

PrimaryMap.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
  projection: PropTypes.func.isRequired,
  center: PropTypes.array.isRequired,
  topoJson: PropTypes.string.isRequired,
  keyAttribute: PropTypes.string,
  properName: PropTypes.string,
  data: PropTypes.object,
  isHighlight: PropTypes.bool,
  mapName: PropTypes.string,
}

export default PrimaryMap
