import React, { useContext } from 'react'
import useData from '../../hooks/useData'
import RaceFull from '../RaceFull'
import Loading from '../Loading'
import Error from '../Error'
import { API_ENDPOINT } from '../../config'
import ElectionContext from '../../context/ElectionContext'

const PresidentialPrimaryRace = ({ slug, party }) => {
  const context = useContext(ElectionContext)
  const url = `${API_ENDPOINT}/${context.date}/counties/${slug}?party=${party}`
  const data = useData(url, 30000)

  if (data === undefined) {
    return <Loading />
  } else if (typeof data !== 'object') {
    return <Error err={data} />
  }

  const candidates = data.races[0].candidates.sort((a, b) => {
    if (a.votecount > b.votecount) {
      return -1
    }
    if (a.votecount < b.votecount) {
      return 1
    }
    return 0
  })

  return (
    <RaceFull
      candidates={candidates}
      precinctsReportingPct={data.races[0].precinctsReportingPct}
      level="primary"
      name={`${data.countyName} County`}
      isPrimary={true}
    />
  )
}

export default PresidentialPrimaryRace
