import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import shortid from 'shortid'
import useData from '../../hooks/useData'
import Loading from '../Loading'
import Error from '../Error'
import BoPChart from '../BoPChart'
import RaceFull from '../RaceFull'
import { raceLevel } from '../../util'
import PresidentNational from '../PresidentNational'
import PresidentElectionTable from '../PresidentElectionTable'
import PresidentMinn from '../PresidentMinn'
import PropTypes from 'prop-types'
import PresidentialPrimaryOverview from '../PresidentialPrimaryOverview'
import Ap from '../Ap'
import ElectionContext from '../../context/ElectionContext'

const HomeResults = ({ url, interval, overview }) => {
  const context = useContext(ElectionContext)

  const data = useData(url, interval)

  if (overview.type === 'Ap') {
    return <Ap />
  }

  const buildBopNodes = (unit, prefix) => {
    let bopList = []
    for (let key in data) {
      if (key.startsWith(prefix)) {
        let res = data[key]
        res.label = `${unit} ${key.split(prefix, 2).pop()}`
        bopList.push(res)
      }
    }

    return bopList.map(function (bop) {
      return <BoPChart {...bop} key={shortid.generate()} />
    })
  }

  const buildRaceNodes = (item) => {
    return item.map((race) => {
      let level = raceLevel(race)

      return <RaceFull {...race} level={level} key={race.district} />
    })
  }

  const mapConfigToProp = (item) => {
    switch (item) {
      case 'usSenate':
        return buildRaceNodes(data.usSenate)
      case 'usHouse':
        return buildRaceNodes(data.usHouse)
      case 'governor':
        return buildRaceNodes(data.governor)
      case 'attorneyGeneral':
        return buildRaceNodes(data.attorneyGeneral)
      case 'secretaryOfState':
        return buildRaceNodes(data.secretaryOfState)
      case 'auditor':
        return buildRaceNodes(data.auditor)
      default:
        return ''
    }
  }

  if (data === undefined) {
    return <Loading />
  } else if (typeof data === 'string') {
    return <Error err={data.toString()} />
  }

  return (
    <div data-testid="home-data">
      <div className="grid_col">
        {overview.map((item, index) => {
          const node = () => {
            switch (item.type) {
              case 'Race':
                return mapConfigToProp(item.label)
              case 'BalanceOfPower':
                return buildBopNodes(item.area, item.bop)
              case 'PresidentialNational':
                return (
                  <>
                    <PresidentElectionTable />
                    <PresidentNational
                      candidates={data.president.topCandidates}
                      key="president-natl"
                    />
                  </>
                )
              case 'PresidentialMinn':
                return (
                  <PresidentMinn
                    mnReportingPct={data.president.mnReportingPct}
                    candidates={data.president.stateCandidateSummary}
                    key="president-minn"
                  />
                )
              case 'PresidentialPrimary':
                return <PresidentialPrimaryOverview />
              default:
                return <div className="section_body">{item.bodyText}</div>
            }
          }

          return (
            <li key={`${item.href}${index}`}>
              <section className="section">
                <div className="section_header">
                  {item.title && (
                    <h2 className="hdg hdg-2 hdg-em hdg-inline">
                      {item.title}
                    </h2>
                  )}

                  {item.buttonText && (
                    <Link
                      to={`/${context.date}/${item.href}`}
                      className="button"
                    >
                      {item.buttonText}
                    </Link>
                  )}
                </div>
                {item.headerText && (
                  <div className="section_subheader">{item.headerText}</div>
                )}
                <div className="blocks">{node()}</div>
              </section>
            </li>
          )
        })}
      </div>
    </div>
  )
}

HomeResults.propTypes = {
  url: PropTypes.string.isRequired,
  interval: PropTypes.number,
  overview: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default HomeResults
