import React, { Component } from 'react'

class IconMenu extends Component {
  render() {
    return (
      <symbol id="icon-menu" width="20" height="20" viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          d="M2,14 L18,14 L18,17 L2,17 L2,14 Z M2,9 L18,9 L18,12 L2,12 L2,9 Z M2,4 L18,4 L18,7 L2,7 L2,4 Z"
        />
      </symbol>
    )
  }
}

export default IconMenu
