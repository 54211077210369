import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import GovernorResults from './GovernorResults'
import { API_ENDPOINT } from '../../config'
import Svg from '../Svg'
import ElectionContext from '../../context/ElectionContext'
import useData from '../../hooks/useData'
import usePreviousElection from '../../hooks/usePreviousElection'

const Governor = () => {
  const context = useContext(ElectionContext)
  const previousElection = usePreviousElection(
    context,
    'governor',
    'governor_races'
  )

  return (
    <div className="Governor">
      <Helmet>
        <title>MPR News | Live Results for MN Governor</title>
        <meta
          name="twitter:title"
          content="LIVE Election Results from MPR News | MN Governor"
        />
        <meta
          property="og:title"
          content="LIVE Election Results from MPR News | MN Governor"
        />
      </Helmet>
      <div className="section_header">
        <h2 className="hdg hdg-2 mobileCentered">
          <Svg icon="mn-seal" />
          Minnesota Governor Results
        </h2>
      </div>
      <GovernorResults
        interval={30000}
        url={`${API_ENDPOINT}/${context.date}/governor_races`}
        previousElection={previousElection}
      />
    </div>
  )
}

export default Governor
