import React, { Component } from 'react'

class IconClose extends Component {
  render() {
    return (
      <symbol
        id="icon-close"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlSpace="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M7.9 10L4 13.9 6.1 16 10 12.1 13.9 16 16 13.9 12.1 10 16 6.1 13.9 4 10 7.9 6.1 4 4 6.1 7.9 10Z"
        />
      </symbol>
    )
  }
}

export default IconClose
