import React from 'react'
import Loading from '../Loading'
import Error from '../Error'
import PropTypes from 'prop-types'
import useData from '../../hooks/useData'
import Sprite from '../Svg/states/Sprite'
import Svg from '../Svg/states'
import { useDefaultImg, DEFAULT_CANDIDATE_IMAGE } from '../../util'
import { THOSE_WHOMST_HAVE_PICS } from '../../config'

const PresidentialPrimaryNational = ({ url, interval, party }) => {
  const data = useData(url, interval)
  if (data === undefined) {
    return <Loading />
  } else if (typeof data !== 'object') {
    return <Error err={data} />
  }

  return (
    <div className="section section-borderBottom">
      <div className="presidentialPrimaryDelegates">
        <DelegateRow
          party={party}
          results={data.results[0]}
          dataSource={data.meta.election_data_source}
        />
      </div>
    </div>
  )
}

const DelegateRow = ({ results, party, dataSource }) => {
  let picturedCandidates = results?.candidates.filter((candidate) => {
    return THOSE_WHOMST_HAVE_PICS.includes(candidate.candidate_id)
  })

  let unpicturedCandidates = results?.candidates.filter((candidate) => {
    return !THOSE_WHOMST_HAVE_PICS.includes(candidate.candidate_id)
  })

  return (
    <div className="delegate-count">
      <div className="results-legend">
        <h3 className="results-header">
          {`Overview of ${party} Delegate Count Results`}
        </h3>
        <div className="results-key">
          {results.total_delegates_needed.toLocaleString('en-US')} to win
        </div>
      </div>
      <div className="results_source">
        Data Source: {dataSource === 'mn_oss' ? 'MN SOS' : 'AP'}
      </div>
      <div className="row row-candidate row-wrap" data-party={results.party}>
        <div className="column column-state">
          <div className="state state-wrapper">
            <div className="state_flex state_margin">
              <div className="stateSvg stateSvg-national">
                <Svg statesvg="US" />
              </div>
              <div className="cell cell-national">National</div>
            </div>
          </div>
        </div>
        <div className="column-candidate">
          <div className="row row-wrap">
            {picturedCandidates?.map((candidate) => {
              return (
                <CandidateDelegateCell
                  {...candidate}
                  key={candidate.candidate_id}
                />
              )
            })}
            {unpicturedCandidates?.map((candidate) => {
              return (
                <CandidateDelegateCell
                  {...candidate}
                  has_photo={false}
                  key={candidate.candidate_id}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const CandidateDelegateCell = ({
  candidate_name,
  candidate_id,
  delegates_won,
  has_photo = true,
}) => {
  const photo = has_photo ? (
    <img
      src={`/data/imgs/candidates/${candidate_id}.jpg`}
      height="64"
      width="64"
      alt={candidate_name}
      onError={useDefaultImg}
    />
  ) : (
    <img
      src={DEFAULT_CANDIDATE_IMAGE}
      height="64"
      width="64"
      alt={candidate_name}
    />
  )

  return (
    <div className="cell cell-candidate">
      <div className="cell_icon">{photo}</div>
      <div className="cell_column">
        <div className="cell_row">
          <div className="cell_name">{candidate_name}</div>
        </div>
        <div className="cell_row">
          <div className="cell_delegates">
            {delegates_won.toLocaleString('en-US')}
          </div>
        </div>
      </div>
      <Sprite />
    </div>
  )
}

PresidentialPrimaryNational.propTypes = {
  url: PropTypes.string.isRequired,
  party: PropTypes.string,
  interval: PropTypes.number,
}

export default PresidentialPrimaryNational
