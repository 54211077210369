import React, { useEffect, useState, useRef } from 'react'
import unfetch from 'unfetch'
import Loading from '../Loading'
import Error from '../Error'
import { useDefaultImg } from '../../util'
import useData from '../../hooks/useData'

const INCREMENT_SIZE = 350

const PresidentElectionTable = ({ url, interval, priorityCandidates }) => {
  const data = useData(url, interval)
  const [states, setStates] = useState(undefined)
  const [disabled, setDisabled] = useState('left')
  const [hide, setHide] = useState(true)
  const scrollWindow = useRef(null)

  useEffect(() => {
    // The order of states and the electoral votes each has is set in Potlatch
    // In future elections, you may want to specify which year you want to grab in this slug via a prop
    async function fetchData() {
      const potlatch = await unfetch('https://cmsapi.publicradio.org/graphql', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query:
            '{ potlatch(slug: "elections/electoral-states-2020") { json } }',
        }),
      })
      const data = await potlatch.json()

      setStates(JSON.parse(data.data.potlatch.json).states)
    }
    fetchData()
  }, [])

  if (data === undefined || states === undefined) {
    return <Loading />
  } else if (typeof data === 'string') {
    return <Error err={data} />
  }

  const handleLeftClick = (e) => {
    e.preventDefault()
    if (scrollWindow.current.scrollLeft >= INCREMENT_SIZE) {
      scrollWindow.current.scrollLeft -= INCREMENT_SIZE
      setDisabled('none')
    } else {
      scrollWindow.current.scrollLeft = 0
      setDisabled('left')
    }
  }

  const handleRightClick = (e) => {
    e.preventDefault()
    let maxScrollLeft =
      scrollWindow.current.scrollWidth - scrollWindow.current.clientWidth
    if (scrollWindow.current.scrollLeft < maxScrollLeft - INCREMENT_SIZE) {
      scrollWindow.current.scrollLeft += INCREMENT_SIZE
      setDisabled('none')
    } else {
      scrollWindow.current.scrollLeft = scrollWindow.current.scrollWidth
      setDisabled('right')
    }
  }

  const handleShowMore = () => {
    setHide(!hide)
  }

  const patt = new RegExp(priorityCandidates.join('|'))
  const priority = data.president?.nationalSummary?.filter((c) =>
    patt.test(c.name)
  )
  const other = data.president?.nationalSummary?.filter(
    (c) => !patt.test(c.name)
  )

  return (
    <div id="electionTable">
      <div className="scroll" ref={scrollWindow}>
        <table>
          <thead>
            <tr className="firstRow">
              <th className="firstColumn">
                <div>State</div>
                <div className="firstRow_votes">Electoral Votes</div>
              </th>
              {states.map((state, i) => {
                return (
                  <th key={i}>
                    <div className="firstRow_postal">{state.postal}</div>
                    <div className="firstRow_votes">{state.electoralVotes}</div>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {data.president?.nationalSummary &&
              priority.map((candidate) => {
                return (
                  <tr key={candidate.name}>
                    <td className="firstColumn">
                      <div className="cellIcon">
                        <img
                          src={`/data/imgs/candidates/${candidate.polid}.jpg`}
                          height="64"
                          width="64"
                          alt={`${candidate.name}`}
                          onError={useDefaultImg}
                        />
                      </div>
                      <span className="candidateLabel">{candidate.name}</span>
                    </td>
                    {states.map((state) => {
                      return (
                        <td
                          className={`${
                            candidate.states[state.postal].winner ? 'winner' : ''
                          }`}
                          key={state.postal}
                        >
                          {`${Math.round(
                            candidate.states[state.postal].votepct * 100
                          )}%`}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            {data.president?.nationalSummary &&
              other.map((candidate) => {
                return (
                  <tr
                    key={candidate.name}
                    className={`${hide ? 'hidden' : ''}`}
                  >
                    <td className="firstColumn">
                      <div className="cellIcon">
                        <img
                          src={`/data/imgs/candidates/${candidate.polid}.jpg`}
                          height="64"
                          width="64"
                          alt={`${candidate.name}`}
                          onError={useDefaultImg}
                        />
                      </div>
                      <span className="candidateLabel">{candidate.name}</span>
                    </td>
                    {states.map((state) => {
                      return (
                        <td key={state.postal} className={state.postal}>
                          {`${Math.round(
                            candidate.states[state.postal].votepct * 100
                          )}%`}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      <div>
        <button className="showMore" onClick={handleShowMore}>
          {`Show ${hide ? 'More ' : 'Less '}Candidates`}
        </button>
      </div>
      <div className="controls">
        <button onClick={handleLeftClick} disabled={disabled === 'left'}>
          {`<`}
        </button>
        <button onClick={handleRightClick} disabled={disabled === 'right'}>
          {`>`}
        </button>
      </div>
    </div>
  )
}

export default PresidentElectionTable
