import React, { Component } from 'react'

class IconInfographic extends Component {
  render() {
    return (
      <symbol
        id="icon-infographic"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <rect x="5" y="61" width="16" height="39" />
        <rect x="30" y="80" width="16" height="20" />
        <rect x="55" y="20" width="16" height="80" />
        <rect x="80" y="50" width="16" height="50" />
      </symbol>
    )
  }
}

export default IconInfographic
