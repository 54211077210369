import React, { useContext } from 'react'
import Loading from '../Loading'
import RaceFull from '../RaceFull'
import { raceLevel } from '../../util'
import PropTypes from 'prop-types'
import useData from '../../hooks/useData'
import Error from '../Error'
import maps from '../../config/maps'
import { useHistory } from 'react-router-dom'
import ElectionContext from '../../context/ElectionContext'

const CountyResults = ({ url, countyCode, interval }) => {
  const history = useHistory()
  const context = useContext(ElectionContext)
  const data = useData(url, interval)
  const map = maps.president
  const mapData = useData(map.topoJson, false)
    ?.objects?.mnCounties?.geometries?.map((item) => item.properties)
    ?.sort((a, b) => (a[map.properName] > b[map.properName] ? 1 : -1))

  if (data === undefined) {
    return <Loading />
  } else if (typeof data === 'string') {
    return <Error err={data} />
  }

  const buildRaceNodes = (raceList = data.races) => {
    const countyString = `, ${data.countyName} County precincts`

    return raceList.map(function (race, index) {
      return (
        <RaceFull
          {...race}
          level={raceLevel(race)}
          addLabel={raceLevel(race) && countyString}
          key={
            race.name ? `${race.name}-${index}` : `${race.district}-${index}`
          }
        />
      )
    })
  }

  return (
    <div className="grid_col">
      <div className="section_header">
        <h2 className="hdg hdg-2 hdg-em">{data.countyName} County Results</h2>
      </div>
      <select
        onChange={(event) => history.push(event.target.value)}
        onBlur={(event) => history.push(event.target.value)}
        defaultValue={`/${context.date}/county/${countyCode}`}
      >
        {mapData?.map((county) => (
          <option value={`/${context.date}/county/${county[map.keyAttribute]}`}>
            {county[map.properName]}
          </option>
        ))}
      </select>
      {/* <div className="section section-lg">
        <CountyMap
          width={450}
          height={350}
          center={[-94, 46.5]}
          projection={projection}
          topoJson={map.topoJson}
          keyAttribute={map.keyAttribute}
          properName={map.properName}
          highlightZone={countyCode}
        />
      </div> */}
      <div className="blocks blocks-1">{buildRaceNodes()}</div>
    </div>
  )
}

CountyResults.propTypes = {
  url: PropTypes.string.isRequired,
  countyCode: PropTypes.string,
  interval: PropTypes.number,
}

export default CountyResults
